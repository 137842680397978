import React, { Component } from 'react';
import { Spinner, Row, Col, Card } from 'react-bootstrap'
import _ from 'lodash'
import Axios from "axios"
import { connect } from 'react-redux';
class DomainSearch extends Component {
    state = {
        search: "",
        availableDomains: [],
        prices: [],
        searchedDomain: {},
        domainSearched: "",
        showBuy: false,
        domainToBuy: "",
        searching: false,
        domainToBuyOneYearPriceDollar: null,
        showtlds: false,
        sale: false,
        tlds: [
            ".ac", ".academy", ".accountants", ".actor", ".adult", ".agency", ".airforce", ".apartments", ".associates", ".auction", ".audio", ".band", ".bargains", ".bet", ".bike", ".bingo", ".biz", ".black", ".blue", ".boutique", ".builders", ".business", ".buzz", ".cab", ".cafe", ".camera", ".camp", ".capital", ".cards", ".care", ".careers", ".cash", ".casino", ".catering", ".cc", ".center", ".ceo", ".chat", ".cheap", ".church", ".city", ".claims", ".cleaning", ".click", ".clinic", ".clothing", ".cloud", ".club", ".coach", ".codes", ".coffee", ".college", ".com", ".community", ".company", ".computer", ".condos", ".construction", ".consulting", ".contractors", ".cool", ".coupons", ".credit", ".creditcard", ".cruises",
            ".dance", ".dating", ".deals", ".degree", ".delivery", ".democrat", ".dental", ".diamonds", ".diet", ".digital", ".direct", ".directory", ".discount", ".dog", ".domains", ".education", ".email", ".energy", ".engineering", ".enterprises", ".equipment", ".estate", ".events", ".exchange", ".expert", ".exposed", ".express", ".fail", ".farm", ".finance", ".financial", ".fish", ".fitness", ".flights", ".florist", ".flowers", ".fm", ".football", ".forsale", ".foundation", ".fund", ".furniture", ".futbol", ".fyi",
            ".gallery", ".games", ".gift", ".gifts", ".gives", ".glass", ".global", ".gmbh", ".gold", ".golf", ".graphics", ".gratis", ".green", ".gripe", ".group", ".guide", ".guitars", ".guru", ".haus", ".healthcare", ".help", ".hiv", ".hockey", ".holdings", ".holiday", ".host", ".hosting", ".house", ".im", ".immo", ".immobilien", ".industries", ".info", ".ink", ".institute", ".insure", ".international", ".investments", ".io", ".irish",
            ".jewelry", ".juegos", ".kaufen", ".kim", ".kitchen", ".kiwi", ".land", ".lease", ".legal", ".lgbt", ".life", ".lighting", ".limited", ".limo", ".link", ".live", ".loan", ".loans", ".lol", ".maison", ".management", ".marketing", ".mba", ".media", ".memorial", ".mobi", ".moda", ".money", ".mortgage", ".movie", ".name", ".net", ".network", ".news", ".ninja",
            ".onl", ".online", ".org", ".partners", ".parts", ".photo", ".photography", ".photos", ".pics", ".pictures", ".pink", ".pizza", ".place", ".plumbing", ".plus", ".poker", ".porn", ".press", ".pro", ".productions", ".properties", ".property", ".pub", ".qpon", ".recipes", ".red", ".reise", ".reisen", ".rentals", ".repair", ".report", ".republican", ".restaurant", ".reviews", ".rip", ".rocks", ".run",
            ".sale", ".sarl", ".school", ".schule", ".services", ".sex", ".sexy", ".shiksha", ".shoes", ".show", ".singles", ".site", ".soccer", ".social", ".solar", ".solutions", ".space", ".store", ".studio", ".style", ".sucks", ".supplies", ".supply", ".support", ".surgery", ".systems", ".tattoo", ".tax", ".taxi", ".team", ".tech", ".technology", ".tennis", ".theater", ".tienda", ".tips", ".tires", ".today", ".tools", ".tours", ".town", ".toys", ".trade", ".training", ".tv",
            ".university", ".uno", ".vacations", ".vegas", ".ventures", ".vg", ".viajes", ".video", ".villas", ".vision", ".voyage", ".watch", ".website", ".wedding", ".wiki", ".wine", ".works", ".world", ".wtf", ".xyz", ".zone", ".ac", ".co.za", ".sh", ".ca", ".cl", ".co", ".com.ar", ".com.br", ".com.mx", ".mx", ".us", ".vc", ".vg", ".au", ".cc", ".co.nz", ".com.au", ".com.sg", ".fm", ".in", ".jp", ".io", ".net.au",
            ".net.nz", ".org.nz", ".qa", ".ru", ".sg", ".be", ".berlin", ".ch", ".co.uk", ".cz", ".de", ".es ", ".eu", ".fi", ".fr", ".gg", ".im", ".it", ".me", ".me.uk", ".nl", ".org.uk", ".ruhr", ".se", ".uk", ".wien"
        ],
        tldsFiltered: [],
        domainToBuyOneYearPricePoundOffer: null,
        pricesLoading: true

    }
    buydomain = (data) => {
        this.props.buyDomain(data)
    }
    genDomainRow = (ava, disableOffer) => {
        //seperate tld
        let domaintldsplt = ava.DomainName.split(".")
        let domaintld = ""
        let i = 0
        for (const d of domaintldsplt) {

            if (i > 0) {
                if (i > 1) {
                    domaintld = domaintld + "."
                }
                domaintld = domaintld + d
            }
            i = i + 1
        }
        //get the price
        let actprice = 0
        let offerMinus = null
        for (const price of this.state.prices) {
            if (price.Name == domaintld) {
                actprice = price.RegistrationPrice
                if (!disableOffer && this.props.sale) {
                    offerMinus = this.props.OfferMinus
                }
            }
        }
        let priceRow = <div></div>
        let offerPrice = null
        if(this.state.pricesLoading){
            priceRow = <div><Spinner animation="border" vaiant="secondary" style={{ width: '50px', height: '50px', fontSize: '30px' }} /></div>
        }else{
            if (offerMinus) {
                if (!this.state.sale) {
                    this.setState({ sale: true })
                }
                offerPrice = ((actprice * this.props.exchangeRate) - offerMinus).toFixed(2)
                if (offerPrice < 0.99) {
                    offerPrice = 0.99
                }
                priceRow = <div>£{offerPrice}     <strike> £{(actprice * this.props.exchangeRate).toFixed(2)}</strike> </div>
            } else {
                priceRow = <div> £{(actprice * this.props.exchangeRate).toFixed(2)} (${actprice})</div>
            }
        }

        //create html
        return (<Row style={{ margin: "20px" }}>
            <Col style={{ textAlign: "center", margin: "auto" }}>{ava.DomainName}</Col>
            <Col style={{ textAlign: "center", margin: "auto" }}>
                <Row>{priceRow}</Row>
                {!this.state.pricesLoading && offerMinus && <Row><p style={{ fontSize: "10px" }}>For the first year, then £{(actprice * this.props.exchangeRate).toFixed(2)} (${actprice}) per year</p></Row>}
            </Col>
            <Col style={{ textAlign: "center", margin: "auto" }}>
                {
                    ava.Availability == "AVAILABLE" ?

                        <button onClick={() => { this.buydomain({ showBuy: true, domainToBuy: ava.DomainName, domainToBuyOneYearPriceDollar: actprice, domainToBuyOneYearPricePoundOffer: offerPrice }) }} style={{ background: "green", width: "140px" }} className="button">Buy</button>
                        :
                        <button disabled={true} style={{ background: "red", width: "140px" }} className="button">Not Available</button>
                }
            </Col>
        </Row>
        )
    }
    searchChange = (e, showtlds = true) => {
        var allowedCharsRegex = /[a-z0-9.-]/g;
        let cleanedString = e.target.value
        var allowedCharsArray = cleanedString.match(allowedCharsRegex);

        if (allowedCharsArray) {
            cleanedString = allowedCharsArray.join("");
        }

        this.setState({ search: cleanedString, showtlds: showtlds })
        let tld = cleanedString.split(".")
        let totaltld = ""
        if (tld[1]) {
            tld.shift()
            for (let tldi of tld) {
                totaltld += "." + tldi
            }
            let matchingTLDs = this.state.tlds.filter(tlde => tlde.includes(totaltld));
            this.setState({ tldsFiltered: matchingTLDs })
        } else {
            this.setState({ tldsFiltered: [] })
        }

        this.searchDebounced()
    }
    handleSearch = (page) => {
        this.searchDomains(this.state.search)
    }
    searchDebounced = _.debounce(() => this.handleSearch(1), 500)

    searchDomains = (domain, first) => {
        this.setState({ availableDomains: [], searchedDomain: [], searching: true })
        let spl = domain.split(".")
        let domainse = domain
        let overridden = false

        var subarray = spl.slice(1);
        let joined = subarray.join(".")
        if (!domain) {
            this.props.domainSearched()
            this.setState({ availableDomains: [], searchedDomain: [], searching: false })
            return

        }

        if (!this.state.tlds.includes("." + joined)) {
            domainse = spl[0] + ".com"
            overridden = true
        } 
        this.props.domainSearched(domainse)
        Axios.post("/api/domains/check/availability", { domain: domainse }).then(res => {
            if (res.data.success) {
                if (this.state.search == domainse || (overridden && domain == this.state.search) || (first && "" == this.state.search)) {
                    if (first && this.state.search != "") {
                        return
                    } else {
                    }
                    this.setState({ searchedDomain: res.data.domain, domainSearched: res.data.domainSearched, searching: false }, () => {
                        this.getPrices()
                    })
                }
            } else {
                if (this.state.search == domainse) {
                    this.setState({ availableDomains: [], searchedDomain: [], searching: false })
                }
            }
        })

    }
    getPrices = () => {
        this.setState({pricesLoading: true},()=>{
            let tlds = []
            for (const domain of this.state.availableDomains) {
                tlds.push(domain.DomainName)
    
            }
            if (this.state.domainSearched) {
                tlds.push(this.state.domainSearched)
            }
            Axios.post("/api/domains/get/tld/prices", { domains: tlds }).then(res => {
                this.setState({ prices: res.data.price, pricesLoading: false })
    
            })
        })

    }
    genTlds = () => {
        let arr = []
        let sub = this.state.search.split(".")
        if (this.state.search != "" && this.state.showtlds) {
            if (this.state.tldsFiltered[0]) {
                for (let tld of this.state.tldsFiltered) {

                    arr.push(<div onClick={() => { this.searchChange({ target: { value: sub[0] + tld } }, false); this.setState({ showtlds: false }) }}>{sub[0] + tld}</div>)
                }
                return arr
            } else {
                for (let tld of this.state.tlds) {

                    arr.push(<div onClick={() => { this.searchChange({ target: { value: sub[0] + tld } }, false); this.setState({ showtlds: false }) }}>{sub[0] + tld}</div>)
                }
                return arr
            }
        }



    }
    render() {
        let offerInBasket = false
        let domain = []
        let prompt = []
        let darkmode = this.props.user.darkmode
        let tlds = this.genTlds()

        this.props.basket.forEach(it => {
            if (it.offer) {
                offerInBasket = true
            }
        })
        if (this.state.searchedDomain.Availability) {
            let html = this.genDomainRow({ DomainName: this.state.domainSearched, Availability: this.state.searchedDomain.Availability }, offerInBasket)
            domain.push(html)
        }


        if (!this.state.searchedDomain.Availability) {
            if (!this.state.searching) {
                if (this.state.search == null || this.state.search == "") {
                    prompt.push(
                        <Row><Col style={{ margin: "auto", textAlign: "center" }}>Search for a domain...</Col></Row>
                    )
                } else {
                    prompt.push(
                        <Row ><Col style={{ margin: "auto", textAlign: "center" }}>Please enter a valid domain.</Col></Row>

                    )
                }
            }


        }
        return (<div>

            <Card style={{ margin: '30px' }}>
                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                    <h4 >Buy A Domain</h4>
                </Card.Header>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ maxWidth: '100%' }} >
                    <Row onMouseLeave={() => { this.setState({ showtlds: false }) }}>
                        <Col>
                            <input style={{ width: '100%' }} type='text' value={this.state.search} onChange={(e) => this.searchChange(e)} placeholder='Domain - e.g. domain.com' />
                            <div style={{ maxHeight: "100px", overflowY: "scroll", borderStyle: "solid", borderColor: "black", borderWidth: "1px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                {tlds}

                            </div>
                        </Col>
                    </Row>
                    {domain}
                    {prompt}

                    {
                        this.state.searching && <div>
                            <Row>
                                <Col style={{ margin: "auto", textAlign: "center", margin: "20px" }}>
                                    <Spinner animation="border" vaiant="secondary" style={{ width: '50px', height: '50px', fontSize: '30px' }} />
                                </Col>

                            </Row>
                            <Row>
                                <Col style={{ margin: "auto", textAlign: "center", margin: "20px" }}>
                                    <p>Searching...</p>

                                </Col>

                            </Row></div>

                    }
                </div>
            </Card>
        </div>);
    }
}

function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user,
        basket: state.basket
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainSearch);

