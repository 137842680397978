import React, { Component } from 'react';
import Modal from "../../components/modal"
import Axios from 'axios';
import { Col, Row } from 'react-bootstrap';

class AdminUserDetailsBasket extends Component {
    state = {

        basket: []
    }
    componentDidMount() {
        this.getData()
    }
    getData = async () => {
        Axios.post("/api/basket/admin/get/by/user", { user: this.props.userSelectedToCheck }).then(res => {
            this.setState({ basket: res.data.basket })
        })
    }
    render() {
        let basket = []
        if (this.state.basket[0]) {
            this.state.basket[0].contents.forEach(item => {
                if(item.type == "domain"){
                    basket.push(<Row>
                        <Col>{"Domain: "+item.domain}</Col>
                        <Col>Years: {item.years}</Col>
                        <Col>Offer: {item.offer ? item.offer : "n/a"}</Col>
                        <Col>AutoRenew: {item.autoRenew ? "true": "false"}</Col>
                        <Col>contactsAndPrivacy: todo</Col>
                        <Col>priceDollarPerYer: todo</Col>
                        <Col>reqistrationContactValid: todo</Col>
                        <Col>techContactValid: todo</Col>
                        <Col>AdminContactValid: {item.adminContanctValid? "true": "false"}</Col>

                    </Row>)
                }else if(item.type == "hosting"){
                    basket.push(<Row>
                        <Col>{item.name + " Hosting"}</Col>
                        <Col>Quantity: {item.amount}</Col>
                    </Row>)
                }
            })
        }

        return (
            <div>

                <Modal show={this.props.show} title={this.props.title} close={this.props.close} content={<div>{basket}</div>}></Modal>
                
            </div>
        );
    }
}

export default AdminUserDetailsBasket;