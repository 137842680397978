import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Row, Col, Button, Form, Card } from 'react-bootstrap';
import axios from 'axios'
import {setMarketingPreferences} from '../../actions/userActions'
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link} from "react-router-dom"

class AccountPrivacy extends Component {
    state = { 
        showDeleteConfirm: false,
        marketingPreferences: this.props.user.marketingPreferences ? this.props.user.marketingPreferences : {email: false, phone: false, text: false},
     }

    openDeleteConfirm = () => {
        this.setState({showDeleteConfirm: true})
    }
    closeDeleteModal = () => {
        this.setState({showDeleteConfirm: false})
    }
    handleMarketingUpdate=()=>{
        axios.post('/api/user/update/marketing-preferences', {preferences: this.state.marketingPreferences})
        this.props.setMarketingPreferences(this.state.marketingPreferences)
    }
    handleMarketing=(type)=>{
        let marketingPreferences = this.state.marketingPreferences
        marketingPreferences[type] = !marketingPreferences[type]
        this.setState({marketingPreferences: marketingPreferences})
    }
    render() {
        let darkmode = this.props.user.darkmode

        return ( 
            <div>
                <Card style={{width: '90%',margin: 'auto', display: 'block', marginTop: '60px', marginBottom: '60px'}}>
                <Card.Header className={darkmode? "darkmode-card-header" :"lightmode-card-header"} style={{textAlign: 'center'}}>
                    <Row>
                    <Button as={Link} to='/account' style={{marginLeft: '20px',backgroundColor: 'rgb(0, 123, 255)'}}><FontAwesomeIcon  size='2x' icon={faArrowLeft}/></Button>
                    <h3 style={{textAlign: 'center', margin: 'auto'}}>Account Privacy and Marketing</h3>

                    </Row>
                </Card.Header>
                <Card.Text className={darkmode? "darkmode-card-content" :"lightmode-card-content"} style={{textAlign: 'center'}}>
                
                <Row style={{padding: '20px'}}>
                    <p style={{textAlign: 'left',paddingLeft:'40px'}}>Marketing preferences (Giveaways, newsletters, competitions, etc...)</p>
                </Row>
                <Row style={{padding: '20px'}}>
                        <Col style={{textAlign: 'center'}} xs={4}>
                            <p>Email</p>
                            <Form.Control checked={this.state.marketingPreferences.email} onClick={()=>this.handleMarketing('email')} type="checkbox"/>
                        </Col>
                        <Col xs={4}>
                            <p>Phone</p>
                            <Form.Control checked={this.state.marketingPreferences.phone}  onClick={()=>this.handleMarketing('phone')} type="checkbox"/>
                        </Col>
                        <Col xs={4}>
                            <p>Text</p>
                            <Form.Control checked={this.state.marketingPreferences.text}  onClick={()=>this.handleMarketing('text')} type="checkbox"/>
                        </Col>
                        
                    </Row>
                    <Row style={{padding: '20px'}}>
                        <Button  onClick={this.handleMarketingUpdate} style={{width: '50%', margin: 'auto'}} type='submit'>Update</Button>

                    </Row>
                </Card.Text>
            </Card>
                

            
            </div>
         );
    }
}
function mapStateToProps(state){
    return{
        user: state.user
    }
}
function mapDispatchToProps(dispatch){
    return{
        setMarketingPreferences: (data)=>{dispatch(setMarketingPreferences(data))}
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(AccountPrivacy);
