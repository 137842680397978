import React, { Component, useRef } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import {setUser} from '../actions/userActions'
import {Link} from 'react-router-dom'
import {Card, Row, Col, Button, Form} from 'react-bootstrap'
import { setBasket } from '../actions/basketActions'

class Login extends Component {
    state = { 
        email: '',
        password: '',
        errorString: ''
     }
    updateValue = (e, type) => {
        this.setState({[type]: e.target.value.replace(/\s/g, "")})
    }

    login = () => {
        Axios.post('/api/user/login',{email: this.state.email, password: this.state.password}).then((res)=>{
            if(res.data.success){
                this.props.setUser({user: res.data.user, token: res.data.token})
                this.props.setBasket()

            }else{
                this.setState({errorString: res.data.error})
            }
        })
    }
    passRef =  React.createRef()
    submitRef =  React.createRef()

    handleKeyPressEmail = (event) => {
        if (event.key === 'Enter') {
            this.passRef.current.focus()

        }
      };
      handleKeyPressPass = (event) => {
        if (event.key === 'Enter') {
            this.submitRef.current.focus()

        }
      };
      handleKeyPressLogin = (event) => {
        if (event.key === 'Enter') {
            this.login()

        }
      };
    render() { 

        return ( 
                <div style={{width: '100%', marginTop: '60px', marginBottom: '150px'}}>
                    <Card style={{width: '80%',margin: 'auto', maxWidth:'1000px'}}>
                        <Card.Header className={"darkmode-card-header"} style={{textAlign: 'center'}}>
                            <h1>Login</h1>
                        </Card.Header>
                        <div className={"darkmode-card-content"} style={{textAlign: 'center', paddingTop: "10px"}}>
                            <Row  style={{padding: '20px'}}>
                                <Col  xs={4}>
                                <label style={{paddingLeft: '40px'}}>Email:</label>
                                </Col>
                                <Col xs={6} style={{minWidth: '200px', margin: 'auto'}}>
                                    <Form.Control onKeyDown={this.handleKeyPressEmail} value={this.state.email} type='text'  placeholder='Enter Email' onChange={(e)=>this.updateValue(e,'email')} />
                                </Col>
                                
                            </Row>
                            <Row style={{padding: '20px'}}>
                                <Col xs={4}>
                                    <label style={{paddingLeft: '40px'}}>Password:</label>

                                </Col>
                                <Col xs={6} style={{minWidth: '200px', margin: 'auto'}}>
                                    <Form.Control onKeyDown={this.handleKeyPressPass} value={this.state.password} ref={this.passRef} type='password'  placeholder='Enter Password' onChange={(e)=>this.updateValue(e,'password')} />
                                </Col>
                                
                            </Row>
                        </div>
                        <Card.Footer style={{background: "#343a40", color: "white"}} >

                                <Row style={{textAlign: 'center'}}>
                                    {this.state.errorString && <p style={{margin: 'auto'}}>{this.state.errorString}</p>}
                                </Row>
                                <Row style={{marginTop: '10px',marginBottom: '10px'}}>
                                    <Button ref={this.submitRef} style={{backgroundColor: 'rgb(0, 123, 255)', height: '100%', width: '80%', margin: 'auto'}}  onKeyDown={this.handleKeyPressLogin}  type='submit'  onClick={this.login}> Login</Button>
                                </Row>
                                <Row>
                                <Col style={{margin: '10px', textAlign: "center"}}>
                                    <Link style={{width: '200px'}}  type='submit'  to='/forgot-password'> <u>Forgot Password?</u></Link>
                                </Col>
                                <Col style={{margin: '10px', textAlign: "center"}}>
                                    <Link style={{width: '200px'}}  type='submit'  to='/create-account'> <u>Create an account</u></Link>
                                </Col>
                                </Row>

                                
                            
                        </Card.Footer>
                    </Card> 

                    <div>
                        
                        
                    </div>

                </div>
         );
    }
}
function mapStateToProps(state){
    return{
        user: state.user
    }
}
function mapDispatchToProps(dispatch){
    return{
        setUser: (data)=>{dispatch(setUser(data))},
        setBasket: (data) => { dispatch(setBasket(data)) }

    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Login);