import React, { Component } from 'react';
import Axios from 'axios';
import {Card, Row, Col, Button, Form} from 'react-bootstrap'
import {Link} from 'react-router-dom'
class ResetPassword extends Component {
    state = { 
        email: window.location.href.split('email=')[1],
        success: false,
        resetCode: '',
        newPassword: '',
        confirmPassword: '',
        resetDisabled: true,
        passwordValid: false,
        passwordSame: false,
        codeValid: false,
        updateSuccess: false,
        errorString: ''
     }
    updateValue = (e, type) => {
        this.setState({[type]: e.target.value},()=>{
            this.validateAll()
        })
    }

    validateAll=()=>{
        let valid = true
        let invalid = {}
        let codeValid = this.state.resetCode !== ''
        let passwordValidity = this.validatePasswordOnly()
        valid = codeValid && passwordValidity.valid
        invalid = {codeValid: !codeValid,...passwordValidity.invalid}
        this.setState({resetDisabled: !valid, ...invalid})
    }
    validatePasswordOnly = () => {
        let valid = true
        let invalid={}
        if(!this.validatePassword(this.state.newPassword)){
            valid = false
            invalid.passwordValid = true
        }else{
            invalid.passwordValid = false
        }
        if(this.state.newPassword !== this.state.confirmPassword){
            valid = false
            invalid.passwordSame = true
        }else{
            invalid.passwordSame = false
        }
        return {valid: valid, invalid: invalid}
    }
    validatePassword = (password) => {
        const re = /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
        return re.test(String(password));
    }
    reset=()=>{
        Axios.post('/api/user/reset-password', {email: this.state.email, resetCode: this.state.resetCode, password: this.state.newPassword}).then(res=>{
            this.setState({updateSuccess: res.data.success, errorString: res.data.errorString})
            
        })
    }
    render() { 
        return ( 
                <div style={{width: '100%', paddingTop: '40px'}}>
                    <Card style={{width: '80%',margin: 'auto'}}>
                        <Card.Header className="darkmode-card-header" style={{textAlign: 'center'}}>
                            <h1>Forgot Password</h1>
                        </Card.Header>
                        {this.state.codeValid && 
                        <Row >
                            <Col>
                            <p>Code Required</p>
                            </Col>
                        </Row>
                    }
                        <Card.Text className="darkmode-card-content" style={{textAlign: 'center', marginBottom: "-10px"}}>
                            <Row style={{padding: '20px'}}>
                                <Col xs={4}>
                                <label>Reset Code</label>
                                </Col>
                                <Col xs={6}>
                                    <Form.Control type='text' placeholder='Enter Reset Code' onChange={(e)=>this.updateValue(e,'resetCode')} />
                                </Col>
                                
                            </Row>
                            {this.state.passwordValid && 
                        <Row >
                            <Col>
                            <p>Password not valid</p>
                            </Col>
                        </Row>
                    }
                            <Row style={{padding: '20px'}}>
                                <Col xs={4}>
                                <label>New Password (must contain at least one uppercase, lowercase, and number and be 8 characters min)</label>
                                </Col>
                                <Col xs={6}>
                                    <Form.Control type='password' placeholder='Enter Password' onChange={(e)=>this.updateValue(e,'newPassword')} />
                                </Col>
                                
                            </Row>

                            {this.state.passwordSame && 
                        <Row >
                            <Col>
                            <p>Passwords not the same</p>
                            </Col>
                        </Row>
                    }
                            <Row style={{padding: '20px'}}>
                                <Col xs={4}>
                                <label>Confirm New Password</label>
                                </Col>
                                <Col xs={6}>
                                    <Form.Control type='password' placeholder='Confirm New Password' onChange={(e)=>this.updateValue(e,'confirmPassword')} />
                                </Col>
                                
                            </Row>
                        </Card.Text>
                        <Card.Footer className="darkmode-card-header" >
                            <Row>
                                <Col >
                                <Button style={{float: 'left',backgroundColor: '#343a40'}}  as={Link} to='/forgot-password'>Resend Password Reset</Button>
                                
                                </Col>
                                <Col >
                                    <Button style={{float: 'right',backgroundColor: '#343a40'}} disabled={this.state.resetDisabled}  type='submit'  onClick={this.reset}>Reset Password</Button>
                                
                                </Col>
                            </Row>
                            <Row>
                                
                                <Col>
                                <p style={{float: 'right'}}>{this.state.errorString}</p>
                                {this.state.updateSuccess && <p style={{float: 'right'}}>Your password has been reset.</p>}
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>                    
                </div>
         );
    }
}
 
export default ResetPassword;