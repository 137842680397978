import {combineReducers} from 'redux'
import user from './user'
import subscriptions from './subscriptions'
import basket from './basket'

const rootReducer = combineReducers({
    user,
    subscriptions,
    basket
})
export default rootReducer