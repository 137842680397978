import React, { Component } from 'react';
// import NavBar from '../components/account/navbar'
import {Switch, Route, Redirect} from "react-router-dom"
import ProtectedRoute from '../components/routes/protectedRoute'
import AccountMenu from '../components/account/accountMenu'
import AccountDetails from '../components/account/accountDetails'
import AccountPrivacy from '../components/account/accountPrivacy';
import AccountData from '../components/account/accountData';
import AccountUserInterface from '../components/account/accountUserInterface';


class Account extends Component {
    state = {  }
    render() { 
        return ( 
            <div style={{marginBottom: '150px'}}>
                {/* <NavBar/> */}

                <Switch>
                    <ProtectedRoute exact path="/account"> <AccountMenu/> </ProtectedRoute>
                    <ProtectedRoute exact path="/account/details"> <AccountDetails/> </ProtectedRoute>
                    <ProtectedRoute exact path="/account/privacy"> <AccountPrivacy/> </ProtectedRoute>
                    <ProtectedRoute exact path="/account/data"> <AccountData/> </ProtectedRoute>
                    <ProtectedRoute exact path="/account/user-interface"> <AccountUserInterface/> </ProtectedRoute>
                    <Route><Redirect to='/account'/></Route>
                </Switch>
            </div> 
        );
    }
}
 
export default Account;