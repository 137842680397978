import React, { Component } from 'react';
import {Button} from 'react-bootstrap'
import Modal from "../modal"

import Axios from 'axios';
import { connect } from 'react-redux';
class CancelPlan extends Component {
    state = { 

     }
    
    confirmCancelSubscription = () => {
        let subscriptionItem = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
        Axios.post('/api/payment/delete/subscription', {subId: subscriptionItem}).then(res=>{
            if(res.data.deleted === true){
                this.props.cancelled()
            }
        })
    }
    render() { 
        let title = "Cancel Subscription"
        let content = <div>
                                    <p>Are you sure you want to cancel your subscription?</p>
                        <p>Once you cancel your your subscription, your website will stay live until the end of your subscription period.</p>
                        <p>You will have a 2 day grace period after your subscription ends to download your sites data.
                        After the grace period has ended, your sites data will be permanently deleted!</p>
                        <p>If you decide to start the subscription again you will be charged the full amount.</p>
                        <Button style={{float: 'right', background:'red', borderColor: 'red'}} onClick={this.confirmCancelSubscription}>Cancel Subscription</Button>    
        </div>
        return ( 

                            <Modal  show={this.props.show} title={title} content={content} close={this.props.closeCancelModal} />

         );
    }
}
 
function mapStateToProps(state){
    return{
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch){
    return{
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CancelPlan);
