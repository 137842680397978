import React, { Component } from 'react';
import { Card, Table, Form, Button, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'

class AdminLatestActiveUsers extends Component {
    state = {
        latestActive: []
    }
    componentDidUpdate = (props) => {
        if (props.updateCount != this.props.updateCount) {
            this.getData()
        }
    }
    componentDidMount() {
        this.getData()
    }
    getData = () => {
        axios.get("/api/stats/users/get/latest/active").then(res => {
            this.setState({ latestActive: res.data.latestActive })
        })
    }
    render() {
        let latesthtml = []
        this.state.latestActive.forEach(latest => {
            let usershtml = []
            latest.usersWithIp.forEach(us => {
                usershtml.push(<Row>{us.email}</Row>)
            });
            latesthtml.push(<tr key={latest.ip}>
                <td>{latest.ip}</td>
                <td>{usershtml}</td>
                <td>{moment.unix(latest.lastActive).format('DD-MM-YYYY:HH:mm:ss')}</td>
            </tr>)
        })

        return (
            <div style={{}}>
                <Card style={{ margin: '30px' }}>
                    <Card.Header className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                        Latest Active Users
                    </Card.Header>
                    <div className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                        <Table bordered style={{ 'margin': '0px' }}>
                            <thead className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                                <tr>
                                    <th>ip</th>
                                    <th>potential users</th>
                                    <th>last active</th>
                                    <th>created</th>
                                </tr>
                            </thead>
                            <tbody className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                                {latesthtml}
                            </tbody>

                        </Table>

                    </div>
                    <Card.Footer className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                    </Card.Footer>
                </Card>
            </div>
        );
    }
}

export default AdminLatestActiveUsers;