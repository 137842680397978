import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap'
import Axios from "axios"
import BuyDomain from "../components/domain/buyDomain"
import {Switch, Route, Redirect} from "react-router-dom"
import MyDomains from "../components/domain/myDomains"


import { connect } from 'react-redux';
import Navbar from "../components/domain/navbar"
import ProtectedRoute from '../components/routes/protectedRoute'

class Domains extends Component {
    state = {

        dollarToPound: null,
        paymentSuccess: false,
        paymentCancell: false,
        urlParams: ""
    }
    componentDidMount() {
        this.paymentSuccessOrFail()
        this.setExchangeRate()
    }
    closePaymentSuccess = () => {
        this.setState({ paymentSuccess: false })
    }
    closePaymentCancel = () => {
        this.setState({ paymentCancell: false })
    }
    setExchangeRate = async () => {
        Axios.get("/api/domains/get/exchange-rate").then(res => {
            let rate = parseFloat(res.data.dollarToPound).toFixed(2)
            this.setState({ dollarToPound: rate })
        })
    }
 
    paymentSuccessOrFail = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const price = urlParams.get('price');
        const payment = urlParams.get('payment');
        if (payment) {
            if (payment === 'true') {
                window.gtag_report_conversion_purchased(price)
                this.setState({ paymentSuccess: true })
            }
            else {
                this.setState({ paymentCancell: true })
            }
        }
    }

    closeEnter = () => {
        this.setState({ closeEnter: 'true' })
    }
    closeLeave = () => {
        this.setState({ closeEnter: '' })
    }
    render() {
        let params = ""
        const urlParams = new URLSearchParams(window.location.search);
        const price = urlParams.get('price');
        const payment = urlParams.get('payment');
        if (payment) {
            if (payment === 'true') {
                params = "?payment=true&price="+price
            }
            else {
                params = "?payment=false&price="+price
            }
        }
        return (
            <div style={{ width: "100%", margin: "auto" }}>
                <Navbar/>

                {this.state.paymentSuccess &&
                    <Row style={{ margin: '20px' }}>
                        <Card style={{ width: '100%', maxWidth: '1000px', margin: 'auto', backgroundColor: 'rgb(75,181,67)' }}>
                            <div style={{ position: 'absolute', top: '25%', left: '96%', transform: 'translate(-50%, -50%)' }}>
                                <div style={{ width: '30px', height: '30px', backgroundColor: this.state.closeEnter ? 'rgba(0,0,0,0.2)' : '', textAlign: 'center' }} onClick={this.closePaymentSuccess} onMouseEnter={() => { this.closeEnter() }} onMouseLeave={() => this.closeLeave()}><p style={{ color: 'black' }}>x</p></div>
                            </div>
                            <h3 style={{ textAlign: 'center' }}>Payment Success.</h3>
                            <h5 style={{ textAlign: 'center' }}>Your new domain should be shown below.</h5>
                        </Card>
                    </Row>}
                {this.state.paymentCancell &&
                    <Row style={{ margin: '20px' }}>
                        <Card style={{ width: '100%', maxWidth: '1000px', margin: 'auto', backgroundColor: 'rgb(255,69,0)' }}>
                            <div style={{ position: 'absolute', top: '25%', left: '96%', transform: 'translate(-50%, -50%)' }}>
                                <div style={{ width: '30px', height: '30px', backgroundColor: this.state.closeEnter ? 'rgba(0,0,0,0.2)' : '', textAlign: 'center' }} onClick={this.closePaymentCancel} onMouseEnter={() => { this.closeEnter() }} onMouseLeave={() => this.closeLeave()}><p style={{ color: 'black' }}>x</p></div>
                            </div>
                            <h3 style={{ textAlign: 'center' }}>Payment Cancelled.</h3>
                            <h5 style={{ textAlign: 'center' }}>Please try again.</h5>
                        </Card>
                    </Row>}

                <Switch>
                    <ProtectedRoute exact path={"/domains/buy"}> <BuyDomain exchangeRate={this.state.dollarToPound} /> </ProtectedRoute>
                    <ProtectedRoute exact path={"/domains/my-domains"}> <MyDomains/> </ProtectedRoute>

                    <Route><Redirect to={'/domains/buy'+params}/></Route>
                </Switch>






 
                


            </div>
        );
    }
}



function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Domains);





