import React, { Component } from 'react';
import axios from "axios"
import { Button, Form, Row, Col, Card } from 'react-bootstrap'
import Modal from "../components/modal"
import { connect } from 'react-redux';

class AdminDiskSpace extends Component {
    state = {
        backupsBySubscriptions: [],
        showInspectBackup: false,
        inspectBackup: null,
        mongoBackups: null
    }
    interval1 = null
    interval2 = null
    componentDidMount() {
        this.interval1 = setInterval(()=>{
            this.getBackupCountBySubscription()
        },1000)
        this.interval2 = setInterval(()=>{
            this.getMongoBackup()
        },1000)
    }
    getBackupCountBySubscription = () => {
        axios.get("/api/backup/get/backup/count/by/subscription").then(res => {
            let data = res.data.data
            this.setState({ backupsBySubscriptions: data })
        })
    }
    getMongoBackup = () => {
        axios.get("/api/backup/get/mongo/count").then(res => {
            let data = res.data.data
            this.setState({ mongoBackups: data })
        })
    }
    componentWillUnmount(){
        if(this.interval1){
            clearInterval(this.interval1)
        }
        if(this.interval2){
            clearInterval(this.interval2)
        }
    }   
    backupRowClicked = (subscription) => {
        axios.post("/api/backup/get/backup/by/subscription", { id: subscription }).then(res => {
            let data = res.data.data
            this.setState({ inspectBackup: data, showInspectBackup: true })
        })
    }
    closeInspect = () => {
        this.setState({ showInspectBackup: false })
    }

    render() {
        let darkmode = this.props.user.darkmode

        let backups = []
        this.state.backupsBySubscriptions.forEach(backup => {
            backups.push(
                <Row onClick={() => this.backupRowClicked(backup.subscriptionItem)}>
                    <Col>
                        {backup.subscriptionItem}
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                        {backup.backupCount}
                    </Col>
                </Row>
            )
        })
        let backupsInspect = []
        if (this.state.inspectBackup) {
            this.state.inspectBackup.backups.forEach(backup => {
                backupsInspect.push(
                    <Row>
                        <Col>
                            {backup._id}
                        </Col>
                        <Col>
                            {backup.backuptype}
                        </Col>
                        <Col>
                            {backup.date}
                        </Col>
                        <Col>
                            {backup.status}
                        </Col>
                        <Col>
                            {backup.subscriptionItem}
                        </Col>
                    </Row>
                )
            })
        }
        return (<div style={{ width: "100%", margin: "auto" }}>
                <Card style={{ margin: '30px' }}>
                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                    Hosting Mongo Backups
                </Card.Header>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ maxWidth: '100%' }} >
                    <Row>
                        <Col>
                            Count
                        </Col>
                    </Row>
                    {this.state.mongoBackups}

                </div>
                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                </Card.Footer>
            </Card>
            <Card style={{ margin: '30px' }}>
                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                    Backups
                </Card.Header>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ maxWidth: '100%' }} >
                    <Row>
                        <Col>
                            Subscription
                        </Col>
                        <Col>
                            User
                        </Col>
                        <Col>
                            No.Backups
                        </Col>
                    </Row>
                    {backups}

                </div>
                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                </Card.Footer>
            </Card>
            <Modal show={this.state.showInspectBackup} title={<h1>Backups</h1>} content={backupsInspect} close={this.closeInspect} />





        </div>





        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDiskSpace);






