import React, { Component } from 'react';
import { Card, Table, Form, Button, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import chart from 'chart.js'
import EmailUser from '../components/admin/emailUser'
import EmailEveryone from '../components/admin/emailEveryone'
import EmailNewsletter from '../components/admin/emailNewsletter'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

class AdminDeleteIp extends Component {
  state = {
    faqs: [
    ]
  }
  componentDidMount() {
    this.getAll()
  }
  publish = (id, publish) => {
    axios.post("/api/faq/publish", { id: id, publish: publish }).then(res => {
      if (res.data.success) {

        let tmp = this.state.faqs
        let index = tmp.findIndex(x => x._id === id)
        tmp[index].published = publish
        this.setState({ faqs: tmp })
      }
    })
  }
  createNew = () => {
    axios.get("/api/faq/create").then(res => {
      if (res.data.success) {
        let tmp = this.state.faqs
        tmp.push({ content: [], title: "", _id: res.data.faq._id })
        this.setState({ faqs: tmp })
      }
    })
  }
  getAll = () => {
    axios.get("/api/faq/get/all").then(res => {
      this.setState({ faqs: res.data.faqs })
    })
  }

  render() {
    let darkmode = this.props.user.darkmode

    let faqs = []
    this.state.faqs.forEach((faq) => {

      faqs.push(
        <Row>
          <Col>{faq.title ? faq.title : "n/a"}</Col>
          <Col><Button style={{ width: '100%', backgroundColor: '#343a40' }} type='submit' as={Link} to={`/admin/faq/edit?id=${faq._id}`}>Edit</Button></Col>
          <Col>{faq.published ? <Button style={{ width: '100%', backgroundColor: 'red' }} onClick={() => this.publish(faq._id, false)}>Hide</Button> : <Button style={{ width: '100%', backgroundColor: 'green' }} onClick={() => this.publish(faq._id, true)}>Publish</Button>}</Col>
        </Row>)

    })
    return (
      <div style={{ marginTop: '60px', marginBottom: '150px' }}>
        <Card style={{ margin: '30px' }}>
          <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
            Faqs
          </Card.Header>
          <div style={{ maxWidth: '100%' }} className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} >
            <Button style={{ width: '100%' }} onClick={() => this.createNew()}>Create New</Button>
            <Row>
              <Col><h4>Title</h4></Col>
              <Col><h4>Edit</h4></Col>
              <Col><h4>Show / Hide</h4></Col>
            </Row>
            <hr />
            {faqs}
          </div>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDeleteIp);





