import * as types from './actionsTypes'
import axios from 'axios'

export const addBasketItem = (data) => async dispatch => {

    const res = await axios.post(`/api/basket/add/item/to/basket`, data)
    dispatch( {
        type: types.ADD_BASKET_ITEM,
        payload: res.data.basket
    })
}
export const setBasket = (data) => async dispatch => {
    const res = await axios.get(`/api/basket/get/basket`)
    if(!res.data.basket[0]){
        return
    }
    dispatch({
        type: types.SET_BASKET,
        payload: res.data.basket
    })
}
export const removeItem = (data) => async dispatch => {
    const res = await axios.post(`/api/basket/remove/item`, data)
    dispatch({
        type: types.REMOVE_ITEM,
        payload: res.data.basket
    })
}
export const setContactIndex = (data) => async dispatch => {
    dispatch({
        type: types.SET_CONTACT_INDEX,
        payload: data
    })
}