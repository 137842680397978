import React, { Component } from 'react';
import {Card, Button} from 'react-bootstrap'
import Axios from 'axios';
import { connect } from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import Modal from "../../components/modal"
import Htpasswd from "./htpasswd"
class SiteDetails extends Component {
    state = { 
        storage: '0',
        showDelete: false,
        deleteBtnEnabled: true,
        sqlUsername: '',
        sqlPassword: '',
        ftpPassword: "",
        ftpUsername: ""
     }
    componentDidMount(){
        let subid = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
        Axios.post('/api/sites/get-disk-usage', {subId: subid}).then(res=>{
            this.setState({storage: res.data.size})
        })
        Axios.post('/api/sites/getSqlLogin', {subId: subid}).then(res=>{
            this.setState({sqlUsername: res.data.username, sqlPassword: res.data.password})
        })
        Axios.post('/api/sites/getftpLogin', {subId: subid}).then(res=>{
            this.setState({ftpUsername: res.data.username, ftpPassword: res.data.password})
        })
    }
    deleteSite = () => {
        let subid = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
        this.setState({deleteBtnEnabled: false})
        Axios.post('/api/sites/delete',{subId: subid}).then(()=>{
            this.closeDeleteModal()
            this.props.refresh()
        })
    }
    openDeleteConfirm = () => {
        this.setState({showDelete: true})
    }
    closeDeleteModal = () => {
        this.setState({showDelete: false})
    }
    render() { 
        let darkmode = this.props.user.darkmode

        let planStorage = 0
        switch (this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].plan) {
            case 'Basic Hosting':
                planStorage = '5'
                break;
            case 'Standard Hosting':
                planStorage = '10'
                break;
            case 'Advanced Hosting':
                planStorage = '20'
                break;
            default:
                planStorage = 'Error'
                break;
        

        }
        let hint = ''
        switch (this.props.type) {
            case 'wordpress':
                hint='wordpress admin pannel'
                break;
            case 'dokuwiki':
                hint='dokuwiki install page'
                break;
            default:
                break;
        }
        let title = "Delete website"
        let content =        <div>     <p>Are you sure you want to delete your site?</p>
        <p>All data associated to your website will be irreversibly deleted and your site will revert to a BreezeLabs Hosting parking page. You can then install another site.</p>
        <Button style={{float: 'right', background:'red', borderColor: 'red'}} disabled={!this.state.deleteBtnEnabled} onClick={this.deleteSite}>Delete Site</Button>    
</div>
        return ( 
            <div>
            <Card style={{width: '80%',margin: 'auto', display: 'block', marginTop: '60px'}}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{textAlign: 'center'}}>
                        Website Details
                    </Card.Header>
                    <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{textAlign: 'center'}}>
                    <p style={{fontSize:'12px', padding: "6px 0"}}><FontAwesomeIcon  size='1x' icon={faInfoCircle}/> Tip: If you have just installed your website, make sure to create a user for it ASAP by clicking the {hint} link below!</p>
                        <p>Domain: <a href={'http://'+this.props.domain}>{this.props.domain}</a></p>
                        <p>Type: {this.props.type}</p>
                        {this.props.type === 'wordpress' && <p>Wordpress admin pannel: <a href={'http://'+this.props.domain + '/wp-admin'}>{this.props.domain + '/wp-admin'}</a></p>}
                        {this.props.type === 'dokuwiki' && <p>DokuWiki install page: <a href={'http://'+this.props.domain + '/install.php'}>{this.props.domain + '/install.php'}</a></p>}
                        <p>Storage used: {this.state.storage}/{planStorage}GB</p>
                        <p>PHPMyAdmin: <a href={'http://'+this.props.domain+'/phpmyadmin'}>http://{this.props.domain}/phpmyadmin</a></p>
                        <p>PHPMyAdmin Username: {this.state.sqlUsername}</p>
                        <p>PHPMyAdmin Password: {this.state.sqlPassword}</p>
                        <div>
                        <p>FTP IP: 18.133.82.127</p>
                        <p>FTP Port: 21</p>
                        <p>FTP Username: {this.state.ftpUsername}</p>
                        <p>FTP Password: {this.state.ftpPassword}</p>
                        </div>
  

                        <Button className='button-warning' style={{marginBottom: '20px', width: "200px"}} onClick={this.openDeleteConfirm}>Delete</Button>
                    </div>
                    
                </Card>
                {
                    this.props.user.email == "thomas.breeze@icloud.com" &&
                    <Htpasswd/>

                }
                <Modal  show={this.state.showDelete} title={title} content={content} close={this.closeDeleteModal} />
                </div>
         );
    }
}
 
function mapStateToProps(state){
    return{
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch){
    return{
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SiteDetails);

