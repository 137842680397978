import React, { Component } from 'react';
import {loadStripe} from '@stripe/stripe-js'
import axios from 'axios'
import {Button, Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom';

class StripeContainerRecurring extends Component {
    state = { 
        session: null,
        key: '',
        showPurchasePolicy: false,
        loading: false
     }
     componentDidMount = () => {
         axios.get('/api/payment/get/key').then(res=>{
            this.setState({key: res.data.key})
         })
     }
    
    handleClick =  async (selectedId) => {
        this.setState({loading: true})
        const promise = loadStripe(this.state.key)
        
        axios.post('/api/payment/create-payment-intent/recurring', {selectedPrice: this.props.priceId, value: this.props.value, restartedSubId: this.props.restartId}).then(async res=>{
            const stripe = await promise
            let sessionId = res.data.id
            await stripe.redirectToCheckout({
                sessionId,
            })
        })
    }

    render() { 
        return ( 
            <div>
               
                <Button  disabled={this.props.disabled} style={{width: '200px'}}  role='link' onClick={this.handleClick}>
                {!this.state.loading ? <div>Buy Now</div> : <Spinner animation="border" vaiant="secondary" style={{ width: '20px', height: '20px', fontSize: '20px' }} />}

                    </Button>
 
            </div>
         );
    }
}
 
export default StripeContainerRecurring;