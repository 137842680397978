import React, { Component } from 'react';
import {Button, Modal, Form} from 'react-bootstrap'
import Axios from 'axios';
import { connect } from 'react-redux';
class DeleteDomainName extends Component {
    state = { 
        deleteEnabled: false,
        websiteDeleteAgreed: false
     }
    removeDomainName = () => {
        let subid = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
        this.setState({deleteEnabled: false, websiteDeleteAgreed: false})
        Axios.post('/api/sites/delete-url',{subId: subid}).then(res=>{
            if(res.data.success){
                this.props.closeDeleteDomainNameModal()
            }
        })
    }
    websiteDeleteAgreed=()=>{
        this.setState({websiteDeleteAgreed: !this.state.websiteDeleteAgreed},()=>{
            if(this.state.websiteDeleteAgreed){
                this.setState({deleteEnabled: true})
            }else{
                this.setState({deleteEnabled: false})
            }
        })
        
    }
    render() { 
        let darkmode = this.props.user.darkmode

        return ( 
            <Modal  onHide={this.props.closeDeleteDomainNameModal} show={this.props.show}>
                    <Modal.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                        <Modal.Title>
                            Remove Domain Name
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                        
                        <p>In order to remove your domain name, the associated website will be deleted. If you agree to the deletion of your website, select the checkbox below. If you wish to keep your domain and website, click Cancel</p>
                        <Form.Control checked={this.state.websiteDeleteAgreed}  onChange={(e)=>{this.websiteDeleteAgreed()}} type="checkbox"/>
                    </Modal.Body>
                    <Modal.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                        <div style={{width: '100%'}}>
                            <Button className="button-warning" style={{float: 'right'}} onClick={this.removeDomainName} disabled={!this.state.deleteEnabled}>Remove Domain Name</Button>    
                            <Button style={{float: 'left',backgroundColor: '#343a40'}} onClick={this.props.closeDeleteDomainNameModal}>Cancel</Button>    
                        </div>
                        
                    </Modal.Footer>
                </Modal>
         );
    }
}
 
function mapStateToProps(state){
    return{
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch){
    return{
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DeleteDomainName);
