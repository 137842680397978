import React, { Component } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import { connect } from 'react-redux';

class CustomInstaller extends Component {
    state = { 
        installEnabled: true,
        errormsg: '',
        expanded: '',
     } 
    installParking = () => {
        if (!this.props.disabled) {
            this.setState({ installEnabled: false })
            let subid = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
            Axios.post('/api/sites/create', { type: 'parking', subId: subid }).then(res => {
                if (res.data.success) {
                    this.setState({ errormsg: res.data.message })
                    this.props.refresh()
                } else {
                    this.setState({ errormsg: res.data.message, installEnabled: true })
                }
            })
        }
    }

    expandDescription = (type) => {
        if (this.state.expanded === type) {
            this.setState({ expanded: '' })
        } else {
            this.setState({ expanded: type })
        }
    }
    render() { 
        let darkmode = this.props.user.darkmode

        return (
            <Col>
            <Card style={{ margin: 'auto', minWidth: '250px', display: 'block', marginTop: '20px', marginBottom: '20px', maxWidth: '500px' }}>
                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                    Custom
                </Card.Header>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ textAlign: 'center', paddingTop: "10px", marginBottom: "0px", overflow: 'hidden', height: this.state.expanded == 'parking' ? '' : '300px' }}>
                    <img alt='wordpress' style={{ width: '60px' }} src={require('../../../wordpress.jpg')}></img>
                    <p style={{ ...{ padding: '20px', height: this.state.expanded == 'wordpress' ? '' : '200px', overflow: 'parking' } }}>
                        Custom website installer. You can select from a range of databases and index file locations. Your custom website can then be uploaded via FTP. 
                    </p>
                    <p style={{ height: '20px', marginTop: "-15px" }} onClick={() => this.expandDescription('parking')}><FontAwesomeIcon size='2x' icon={this.state.expanded === 'parking' ? faChevronUp : faChevronDown} /></p>
                </div>
                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                    {this.props.disabled ? <p style={{ color: 'red' }}>A domain name is required to enable installation.</p> : <Button disabled={this.props.disabled} onClick={this.installParking} disabled={!this.state.installEnabled} >Install</Button>}
                    {this.state.errormsg}
                </Card.Footer>
            </Card>
        </Col>
        );
    }
}
 
function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomInstaller);