import React, { Component } from 'react';
import {Redirect} from "react-router-dom"
import SiteBackup from '../../../components/myHosting/siteBackup'
import {Card, Button} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLock, faUnlock} from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import {setSubscriptionsCancelled} from '../../../actions/subscriptionsActions'
import { connect } from 'react-redux';

class Backups extends Component {
    state = { domain: null } 
    componentDidMount(){
        if(this.props.subscriptions.detailedIndex !== null){
            this.getSiteData()    

        }
        
        
    }

    getSiteData=()=>{

        let subid = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
        Axios.post('/api/sites/get-site',{subId: subid}).then(req=>{
            if(req.data.site){
                this.setState({domain: req.data.domain})
            }else{
                this.setState({domain: null})
            }
        }) 
    
    }
    render() { 
        let subscription = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex]
        if(this.props.subscriptions.detailedIndex === null){
            return <Redirect to='/my-hosting'/>
        }
        let manageSiteAllowed = subscription.status != 'waiting' && subscription.status != 'expired'
        let allowedProducts = ["Standard Hosting", "Advanced Hosting"]

        let backupsEnabled = false
        if(allowedProducts.includes(subscription.plan)){
            backupsEnabled = true
        }

        return (<div>
                            {manageSiteAllowed ? 
                    <div>
                        
                        
                        {backupsEnabled ?
                                                <SiteBackup domain={this.state.domain}/>

                        :<Card style={{width: '80%',margin: 'auto', display: 'block', marginTop: '20px'}}>
                        <Card.Header style={{marginBottom: '10px',textAlign: 'center'}}>
                            Backups
                        </Card.Header>
                        <Card.Text style={{textAlign: 'center'}}>
                            <p>Your hosting plan does not support backups. Upgrade your plan to access Backups.</p>
                            <FontAwesomeIcon style={{paddingBottom: '20px'}}  size='4x' icon={faLock}/>
                        </Card.Text>
                    </Card>
    
                        
                        }
                    </div>
                    :
                    <div>
                        <Card style={{width: '80%',margin: 'auto', display: 'block', marginTop: '20px'}}>
                            <Card.Header style={{marginBottom: '10px',textAlign: 'center'}}>
                                Manage Site
                            </Card.Header>
                            <Card.Text style={{textAlign: 'center'}}>
                            <FontAwesomeIcon style={{paddingBottom: '20px'}}  size='4x' icon={faLock}/>
                                <p>Your subscription status must be active to manage your site.</p>
                            </Card.Text>
                        </Card>
                    </div>
                }
        </div>);
    }
}
 
function mapStateToProps(state){
    return{
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch){
    return{
        setSubscriptionsCancelled: (index)=>{dispatch(setSubscriptionsCancelled(index))}
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Backups);

