import React, { Component } from 'react';
import { Button, Form, Row, Col, Card } from 'react-bootstrap'
import { connect } from 'react-redux';
import axios from "axios"

class AdminLogs extends Component {
    state = {
        domainOffersActive: 0,
        logs: {}
    }
    componentDidMount(){
        this.getData()
    }
    getData = async()=>{
        axios.get("/api/logs/get/all").then(res=>{
            this.setState({logs: res.data.data})
        })

    }
    emptyFile=(file)=>{
        axios.post("/api/logs/clear/by/name", {file: file}).then(res=>{
            this.getData()
        })
    }
    render() {
        let darkmode = this.props.user.darkmode

        return (<div>
            <Card style={{ margin: '30px' }}>
                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                hosting-server-out
                </Card.Header>
                <Button style={{width: '100%'}}  type='submit' onClick={()=>this.emptyFile("hosting-server-out")}>Empty file</Button>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{textAlign: "center"}}>
                <pre style={{ whiteSpace: 'pre-wrap' , textAlign: "left", color: "white"}}>

                    {this.state.logs.stdout}
                    </pre>
                </div>
                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                </Card.Footer>
            </Card>
            <Card style={{ margin: '30px' }}>
                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                hosting-server-error
                </Card.Header>
                <Button style={{width: '100%'}}  type='submit' onClick={()=>this.emptyFile("hosting-server-error")}>Empty file</Button>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{textAlign: "center"}}>
                <pre style={{ whiteSpace: 'pre-wrap' , textAlign: "left", color: "white"}}>

                {this.state.logs.stderr}
</pre>
                </div>
                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                </Card.Footer>
            </Card>
           
        </div>);
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogs);






