import React, { Component } from 'react';
import { Button, } from 'react-bootstrap'
import Modal from "../components/modal"

class Faq extends Component {
    state = { 
        show: false
     }
     closeModal = () => {
         this.setState({show: false})
     }
     openModal = () => {
        this.setState({show: true})
    }
    render() { 
        let content = []
        this.props.data.content.forEach(item=>{
            let taggedItem = item.content
            if(item.type){
                if(item.type.p){
                    taggedItem = <p>{taggedItem}</p>
                }if(item.type.b){
                    taggedItem = <b>{taggedItem}</b>
                }if(item.type.u){
                    taggedItem = <u>{taggedItem}</u>
                }if(item.type.li){
                    taggedItem = <li>{taggedItem}</li>
                }if(item.type.ol){
                    taggedItem = <ol>{taggedItem}</ol>
                }if(item.type.h1){
                    taggedItem = <h1>{taggedItem}</h1>
                }if(item.type.h2){
                    taggedItem = <h2>{taggedItem}</h2>
                }if(item.type.h3){
                    taggedItem = <h3>{taggedItem}</h3>
                }if(item.type.h4){
                    taggedItem = <h4>{taggedItem}</h4>
                }if(item.type.h5){
                    taggedItem = <h5>{taggedItem}</h5>
                }if(item.type.a){
                    taggedItem = <a target="#blank" href={item.link}>{taggedItem}</a>
                }
            }
            
            content.push(taggedItem)
        })
        return ( 
        <div style={{margin: '20px'}}>
            <Button onClick={this.openModal} style={{padding: '5px', width: '100%',backgroundColor: '#343a40'}}>{this.props.data.title}</Button> 
            <Modal  show={this.state.show} title={<h1>{this.props.data.title}</h1>} content={content} close={this.closeModal} />
        </div>
        );
    }
}
 
export default Faq;