import initialState from './initialState'
import { ADD_BASKET_ITEM, SET_BASKET, REMOVE_ITEM, SET_CONTACT_INDEX } from '../actions/actionsTypes'
import _ from 'lodash'
export default function basket(state = initialState.basket, action) {
    let newState = _.cloneDeep(state)
    switch (action.type) {

        case ADD_BASKET_ITEM:
            newState = action.payload
            return newState
        case SET_BASKET:
            newState = action.payload[0].contents
            return newState
        case REMOVE_ITEM:
            newState = action.payload
            return newState
        case SET_CONTACT_INDEX:
            if(newState[action.payload].active){
                newState[action.payload].active = false}
            else{
                newState[action.payload].active = true

            }
            return newState
        default:
            return state
    }
}

