import React, { Component } from 'react';
import { connect } from 'react-redux';

class Modal extends Component {
    render() {
        if (!this.props.show) {
            return null;
        }
        let darkmode = this.props.user ? this.props.user.darkmode : true

        return (
            <div  className="bl-modal" onClick={this.props.close}>
                <div style={this.props.width && {width: this.props.width}} className="bl-modal-content" onClick={e => e.stopPropagation()}>
                    <div style={{width: "100%"}} className={darkmode? "darkmode-card-header" + " bl-modal-title" :"lightmode-card-header" + " bl-modal-title"}  >{this.props.title}</div>
                    <div className={darkmode? "darkmode-card-content" + " bl-modal-body" :"lightmode-card-content" + " bl-modal-body"} style={this.props.height &&{height: this.props.height}}>{this.props.content}</div>
                    <div className={darkmode? "darkmode-card-header" + " bl-modal-footer" :"lightmode-card-header" + " bl-modal-footer"}>
                        <button onClick={this.props.close} className="button">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setUser: (data) => { dispatch(setUser(data)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
