import React, { Component } from 'react';
import { Card, Row } from 'react-bootstrap'
import { connect } from 'react-redux';
import ParkingInstaller from "../myHosting/websiteInstaller/parkingInstaller"
import DokuWikiInstaller from "../myHosting/websiteInstaller/dokuWikiInstaller"
import WordpressInstaller from './websiteInstaller/wordpressInstaller';
import CustomInstaller from './websiteInstaller/customInstaller';

class WebsiteInstaller extends Component {
    state = {

    }

    render() {
        let darkmode = this.props.user.darkmode

        return (
            <div>
                <Card style={{ width: '80%', margin: 'auto', display: 'block', marginTop: '20px' }}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ marginBottom: '10px', textAlign: 'center' }}>
                        Website Installer
                    </Card.Header>
                    <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', marginTop: "-10px" }}>
                        <Row>
                            <WordpressInstaller disabled={this.props.disabled} refresh={this.props.refresh} />
                            <DokuWikiInstaller disabled={this.props.disabled} refresh={this.props.refresh} />
                            {this.props.user.admin == "true"  && <CustomInstaller disabled={this.props.disabled} refresh={this.props.refresh} />} 
                            {this.props.user.admin === 'true' &&
                                <ParkingInstaller disabled={this.props.disabled} refresh={this.props.refresh} />
                            }
                        </Row>
                    </div>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteInstaller);