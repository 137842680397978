import React, { Component } from 'react';
import { Button, Form, Row, Col, Card } from 'react-bootstrap'
import axios from 'axios'
import _ from 'lodash'
import { connect } from 'react-redux';
import qs from 'qs'
class EmailNewsletter extends Component {
    state = {
        id: "",
        title: "",
        content: [],
        published: true
    }
    componentDidMount(){
        
        let id = qs.parse(new URL(window.location.href).search.substring(1), {ignoreQueryPrefix: true}).id
        this.setState({id: id},()=>{
            this.getFaq()

        })
    }
    
    getFaq=()=>{
        axios.post("/api/faq/get/by/id",{id:this.state.id}).then(res=>{
            if(res.data.success){
               let faq = res.data.faq
               this.setState({title: faq.title, content: faq.content, published: faq.published})
            }
        })
    }
    updateValue = (e, field) => {
        this.setState({ [field]: e.target.value })
    }
    updateValueForItem = (e, i) => {
        let tmpArr = this.state.content
        tmpArr[i].content = e.target.value
        this.setState({ "content": tmpArr })
    }
    updateLinkForItem = (e, i) => {
        let tmpArr = this.state.content
        tmpArr[i].link = e.target.value
        this.setState({ "content": tmpArr })
    }
    addRow = () => {
        let tmp = this.state.content
        tmp.push({ content: "", type: {} })
        this.setState({ "content": tmp })
    }
    changeType = (type, i) => {
        let tmp = this.state.content
        tmp[i].type[type] = !tmp[i].type[type]
        this.setState({"content": tmp})
    }
    publish=(id, publish)=>{
        axios.post("/api/faq/publish",{id:id, publish: publish}).then(res=>{
            if(res.data.success){
                this.setState({published: publish})
            }
        })
      }
      save=()=>{
        axios.post("/api/faq/save",{id: this.state.id, title: this.state.title, content: this.state.content}).then(res=>{
        })
      }
    render() {
        let darkmode = this.props.user.darkmode

        let contentRows = []
        for (let i = 0; i < this.state.content.length; i++) {
            if(!this.state.content[i].type){
                this.state.content[i].type = []
            }
            contentRows.push(
                <div>
                    <Row>
                        <Col>
                            <Row>
                                <Form.Control style={{minWidth: '20px'}} checked={this.state.content[i].type["p"] == true} onClick={()=>this.changeType("p", i)} type="checkbox"/>
                            </Row>
                            <Row>
                                <p style={{margin: "auto"}}>p</p>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                            <Form.Control style={{minWidth: '20px'}} checked={this.state.content[i].type["li"] == true}onClick={()=>this.changeType("li", i)} type="checkbox"/>

                            </Row>
                            <Row>
                            <p style={{margin: "auto"}}>li</p>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Form.Control style={{minWidth: '20px'}} checked={this.state.content[i].type["b"] == true} onClick={()=>this.changeType("b", i)} type="checkbox"/>

                            </Row>
                            <Row>
                            <p style={{margin: "auto"}}>b</p>
                            </Row>

                            </Col>

                            <Col>
                                <Row>
                                    <Form.Control style={{minWidth: '20px'}} checked={this.state.content[i].type["u"] == true} onClick={()=>this.changeType("u", i)} type="checkbox"/>
                                </Row>
                                <Row>
                                <p style={{margin: "auto"}}>u</p>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Form.Control style={{minWidth: '20px'}} checked={this.state.content[i].type["ol"] == true} onClick={()=>this.changeType("ol", i)} type="checkbox"/>
                                </Row>
                                <Row>
                                <p style={{margin: "auto"}}>ol</p>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Form.Control style={{minWidth: '20px'}} checked={this.state.content[i].type["h1"] == true} onClick={()=>this.changeType("h1", i)} type="checkbox"/>
                                </Row>
                                <Row>
                                <p style={{margin: "auto"}}>h1</p>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Form.Control style={{minWidth: '20px'}} checked={this.state.content[i].type["h2"] == true} onClick={()=>this.changeType("h2", i)} type="checkbox"/>
                                </Row>
                                <Row>
                                <p style={{margin: "auto"}}>h2</p>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Form.Control style={{minWidth: '20px'}} checked={this.state.content[i].type["h3"] == true} onClick={()=>this.changeType("h3", i)} type="checkbox"/>
                                </Row>
                                <Row>
                                <p style={{margin: "auto"}}>h3</p>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Form.Control style={{minWidth: '20px'}} checked={this.state.content[i].type["h4"] == true} onClick={()=>this.changeType("h4", i)} type="checkbox"/>
                                </Row>
                                <Row>
                                <p style={{margin: "auto"}}>h4</p>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Form.Control style={{minWidth: '20px'}} checked={this.state.content[i].type["h5"] == true} onClick={()=>this.changeType("h5", i)} type="checkbox"/>
                                </Row>
                                <Row>
                                <p style={{margin: "auto"}}>h5</p>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Form.Control style={{minWidth: '20px'}} checked={this.state.content[i].type["a"] == true} onClick={()=>this.changeType("a", i)} type="checkbox"/>
                                </Row>
                                <Row>
                                <p style={{margin: "auto"}}>a</p>
                                </Row>
                            </Col>
    
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control as='textarea' value={this.state.content[i].content} placeholder='Content' onChange={(e) => this.updateValueForItem(e, i)} />
                        </Col>
                    </Row>
                    {this.state.content[i].type["a"] && <Row>
                        <Col>
                            <Form.Control as='textarea' value={this.state.content[i].link} placeholder='link' onChange={(e) => this.updateLinkForItem(e, i)} />
                        </Col>
                    </Row>}
                </div>
            )
        };


        return (<div >
            <Card style={{ margin: '30px' }}>
                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                    Faq
                </Card.Header>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ maxWidth: '100%' }} >
                    <Row>
                        <Col xs={4}>
                            Title

                        </Col>
                        <Col>
                            <Form.Control as='textarea' value={this.state.title} placeholder='e.g. How to cancel a plan' onChange={(e) => this.updateValue(e, 'title')} />

                        </Col>

                    </Row>

                    {contentRows}
                    <Row><Col> <Button style={{width: "100%", margin: "10px"}} onClick={this.addRow}>Add Row</Button></Col></Row>
                    <Row><Col> <Button style={{width: "100%", margin: "10px"}} onClick={this.save}>Save</Button></Col></Row>
                    {!this.state.published ? <Row><Col> <Button style={{width: "100%", margin: "10px", background: "green"}} onClick={()=>this.publish(this.state.id, true)}>Publish</Button></Col></Row>:
                    <Row><Col> <Button style={{width: "100%", margin: "10px", background: "red"}} onClick={()=>this.publish(this.state.id, false)}>Hide</Button></Col></Row>}
                </div>
                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                </Card.Footer>
            </Card>
        </div>);
    }
}
function mapStateToProps(state) {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps)(EmailNewsletter);