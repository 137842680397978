import React, { Component } from 'react';
import { Button, Form, Row, Col, Card } from 'react-bootstrap'
import { connect } from 'react-redux';
import axios from "axios"

class AdminOffers extends Component {
    state = {
        domainOffersActive: 0
    }
    componentDidMount(){
        this.getData()
    }
    getData = async()=>{
        axios.get("/api/domains/sale/active").then(res=>{
            this.setState({domainOffersActive: res.data.amount})
        })
    }
    render() {
        let darkmode = this.props.user.darkmode

        return (<div>
            <Card style={{ margin: '30px' }}>
                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                    Domain Offers Available
                </Card.Header>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{textAlign: "center"}}>
                    {this.state.domainOffersActive}
                </div>
                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                </Card.Footer>
            </Card>
           
        </div>);
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminOffers);






