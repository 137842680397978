import React, { Component } from 'react';
import { Card, Table, Form, Button, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import chart from 'chart.js'
import moment from 'moment'

class AdminNewStatsGraph extends Component {
    state = {}
    newStatsChartRef = React.createRef()

    componentDidMount() {
        this.getNewGraphData()
    }
    genNewGraph = (viewGraphData) => {
        const chartFromRef = this.newStatsChartRef.current.getContext('2d')
        if (this.newchartInstance) {

            const hiddenDatasets = [];
            this.newchartInstance.data.datasets.forEach((dataset, index) => {
                for (const key in dataset._meta) {
                    if (dataset._meta[key].hidden == true) {
                        viewGraphData.datasets[index].hidden = true

                    }
                }

            });


            this.newchartInstance.data = viewGraphData
            this.newchartInstance.options.animation = false;

            this.newchartInstance.update();


        } else {
            this.newchartInstance = new chart(chartFromRef, {
                type: 'line',
                data: viewGraphData,
                options: {
                    scales: {
                        xAxes: [{
                            gridLines: { color: "gray" },
                            ticks: {
                                fontColor: 'white'
                            }
                        }],
                        yAxes: [{
                            gridLines: { color: "gray" },
                            ticks: {
                                fontColor: 'white'
                            }
                        }],

                    }
                }
            })
        }


    }
    componentDidUpdate=(props)=>{
        if(props.updateCount != this.props.updateCount){
            this.getNewGraphData()
        }
    }
    getNewGraphData = async () => {
        let graphdata = {}
        await new Promise((res, rej) => {
            axios.get('/api/stats/views/get/all').then((resp) => {

                if (resp.data.views !== this.state.viewCount) {
                    graphdata = resp.data.viewDataForGraph
                }
                res()
            })
        })

        this.genNewGraph(graphdata)

    }
    render() {
        return (
            <Row>
                <Col>
                    <Card className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ margin: '30px' }}>
                        <Card.Header className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                            New Stats
                        </Card.Header>
                        <canvas style={{ maxHeight: '500px', maxWidth: '1000px', margin: 'auto', color: "white" }} id='linechart1' ref={this.newStatsChartRef} />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default AdminNewStatsGraph;