import React, { Component } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap'
import Axios from 'axios';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
class DokuWikiInstaller extends Component {
    state = {  
        installEnabled: true,
        errormsg: '',
        expanded: '',
    } 
    expandDescription = (type) => {
        if (this.state.expanded === type) {
            this.setState({ expanded: '' })
        } else {
            this.setState({ expanded: type })
        }
    }
    installDokuWiki = () => {

        if (!this.props.disabled) {
            this.setState({ installEnabled: false })
            let subid = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
            Axios.post('/api/sites/create', { type: 'dokuwiki', subId: subid }).then(res => {
                if (res.data.success) {
                    this.setState({ errormsg: res.data.message })
                    this.props.refresh()
                } else {
                    this.setState({ errormsg: res.data.message, installEnabled: true })
                }
            })
        }

    }
    render() { 
        let darkmode = this.props.user.darkmode

        return (

            <Col>
            <Card style={{ margin: 'auto', minWidth: '250px', display: 'block', marginTop: '20px', marginBottom: '20px', maxWidth: '500px' }}>
                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                    DokuWiki
                </Card.Header>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ textAlign: 'center', paddingTop: "10px", marginBottom: "0px", overflow: 'hidden', height: this.state.expanded == 'dokuwiki' ? '' : '300px' }}>

                    <img alt='wordpress' style={{ width: '60px' }} src={require('../../../Dokuwiki_logo.png')}></img>
                    <p style={{ padding: '20px', height: this.state.expanded == 'dokuwiki' ? '' : '200px', overflow: 'hidden', textAlign: 'left' }}>
                        DokuWiki is one of the most popular choices for Wiki software due to its:
                        <ul>
                            <li>Ease of use.</li>
                            <li>Built in access control lists.</li>
                            <li>Large variety of extensions.</li>
                            <li>Ease of use.</li>
                            <li>Open source.</li>
                        </ul>
                        Use cases:
                        <ul>
                            <li>Business knowledge base.</li>
                            <li>Public knowledge base.</li>
                            <li>Private notebook.</li>
                            <li>Public blog.</li>
                            <li>Project workspace.</li>
                            <li>Blog.</li>
                        </ul>
                    </p>
                    <p style={{ height: '20px', marginTop: "-15px" }} onClick={() => this.expandDescription('dokuwiki')}><FontAwesomeIcon size='2x' icon={this.state.expanded === 'dokuwiki' ? faChevronUp : faChevronDown} /></p>
                </div>
                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                    {this.props.disabled ? <p style={{ color: 'red' }}>A domain name is required to enable installation.</p> : <Button disabled={this.props.disabled} onClick={this.installDokuWiki} disabled={!this.state.installEnabled}>Install</Button>}
                    {this.state.errormsg}
                </Card.Footer>
            </Card>
        </Col>
        );
    }
}
 

function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DokuWikiInstaller);