import React, { Component } from 'react';
import {Row, Button, Col, Spinner} from 'react-bootstrap'
import {Link} from "react-router-dom"
import HostingCard from './hostingCard'
import { connect } from 'react-redux';

class AddPlanCard extends Component {
    state = {
        hovered: false
    }
    openDetails = ()=>{
        this.setState({hovered: true})
    }
    closeDetails = ()=>{
        this.setState({hovered: false})
    }
        render() { 
            let darkmode = this.props.user.darkmode

        return ( 
            <HostingCard>
                
                <Button as={Link} to='/plans' style={{backgroundColor: this.state.hovered? darkmode ? "#2a2e32": "white" : darkmode?'#343a40': "white", color: darkmode? "white":'black', width: "100%", height: "100%" }} onMouseEnter={this.openDetails} onMouseLeave={this.closeDetails}>
                    <Row style={{top: "35%", position: 'relative',marginLeft: "0px"}}>
                        <Col>
                                <Row>
                                    <Col style={{maxWidth: '10%'}}>
                                        <Spinner animation="border" vaiant="secondary" style={{width: '50px', height:'50px', fontSize: '30px'}} />
                                    </Col>
                                    <Col style={{marginLeft: '10px', maxWidth: '300px'}}>
                                        <p style={{fontSize: '30px'}}>Loading Plans</p>
                                    </Col>
                                </Row>
                        </Col>
                    </Row>
                </Button>
            </HostingCard>        
        );
    }
}
function mapStateToProps(state){
    return{
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch){
    return{
        setSubscriptionsDetailedIndex: (data)=>{dispatch(setSubscriptionsDetailedIndex(data))}
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AddPlanCard);
