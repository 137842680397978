import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import { Button, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom';

class StripeContainerRecurring extends Component {
    state = {
        session: null,
        key: '',
        showPurchasePolicy: false,
        loading: false
    }
    componentDidMount = () => {
        axios.get('/api/payment/get/key').then(res => {
            this.setState({ key: res.data.key })
        })
    }

    handleClick = async (selectedId) => {
        this.setState({ loading: true })
        let contactsAndPrivacy = this.props.contactsAndPrivacy()
        this.props.domainCheckoutClicked()
        const promise = loadStripe(this.state.key)
        let postobj = {
            autoRenew: this.props.autoRenew, years: this.props.years, yearlyPrice: this.props.yearlyPricePound, domain: this.props.domain, contactsAndPrivacy: contactsAndPrivacy
        }
        if(this.props.offerPrice && this.props.years == 1){
            postobj.offerPrice = this.props.offerPrice
        }
        axios.post('/api/payment/create-payment-intent/domain', postobj).then(async res => {
            const stripe = await promise
            let sessionId = res.data.id
            await stripe.redirectToCheckout({
                sessionId,
            })
        })
    }

    render() {
        return (
            <div>
                <button disabled={this.props.disabled} style={{ background: "green", width: "200px", margin: "20px", margin: "auto" }} role='link' onClick={this.handleClick} className="button">
                    {!this.state.loading ? <div>Buy Now</div> : <Spinner animation="border" vaiant="secondary" style={{ width: '20px', height: '20px', fontSize: '20px' }} />}
                </button>

            </div>
        );
    }
}

export default StripeContainerRecurring;