import React, { Component } from 'react';
import {Button, Row,Card} from 'react-bootstrap'
import AddPlanCard from '../components/myHosting/addPlanCard'
import LoadingPlanCard from '../components/myHosting/loadingPlanCard'
import PlanCard from '../components/myHosting/planCard'
import { connect } from 'react-redux';
import {setSubscriptionsData, startSetSubscriptionsData} from '../actions/subscriptionsActions'

class MyHostingList extends Component {
    state = { 
        subscriptions: [],
        paymentSuccess: false,
        paymentCancell: false,
        closeEnter: ''

     }
    componentDidMount=async()=>{
        this.paymentSuccessOrFail()
        this.props.startSetSubscriptionsData()
        await this.props.setSubscriptionsData(this.props.user.email)
    }
    refreshPlans =async () =>{
        this.props.startSetSubscriptionsData()
        await this.props.setSubscriptionsData(this.props.user.email)
    }
    paymentSuccessOrFail = ()=>{
        const urlParams = new URLSearchParams(window.location.search);
        const price = urlParams.get('price');
        const payment = urlParams.get('payment');
        if(payment){
            if(payment === 'true'){
                window.gtag_report_conversion_purchased(price)
                this.setState({paymentSuccess: true})
            }
            else{
                this.setState({paymentCancell: true})
            }
        }
    }
    closePaymentSuccess=()=>{
        this.setState({paymentSuccess: false})
    }
    closeEnter=()=>{
        this.setState({closeEnter:'true'})
    }
    closeLeave=()=>{
        this.setState({closeEnter:''})
    }
    closePaymentCancel=()=>{
        this.setState({paymentCancell: false})
    }
    closeEnter=()=>{
        this.setState({closeEnter:'true'})
    }
    closeLeave=()=>{
        this.setState({closeEnter:''})
    }
    render() { 
        let plans = []
        
        this.props.subscriptions.subscriptions.forEach((plan, index)=>{
            plan && plan.plan && plans.push(<PlanCard key={"plan"+index} plan={plan} planIndex={index}/>)
        })
        let darkmode = this.props.user.darkmode
        return (
        <div style={{marginTop: '60px', marginBottom: '150px', width: '98%', margin: 'auto'}}>
            {this.state.paymentSuccess &&
            <Row style={{margin:'20px'}}>
                <Card style={{width: '100%', maxWidth: '1000px', margin: 'auto', backgroundColor: 'rgb(75,181,67)'}}>
                <div style={{position: 'absolute', top: '25%',left: '96%', transform: 'translate(-50%, -50%)'}}>
                    <div style={{width: '30px', height:'30px', backgroundColor:this.state.closeEnter?'rgba(0,0,0,0.2)':'',  textAlign: 'center'}} onClick={this.closePaymentSuccess} onMouseEnter={()=>{this.closeEnter()}} onMouseLeave={()=>this.closeLeave()}><p style={{color: 'black'}}>x</p></div>
                </div>
                <h3 style={{textAlign: 'center'}}>Payment Success.</h3>
                <h5 style={{textAlign: 'center'}}>Your new subscription should be shown below. Click refresh if subscription not shown.</h5>
                </Card>
            </Row>}
            {this.state.paymentCancell &&
            <Row style={{margin:'20px'}}>
                <Card style={{width: '100%', maxWidth: '1000px', margin: 'auto', backgroundColor: 'rgb(255,69,0)'}}>
                <div style={{position: 'absolute', top: '25%',left: '96%', transform: 'translate(-50%, -50%)'}}>
                    <div style={{width: '30px', height:'30px', backgroundColor:this.state.closeEnter?'rgba(0,0,0,0.2)':'',  textAlign: 'center'}} onClick={this.closePaymentCancel} onMouseEnter={()=>{this.closeEnter()}} onMouseLeave={()=>this.closeLeave()}><p style={{color: 'black'}}>x</p></div>
                </div>
                <h3 style={{textAlign: 'center'}}>Payment Cancelled.</h3>
                <h5 style={{textAlign: 'center'}}>Please try again.</h5>
                </Card>
            </Row>}

            <Row style={{marginTop:'20px'}}>
                <Button onClick={()=>{this.refreshPlans()}} style={{backgroundColor: '#343a40', margin: 'auto', width: '400px'}}>Refresh</Button>
            </Row>
            <Row style={{paddingTop:'20px', marginBottom:'20px',margin: "auto", textAlign: "center"}}>
                <p style={{ margin: "auto", textAlign: "center", color: "white"}}>{this.props.subscriptions.subscriptions.length} Subscriptions </p>
            </Row>
            <Row style={{width: `${(1 + this.props.subscriptions.subscriptions.length) * 450}px`, maxWidth: "100%", margin: 'auto'}}>
                <AddPlanCard />
                {this.props.subscriptions.retrieved && plans}
                {!this.props.subscriptions.retrieved && <LoadingPlanCard/>}
            </Row>
        </div>
         );
    }
}
function mapStateToProps(state){
    return{
        subscriptions: state.subscriptions,
        user: state.user,
    }
}
function mapDispatchToProps(dispatch){
    return{
        startSetSubscriptionsData: ()=>{dispatch(startSetSubscriptionsData())},
        setSubscriptionsData: (data)=>{dispatch(setSubscriptionsData(data))}
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(MyHostingList);
