import React, { Component } from 'react';
import {Button} from 'react-bootstrap'
import Axios from 'axios';
import { connect } from 'react-redux';
import {Redirect} from 'react-router-dom'
import Modal from "../modal"

class CancelPlan extends Component {
    state = { 
        deleteRedirect: false
     }
    confirmDeletePlan=()=>{
        let subscriptionId = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionId
        let subscriptionItem = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem

        Axios.post('/api/subscriptions/delete/byuser',{subId: subscriptionId, subItem: subscriptionItem}).then((res)=>{
            this.setState({deleteRedirect: true})
        })
    }
  
    render() { 
        let title = "Delete Plan"
        let content=<div>
                        <p>Are you sure you want to delete this plan?</p>
                        <p>Your subscription will be cancelled.</p>
                        <p>Your subscription will be deleted.</p>
                        <p>All data associated to your plan (website, database, etc...) will be irreversibly deleted.</p>
                        <p>This can not be undone.</p>
                        <Button style={{float: 'right', background:'red', borderColor: 'red'}} onClick={this.confirmDeletePlan}>Delete Plan</Button>    

        </div>
        if(this.state.deleteRedirect){
             return(<Redirect to="/my-hosting"/>)
        }

        return ( 

                            <Modal  show={this.props.show} title={<h1>{title}</h1>} content={content} close={this.props.closeDeletePlanModal} />

         );
    }
}
 
function mapStateToProps(state){
    return{
        subscriptions: state.subscriptions,
    }
}
function mapDispatchToProps(dispatch){
    return{
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CancelPlan);
