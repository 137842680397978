import React, { Component } from 'react';
import {Card, Row, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';

class Admin extends Component {
    state = {  }
    render() { 
        let darkmode = this.props.user.darkmode
        return ( 
            <div style={{width: '100%', marginTop: '60px', marginBottom: '100px'}}>
            <Card style={{width: '80%',margin: 'auto', maxWidth:'1000px'}}>
                <Card.Header className={darkmode? "darkmode-card-header" :"lightmode-card-header"} style={{textAlign: 'center'}}>
                    <h1>Admin</h1>
                </Card.Header>
                <div className={darkmode? "darkmode-card-content" :"lightmode-card-content"} style={{paddingTop: "10px"}}>
                    <Row style={{paddingLeft: '40px',paddingRight: '40px',paddingBottom:'10px'}}>
                            <Button style={{width: '100%'}}  type='submit'  as={Link} to='/admin/stats'>Stats</Button>
                    </Row>
                    <Row style={{paddingLeft: '40px',paddingRight: '40px',paddingBottom:'10px'}}>
                            <Button style={{width: '100%'}}  type='submit'  as={Link} to='/admin/email'>Email</Button>
                    </Row>
                    <Row style={{paddingLeft: '40px',paddingRight: '40px',paddingBottom:'10px'}}>
                            <Button style={{width: '100%'}}  type='submit'  as={Link} to='/admin/stripe'>Stripe</Button>
                    </Row>
                    <Row style={{paddingLeft: '40px',paddingRight: '40px',paddingBottom:'10px'}}>
                            <Button style={{width: '100%'}}  type='submit'  as={Link} to='/admin/create/user'>Create User</Button>
                    </Row>
                    <Row style={{paddingLeft: '40px',paddingRight: '40px',paddingBottom:'10px'}}>
                            <Button style={{width: '100%'}}  type='submit'  as={Link} to='/admin/delete/ip'>Delete IPs</Button>
                    </Row>
                    <Row style={{paddingLeft: '40px',paddingRight: '40px',paddingBottom:'10px'}}>
                            <Button style={{width: '100%'}}  type='submit'  as={Link} to='/admin/faq'>FAQ</Button>
                    </Row>
                    <Row style={{paddingLeft: '40px',paddingRight: '40px',paddingBottom:'10px'}}>
                            <Button style={{width: '100%'}}  type='submit'  as={Link} to='/admin/disk'>Disk Space</Button>
                    </Row>
                    <Row style={{paddingLeft: '40px',paddingRight: '40px',paddingBottom:'10px'}}>
                            <Button style={{width: '100%'}}  type='submit'  as={Link} to='/admin/backups'>Backups</Button>
                    </Row>
                    <Row style={{paddingLeft: '40px',paddingRight: '40px',paddingBottom:'10px'}}>
                            <Button style={{width: '100%'}}  type='submit'  as={Link} to='/admin/domains'>Domains</Button>
                    </Row>
                    <Row style={{paddingLeft: '40px',paddingRight: '40px',paddingBottom:'10px'}}>
                            <Button style={{width: '100%'}}  type='submit'  as={Link} to='/admin/offers'>Offers</Button>
                    </Row>
                    <Row style={{paddingLeft: '40px',paddingRight: '40px',paddingBottom:'10px'}}>
                            <Button style={{width: '100%'}}  type='submit'  as={Link} to='/admin/logs'>Logs</Button>
                    </Row>
                    </div>
            </Card>                    
        </div>
        );
    }
}
 
function mapStateToProps(state) {
        return {
            user: state.user,
        }
    }
    function mapDispatchToProps(dispatch) {
        return {}
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(Admin);
    
    