import * as types from './actionsTypes'
export function setUser(data){
    return {
        type: types.SET_USER,
        payload: data
    }
}
export function logoutUser(){
    return {
        type: types.LOGOUT_USER
    }
}
export function setMarketingPreferences(data){
    return {
        payload: data,
        type: types.SET_MARKETING_PREFERENCES
    }
}