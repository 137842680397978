import React, { Component } from 'react';
class AdminUserView extends Component {
    state = {}
    render() {
        // Convert hexadecimal string to integer
        const timestamp = parseInt(this.props.user._id.substring(0, 8), 16) * 1000;
        // Convert Unix timestamp to Date object
        const date = new Date(timestamp);


        return (

            <tr onClick={this.props.openUserDetails} key={this.props.user._id}>
                <td>{this.props.user.email}</td>
                <td>{this.props.user.marketingPreferences.email.toString()}</td>
                <td>{this.props.user.marketingPreferences.text.toString()}</td>
                <td>{this.props.user.marketingPreferences.phone.toString()}</td>
                <td>{this.props.user.admin.toString()}</td>
                <td>{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} @ ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`}</td>
            </tr>
        );
    }
}

export default AdminUserView;