import React, { Component } from 'react';
import {Card, Table, Form, Button,Row,Col} from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import { connect } from 'react-redux';

class AdminEmail extends Component {
    state={
        password: '',
        email:'',
        marketingPreferences: {email: false, phone: false, text: false},

    }
    componentDidMount(){
        this.genPassword()
    }
    genPassword=()=>{
        var randomstring = Math.random().toString(36).slice(-10);
        this.setState({password: randomstring})
    }
    updateValue=(e)=>{
        this.setState({email: e.target.value})
    }
    createUser=()=>{
        axios.post('/api/user/admin/create/user',{email: this.state.email, password: this.state.password,marketingPreferences: this.state.marketingPreferences })
    }
    handleMarketing=(type)=>{
        let marketingPreferences = this.state.marketingPreferences
        marketingPreferences[type] = !marketingPreferences[type]
        this.setState({marketingPreferences: marketingPreferences})
    }
    render() {  
        let darkmode = this.props.user.darkmode
       
        return ( 
            <div style={{marginTop: '60px', marginBottom: '150px'}}>
                <Card style={{ margin: '30px'}}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{textAlign: 'center'}}>
                        Create User
                    </Card.Header>
                    <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{maxWidth: '100%'}} >
                        <Row>
                            <Col>Email</Col>
                            <Col>                                    
                                <Form.Control as='textarea' placeholder='email' value={this.state.email} onChange={(e)=>this.updateValue(e)} />
                            </Col>
                        </Row>   
                        <Row>
                            <Col>Password</Col>
                            <Col>                                    
                                <Form.Control as='textarea' placeholder='password' value={this.state.password} />
                            </Col>
                        </Row>   
                        <Row style={{padding: '20px'}}>
                        <Col style={{textAlign: 'center'}} xs={4}>
                            <p>Email</p>
                            <Form.Control style={{minWidth: '20px'}} checked={this.state.marketingPreferences.email} onClick={()=>this.handleMarketing('email')} type="checkbox"/>
                        </Col>
                        <Col style={{textAlign: 'center'}} xs={4}>
                            <p>Phone</p>
                            <Form.Control style={{minWidth: '20px'}} checked={this.state.marketingPreferences.phone} onClick={()=>this.handleMarketing('phone')} type="checkbox"/>
                        </Col>
                        <Col style={{textAlign: 'center'}} xs={4}>
                            <p>Text</p>
                            <Form.Control style={{minWidth: '20px'}} checked={this.state.marketingPreferences.text} onClick={()=>this.handleMarketing('text')} type="checkbox"/>
                        </Col>
                        
                    </Row>              
                    </div>
                </Card>
        
                
                <Button style={{backgroundColor: '#343a40', width: '100%'}} onClick={this.createUser}>create</Button>

            </div>
         );
    }
}
 
function mapStateToProps(state) {
    return {
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminEmail);





