import React, { Component } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux';
import Axios from 'axios';

class Htpasswd extends Component {
    state = {
        enabled: false,
        user: "",
        password: ""
    }
    componentDidMount() {
        Axios.post('/api/sites/get/htpasswd', { subscriptionItem: this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem }).then(res => {
            this.setState({ enabled: res.data.enabled, user: res.data.user ? res.data.user : "" })
        })
    }
    changeEnabled = (enabled, subscriptionItem) => {
        Axios.post('/api/sites/set/htpasswd', { subscriptionItem: subscriptionItem, enabled: enabled, password: this.state.password, user: this.state.user }).then(res => {
            this.setState({ enabled: enabled })
        })
    }
    updateUser = (newuser) =>{
        this.setState({user: newuser})
    }
    updatePassword = (newpassword) =>{
        this.setState({password: newpassword})
    }
    render() {
        let darkmode = this.props.user.darkmode
        let users = []
        let enabled = this.state.enabled
        let subscriptionItem = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
        return (
            <div>
                <Card style={{ width: '80%', margin: 'auto', display: 'block', marginTop: '60px' }}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                        Manage Site Lock: (htpasswd)
                    </Card.Header>
                    <Card.Text className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ textAlign: 'center' }}>
                        <Row><Col>Lock: {this.state.enabled ? "Enabled" : "Disabled"} </Col></Row>
                        {this.state.enabled && <Row><Col>User: {this.state.user}</Col></Row>}
                        {!this.state.enabled && <Row><Col>
                            <input style={{ width: '700px', maxWidth: "60%", margin: "20px" }} type='text' value={this.state.user} onChange={(e) => { this.updateUser(e.target.value) }} placeholder='User' />
                        </Col></Row>}
                        {!this.state.enabled && <Row><Col>
                            <input style={{ width: '700px', maxWidth: "60%", margin: "20px" }} type='text' value={this.state.password} onChange={(e) => { this.updatePassword(e.target.value) }} placeholder='Password' />
                        </Col></Row>}
                        <Row><Col>
                            {enabled ?
                                <Button className='button-warning' style={{ marginBottom: '20px', width: "200px" }} onClick={() => this.changeEnabled(false, subscriptionItem)}>Disable Site Lock</Button>
                                :
                                <Button disabled={this.state.user.length < 4 || this.state.password.length < 4} style={{ marginBottom: '20px', width: "200px" }} onClick={() => this.changeEnabled(true, subscriptionItem)}>Enable Site Lock</Button>

                            }
                        </Col></Row>
                        {this.state.user.length < 4 &&<Row style={{color: "red"}}><Col>
                            User must be contain at least 4 characters.
                        </Col></Row> }
                        {this.state.password.length < 4 &&<Row style={{color: "red"}}><Col>
                            Password must be contain at least 4 characters.
                        </Col></Row> }
                        {users}
                    </Card.Text>
                </Card>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setHtPasswdEnabled: (data) => { dispatch(setHtPasswdEnabled(data)) }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Htpasswd);

