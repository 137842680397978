import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import NotFound404 from './pages/notFound404';
import Navbar from './components/navbar';
import CheckoutFooter from './components/checkoutFooter';

import Login from './pages/login';
import Axios from 'axios'
import { connect } from 'react-redux';
import { setUser } from './actions/userActions'
import { setBasket } from './actions/basketActions'

import ProtectedRoute from './components/routes/protectedRoute'
import PublicRoute from './components/routes/publicRoute'
import CreateAccount from './pages/createAccount'
import Account from './pages/account'
import Plans from './pages/plans'
import Basket from './pages/basket'

import AdminRoute from './components/routes/adminRoute'
import Admin from './pages/admin'
import AdminEmail from './pages/adminEmail'
import AdminStats from './pages/adminStats'
import AdminStripe from './pages/adminStripe'
import AdminDeleteIp from './pages/adminDeleteIp'
import AdminCreateUser from './pages/adminCreateUser'
import AdminFaq from './pages/adminFaq'
import AdminFaqEdit from './pages/adminFaqEdit'
import AdminDiskSpace from './pages/adminDiskSpace'
import AdminBackups from './pages/adminBackups'
import AdminDomains from './pages/adminDomains'
import AdminOffers from './pages/adminOffers'
import AdminLogs from './pages/adminLogs'


import MyHosting from './pages/myHosting'
import Domains from './pages/domains'
import Footer from './components/footer';
import Help from './pages/help'
import ForgotPassword from './pages/forgotPassword'
import ResetPassword from './pages/resetPassword'
import InfoFooter from './components/infoFooter';
import "./styles.css"

class App extends Component {
  state = { maintainance: false }
  componentDidMount() {
    Axios.defaults.headers.common['x-auth-token'] = localStorage.jwtToken
    Axios.get('/api/user/user').then(res => {
      if (res.data.success) {
        this.props.setUser({ user: res.data.user, token: localStorage.jwtToken })
      }
    })
    this.props.setBasket()
  }

  render() {
    if (this.state.maintainance) {
      if (this.props.user && this.props.user.email !== 'thomas.breeze@icloud.com') {
        return <h1 style={{ margin: "100px" }}>This service is down for scheduled maintainance. Please check back soon.</h1>
      }
    }
    return (
      <div style={{background: "rgb(59, 74, 88)", minHeight: "100vh"}}>
        <Router>
          <Navbar />
          <CheckoutFooter/>
          {/* <Footer /> */}
          <div style={{ paddingTop: '100px', paddingBottom: "120px" }}>
            <Switch>
          //pages accessible for non logged in users#
              <PublicRoute exact={true} path="/"><Redirect to='/login' /></PublicRoute>
              <PublicRoute path="/login">
                <div>
                  <Login />
                  <hr style={{ width: "90%" }} />
                  <InfoFooter />
                </div>
              </PublicRoute>
              <PublicRoute path="/create-account">
                <div>
                  <CreateAccount />
                  <hr style={{ width: "90%" }} />
                  <InfoFooter />
                </div>
              </PublicRoute>
              <PublicRoute path="/forgot-password">
                <div>
                  <ForgotPassword />
                  <hr style={{ width: "90%" }} />
                  <InfoFooter />
                </div>
              </PublicRoute>
              <PublicRoute path="/reset-password">
                <div>
                  <ResetPassword />
                  <hr style={{ width: "90%" }} />
                  <InfoFooter />
                </div>
              </PublicRoute>
              <Route path="/help">
                <div>
                  <Help />
                  <hr style={{ width: "90%" }} />
                  <InfoFooter />
                </div>
              </Route>

              <ProtectedRoute path="/account"> <Account /> </ProtectedRoute>
              <ProtectedRoute path="/my-hosting"> <MyHosting /> </ProtectedRoute>

              <ProtectedRoute path="/domains"> <Domains /> </ProtectedRoute>

              <ProtectedRoute path="/plans"> <Plans /> </ProtectedRoute>

              <ProtectedRoute exact={true} path="/basket"> <Basket /> </ProtectedRoute>

              <AdminRoute exact={true} path='/admin'><Admin /></AdminRoute>
              <AdminRoute exact={true} path='/admin/email'><AdminEmail /></AdminRoute>
              <AdminRoute exact={true} path='/admin/stats'><AdminStats /></AdminRoute>
              <AdminRoute exact={true} path='/admin/create/user'><AdminCreateUser /></AdminRoute>
              <AdminRoute exact={true} path='/admin/delete/ip'><AdminDeleteIp /></AdminRoute>
              <AdminRoute exact={true} path='/admin/faq'><AdminFaq /></AdminRoute>
              <AdminRoute exact={true} path='/admin/faq/edit'><AdminFaqEdit /></AdminRoute>
              <AdminRoute exact={true} path='/admin/disk'><AdminDiskSpace /></AdminRoute>
              <AdminRoute exact={true} path='/admin/backups'><AdminBackups /></AdminRoute>
              <AdminRoute exact={true} path='/admin/domains'><AdminDomains /></AdminRoute>
              <AdminRoute exact={true} path='/admin/offers'><AdminOffers /></AdminRoute>
              <AdminRoute exact={true} path='/admin/logs'><AdminLogs /></AdminRoute>

              {this.props.user && this.props.user.email === 'thomas.breeze@icloud.com' && <AdminRoute exact={true} path='/admin/stripe'><AdminStripe /></AdminRoute>}


              <Route><NotFound404 /></Route>
            </Switch>

          </div>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}
function mapDispatchToProps(dispatch) {
  return {
    setUser: (data) => { dispatch(setUser(data)) },
    setBasket: (data) => { dispatch(setBasket(data)) }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);