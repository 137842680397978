import React, { Component } from 'react';
import { Card, Row, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

class AccountMenu extends Component {
    state = {}
    render() {
        let darkmode = this.props.user.darkmode

        return (
            <div style={{ width: '100%', marginTop: '60px', marginBottom: '100px' }}>
                <Card style={{ width: '80%', margin: 'auto', maxWidth: '1000px' }}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ paddingBottom: '10px', textAlign: 'center' }}>
                        <h1>Manage your account</h1>
                    </Card.Header>
                    <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{paddingTop: "10px"}}>

                        <Row style={{ paddingLeft: '40px', paddingRight: '40px', paddingBottom: '10px' }}>
                            <Button style={{ width: '100%', backgroundColor: '#343a40' }} type='submit' as={Link} to='/account/details'> Account Details</Button>
                        </Row>
                        <Row style={{ paddingLeft: '40px', paddingRight: '40px', paddingBottom: '10px' }}>
                            <Button style={{ width: '100%', backgroundColor: '#343a40' }} type='submit' as={Link} to='/account/privacy'> Privacy and Marketing</Button>
                        </Row>
                        <Row style={{ paddingLeft: '40px', paddingRight: '40px', paddingBottom: '10px' }}>
                            <Button style={{ width: '100%', backgroundColor: '#343a40' }} type='submit' as={Link} to='/account/data'>Account Data</Button>
                        </Row>
                        <Row style={{ paddingLeft: '40px', paddingRight: '40px', paddingBottom: '10px' }}>
                            <Button style={{ width: '100%', backgroundColor: '#343a40' }} type='submit' as={Link} to='/account/user-interface'>User Interface</Button>
                        </Row>
                    </div>

                </Card>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
