import React, { Component } from 'react';
import axios from "axios"
import { Button, Form, Row, Col, Card } from 'react-bootstrap'
import { connect } from 'react-redux';

class AdminDiskSpace extends Component {
    state = {
        increase: 0,
        servers: [],
        instanceToIncrease: ""

    }
    componentDidMount() {
        this.getDisksByServer()
    }
    getDisksByServer = () => {
        axios.get("/api/disk/get/servers").then(res => {
            this.setState({ servers: res.data.data })
        })
    }
    increaseSize = (instanceId, ip, current, filesystem, disk) => {
        this.setState({ instanceToIncrease: "" })
        current = current.replace("G", "")
        axios.post("/api/disk/increase/size", { increase: parseInt(this.state.increase), current: parseInt(current), instanceId: instanceId, ip: ip, filesystem: filesystem, disk: disk }).then(res => {
            this.getDisksByServer()
        })
    }
    updateIncreaseInput = (e, instanceId) => {
        let instanceToIncrease = ""
        if (e.target.value && parseInt(e.target.value)) {
            instanceToIncrease = instanceId
        }
        this.setState({ increase: e.target.value, instanceToIncrease: instanceToIncrease })
    }
    render() {
        let darkmode = this.props.user.darkmode

        let serversToDisplay = []
        this.state.servers.forEach(server => {
            if (server.diskSpace) {
                serversToDisplay.push(
                    <Card style={{ margin: '30px' }}>
                        <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                            {server.instanceId}
                        </Card.Header>
                        <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ maxWidth: '100%' }} >
                            <Row>
                                <Col>
                                    Type
                                </Col>
                                <Col>
                                    <Form.Control as='input' value={server.serverType} disabled />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    filesystem
                                </Col>
                                <Col>
                                    <Form.Control as='input' value={server.filesystem} disabled />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    disk
                                </Col>
                                <Col>
                                    <Form.Control as='input' value={server.disk} disabled />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Disk
                                </Col>
                                <Col>
                                    <Form.Control as='input' value={server.diskSpace[0]} disabled />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Size
                                </Col>
                                <Col>
                                    <Form.Control as='input' value={server.diskSpace[1]} disabled />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Used
                                </Col>
                                <Col>
                                    <Form.Control as='input' value={server.diskSpace[2]} disabled />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Available
                                </Col>
                                <Col>
                                    <Form.Control as='input' value={server.diskSpace[3]} disabled />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Percentage Used
                                </Col>
                                <Col>
                                    <Form.Control as='input' value={server.diskSpace[4]} disabled />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Notes
                                </Col>
                                <Col>
                                    <Form.Control as='input' value={server.notes} disabled />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Static Ip
                                </Col>
                                <Col>
                                    <Form.Control as='input' value={server.staticIp} disabled />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    Size Increase (GB)
                                </Col>
                                <Col>
                                    <Form.Control as='textarea' value={this.state.instanceToIncrease == server.instanceId ? this.state.increase : ""} onChange={(e) => this.updateIncreaseInput(e, server.instanceId)} />
                                </Col>
                            </Row>
                            <Row><Col> <Button disabled={this.state.instanceToIncrease == server.instanceId ? false : true} style={{ width: "100%", margin: "10px", background: "red" }} onClick={() => this.increaseSize(server.instanceId, server.staticIp, server.diskSpace[1], server.filesystem, server.disk)}>Increase Size</Button></Col></Row>
                        </div>
                        <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}  >
                        </Card.Footer>
                    </Card>
                )
            }
        })
        return (<div>
            {serversToDisplay}
        </div>);
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDiskSpace);






