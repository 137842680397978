import React, { Component } from 'react';
import { Button, Modal, DropdownButton, FormControl, Row, Col, DropdownItem, InputGroup } from 'react-bootstrap'
import Axios from 'axios';
import { connect } from 'react-redux';
import isValidDomain from "is-valid-domain";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
class SetDomainName extends Component {
    state = {
        errormsg: null,
        domain: null,
        addEnabled: false,
        type: 'own_internal',
        BLDomain: '',
        internalDomainsOwned: [],
    }
    componentDidMount(){
        this.typeClicked("own_internal")
    }
    domainChanged = (e) => {
        let domainValid = isValidDomain(e.target.value, { subdomain: false })
        let subre = /^[a-zA-Z0-9][a-zA-Z0-9.-]*[a-zA-Z0-9]$/
        let subdomainValid = subre.exec(e.target.value)
        let addDomainEnabled = (e.target.value && this.state.type === 'blsubdomain' && this.state.BLDomain && subdomainValid) || (e.target.value && this.state.type === 'own_external' && domainValid)
        let error = null
        if (this.state.type == "own_external" && !domainValid) {
            error = "Domain is not valid"
        }
        if (this.state.type == "blsubdomain" && !subdomainValid) {
            error = "Subdomain is not valid"
        }
        this.setState({ domain: e.target.value, addEnabled: addDomainEnabled, errormsg: error })
    }
    setUpDomainName = () => {
        let subid = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
        this.setState({ addEnabled: false })
        Axios.post('/api/sites/create-url', { domain: this.state.domain, subId: subid, domainType: this.state.type, BLDomain: this.state.BLDomain }).then(res => {
            if (res.data.success) {
                this.setState({ showWordPressInstall: false, installEnabled: true })
                this.props.closeCancelModal()
            } else {
                this.setState({ errormsg: res.data.message })
            }
        })
    }
    typeClicked = (type) => {
        this.setState({ type: type, domain: '', BLDomain: '' })
        if (type == "own_internal") {
            Axios.get('/api/domains/get/all/by/customer').then(res => {
                this.setState({ internalDomainsOwned: res.data.domains })
            })
        }
    }
    setBlDomain = (BlDomain) => {
        let addDomainEnabled = (this.state.domain && this.state.type === 'blsubdomain' && BlDomain)
        this.setState({ BLDomain: BlDomain, addEnabled: addDomainEnabled })
    }
    changeInternalDomainSelection = (domain) => {
        this.setState({ domain: domain, addEnabled: true })
    }
    render() {
        let darkmode = this.props.user.darkmode

        let internalDomains = []
        if (this.state.type == "own_internal" && this.state.internalDomainsOwned) {
            this.state.internalDomainsOwned.forEach(domain => {
                internalDomains.push(
                    <DropdownItem onClick={() => this.changeInternalDomainSelection(domain.domain)}>{domain.domain}</DropdownItem>

                )
            })
        }
        return (
            <Modal style={{ width: '90%' }} onHide={this.props.closeCancelModal} show={this.props.show}>
                <Modal.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                    <Modal.Title >
                        Set A Domain Name
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{marginTop: "-1px"}} >

                    <Row>
                        <Col style={{ maxWidth: "25px" }}>
                            <FormControl onClick={() => this.typeClicked('own_internal')} checked={this.state.type === 'own_internal'} type="radio" style={{ width: '20px' }} />
                        </Col>
                        <Col>
                            <p style={{ padding: '8px 0' }}>Use your own domain name.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ maxWidth: "25px" }}>
                            <FormControl onClick={() => this.typeClicked('blsubdomain')} checked={this.state.type === 'blsubdomain'} type="radio" style={{ width: '20px' }} />
                        </Col>
                        <Col>
                            <p style={{ padding: '8px 0' }}>Create a free breezelabshosting domain name.</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{ maxWidth: "25px" }}>
                            <FormControl onClick={() => this.typeClicked('own_external')} checked={this.state.type === 'own_external'} type="radio" style={{ width: '20px' }} />
                        </Col>
                        <Col>
                            <p style={{ padding: '8px 0' }}>Use your own externally registered domain name.</p>
                        </Col>
                    </Row>



                    {this.state.type === 'own_external' && <div>
                        <h4>Use your own externally registered domain name.</h4>
                        <p>Please enter your domain name below:</p>
                        <FormControl onChange={this.domainChanged} placeholder='Domain e.g. breezelabs.co.uk'></FormControl>
                        {this.state.errormsg && <p>{this.state.errormsg}</p>}
                    </div>}
                    {this.state.type === 'own_internal' && <div>
                        <h4>Use your own domain name.</h4>
                        <p>Please selct the domain name below:</p>
                        <DropdownButton align="start" style={{ width: "100%" }} title={this.state.domain ? this.state.domain : "Select a domain"} variant="primary">
                            {internalDomains}
                        </DropdownButton>
                        <Link to="/domains">Buy a domain now</Link>
                        {this.state.errormsg && <p>{this.state.errormsg}</p>}
                    </div>}
                    {this.state.type === 'blsubdomain' && <div>
                        <h4 >Create your free breezelabshosting domain name.</h4>
                        <p>Please enter your desired domain name below:</p>
                        <InputGroup className="mb-3">

                            <FormControl onChange={this.domainChanged} placeholder='e.g. amazingblog'></FormControl>


                            <DropdownButton align="end" title={this.state.BLDomain ? this.state.BLDomain : "Select a domain"} variant="primary">
                                <DropdownItem onClick={() => { this.setBlDomain('.blhosting.uk') }}>.blhosting.co.uk</DropdownItem>
                                <DropdownItem onClick={() => { this.setBlDomain('.breezelabshosting.co.uk') }}>.breezelabshosting.co.uk</DropdownItem>
                                {this.props.user.admin === "true" && <DropdownItem onClick={() => { this.setBlDomain('.breezelabs.co.uk') }}>.breezelabs.co.uk</DropdownItem>}
                            </DropdownButton>
                        </InputGroup>
                        <p>Your domain: {this.state.domain + this.state.BLDomain}</p>
                        {this.state.errormsg && <p>{this.state.errormsg}</p>}
                    </div>}
                </Modal.Body>
                <Modal.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                    <div style={{ width: '100%' }}>
                        <Button style={{ float: 'right', background: 'green', borderColor: 'green' }} onClick={this.setUpDomainName} disabled={!this.state.addEnabled}>Add Domain Name</Button>
                        <Button style={{ float: 'left', backgroundColor: '#343a40' }} onClick={this.props.closeCancelModal}>Cancel</Button>
                    </div>

                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetDomainName);
