import React, { Component } from 'react';
import {loadStripe} from '@stripe/stripe-js'
import axios from 'axios'
import {Button, Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class StripeContainerBasket extends Component {
    state = { 
        session: null,
        key: '',
        showPurchasePolicy: false,
        loading: false
     }
     componentDidMount = () => {
         axios.get('/api/payment/get/key').then(res=>{
            this.setState({key: res.data.key})
         })
     }
    
    handleClick =  async (selectedId) => {
        this.setState({loading: true})
        const promise = loadStripe(this.state.key)
        axios.post('/api/payment/create-payment-intent/basket', {contents: this.props.basket}).then(async res=>{
            const stripe = await promise
            let sessionId = res.data.id
            await stripe.redirectToCheckout({
                sessionId,
            })
        })
    }

    render() { 
        return ( 
            <div style={{margin: "auto", textAlign: "center"}}>
               <button disabled={this.props.disabled} style={{ background: "green", width: "200px", margin: "20px", textAlign: "center", margin: "auto" }} role='link' onClick={this.handleClick} className="button">
               {!this.state.loading ? <div>Buy Now</div> : <Spinner animation="border" vaiant="secondary" style={{ width: '20px', height: '20px', fontSize: '20px' }} />}
                </button>
 
            </div>
         );
    }
}
 
function mapStateToProps(state) {
    return {
        basket: state.basket
    }
}
function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StripeContainerBasket);






