import React, { Component } from 'react';
import {Card, Table, Form, Button,Row,Col} from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import chart from 'chart.js'
import EmailUser from '../components/admin/emailUser'
import EmailEveryone from '../components/admin/emailEveryone'
import EmailNewsletter from '../components/admin/emailNewsletter'
import { connect } from 'react-redux';

class AdminDeleteIp extends Component {
  state={
    deleteIPEnabled: false,
      ip: ""

  }

  updateValue=(e,type)=>{
    this.setState({[type]:e.target.value},()=>{
        if(this.state.ip){
            this.setState({deleteIPEnabled: true})
        }else{
            this.setState({deleteIPEnabled: false})
        }
    })
  }
  deleteIP=()=>{

    axios.post('/api/stats/delete/ids/by/id',{ip:this.state.ip}).then(()=>{
        this.setState({deleteIPEnabled: false})
    })
  }

    render() {    
        let darkmode = this.props.user.darkmode
     
        return ( 
            <div style={{marginTop: '60px', marginBottom: '150px'}}>
                <Card style={{ margin: '30px'}}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{textAlign: 'center'}}>
                        Delete all records of IP Visits
                    </Card.Header>
                    <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{maxWidth: '100%'}} >
                        <Row>
                            <Col>IP Address</Col>
                            <Col>                                    
                                <Form.Control as='textarea' placeholder='IP address' onChange={(e)=>this.updateValue(e,'ip')} />
                            </Col>
                        </Row>                 
                    </div>
                </Card>
                <Button disabled={!this.state.deleteIPEnabled} style={{backgroundColor: '#343a40', width: '100%'}} onClick={this.deleteIP}>Update</Button>

            </div>
         );
    }
}
 
function mapStateToProps(state) {
    return {
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDeleteIp);





