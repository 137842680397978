
import React, { Component } from 'react';
import {Switch, Route, Redirect} from "react-router-dom"
import MyHostingList from './myHostingList';
import ManagePlan from './managePlan';
import ProtectedRoute from '../components/routes/protectedRoute';
import DNS from '../components/myHosting/dns/dns'
import WebsiteInstaller from '../components/myHosting/websiteInstaller/websiteInstaller'
import Backups from '../components/myHosting/backups/backups'
import Sidebar from '../components/sidebar';

class MyHosting extends Component {
    state = { 
        sidebarOpen: true
     }
     sidebarChanged = (state) =>{
        this.setState({sidebarOpen: state})
     }
    render() { 
        return ( 
            <div style={{marginBottom: '150px'}}>
                {/* <NavBar/> */}
                <Switch>
                    <ProtectedRoute exact path="/my-hosting"> <MyHostingList/> </ProtectedRoute>
                    <ProtectedRoute exact path="/my-hosting/manage-plan">          <div><Sidebar sidebarChanged={this.sidebarChanged} /><div style={{paddingLeft: this.state.sidebarOpen ? '150px': '50px'}}><ManagePlan /></div> </div></ProtectedRoute>
                    <ProtectedRoute exact path="/my-hosting/manage-plan/dns"> <div><Sidebar sidebarChanged={this.sidebarChanged}/><div style={{paddingLeft: this.state.sidebarOpen ? '150px': '50px'}}> <DNS/></div> </div ></ProtectedRoute>
                    <ProtectedRoute exact path="/my-hosting/manage-plan/website">  <div><Sidebar sidebarChanged={this.sidebarChanged}/><div style={{paddingLeft: this.state.sidebarOpen ? '150px': '50px'}}><WebsiteInstaller/> </div></div></ProtectedRoute>
                    <ProtectedRoute exact path="/my-hosting/manage-plan/backups"> <div><Sidebar sidebarChanged={this.sidebarChanged}/> <div style={{paddingLeft: this.state.sidebarOpen ? '150px': '50px'}}><Backups/></div> </div></ProtectedRoute>
                    <Route><Redirect to='/my-hosting'/></Route>
                </Switch>
                
    
            </div> 
        );
    }
}
 
export default MyHosting;