import React, { Component } from 'react';
import { Row, Button, Col } from 'react-bootstrap'
import { Link } from "react-router-dom"
import HostingCard from './hostingCard'
import { connect } from 'react-redux';
import { setSubscriptionsDetailedIndex } from '../../actions/subscriptionsActions'
import Axios from 'axios'
class PlanCard extends Component {
    state = {
        detailedOpen: false,
        manageHovered: false,
        plan: null,
        site: false,
        domain: null,
        type: null,
        nameServers: [],
        hovered: false

    }
    componentDidMount() {
        let subid = this.props.subscriptions.subscriptions[this.props.planIndex].subscriptionItem
        Axios.post('/api/sites/get-site', { subId: subid }).then(req => {
            if (req.data.site) {
                this.setState({ domain: req.data.domain, type: req.data.type, site: req.data.site })
            } else {
                this.setState({ site: req.data.site })
            }
        })
    }
    setSelected = () => {
        this.props.setSubscriptionsDetailedIndex(this.props.planIndex)
    }
    openDetails = () => {
        this.setState({ detailedOpen: true, hovered: true })
    }
    closeDetails = () => {
        this.setState({ detailedOpen: false, hovered: false })
    }
    manageHovered = () => {
        this.setState({ manageHovered: true })
    }
    manageHoveredLeft = () => {
        this.setState({ manageHovered: false })
    }
    render() {
        let darkmode = this.props.user.darkmode
        return (
            <HostingCard detailed={this.state.detailedOpen}>
                <Button className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} as={Link} to='/my-hosting/manage-plan' onClick={() => { this.setSelected() }} style={{ color: darkmode ? "white" : 'black', width: "100%", height: "100%", borderColor: "black", borderStyle: "solid", borderWidth: "2px", borderRadius: "10px" }} onMouseEnter={this.openDetails} onMouseLeave={this.closeDetails}>

                    <Row>
                        <Col >
                            <div style={{ margin: "20px" }}>
                                <Row>
                                    <p style={{ fontSize: '30px', marginLeft: '10px' }}>Plan: {this.props.plan.plan}</p>
                                </Row>
                                {this.state.domain && <Row>
                                    <p style={{ fontSize: '30px', marginLeft: '10px', maxWidth: '300px', textAlign: 'left' }}>Site: {this.state.domain.substring(0, 12)}...</p>
                                </Row>
                                }

                                <Row style={{ margin: "auto", textAlign: "center" }}>
                                    <Button style={{ fontSize: '20px', marginLeft: '10px', borderColor: 'white', width: "100%", margin: "auto", textAlign: "center" }} onMouseEnter={this.manageHovered} onMouseLeave={this.manageHoveredLeft}>Click To Manage Plan</Button>
                                </Row>
                                {this.state.detailedOpen &&
                                    <div style={{ textAlign: "left" }}>
                                        <p style={{ fontSize: '20px', marginTop: '10px' }}><li>Plan Status: {this.props.plan.status}</li></p>
                                        <p style={{ fontSize: '20px', }}><li>Next Payment: {this.props.plan.endDate}</li></p>
                                        {this.state.type && <p style={{ fontSize: '20px', }}><li>Website Type: {this.state.type}</li></p>}


                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </Button>

            </HostingCard>


        );
    }
}
function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setSubscriptionsDetailedIndex: (data) => { dispatch(setSubscriptionsDetailedIndex(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanCard);
