import * as types from './actionsTypes'
import axios from 'axios'

export const setSubscriptionsData = () => async dispatch => {
    const res = await axios.get(`/api/user/subscriptions`)
    dispatch( {
        type: types.SET_SUBSCRIPTIONS_DATA,
        payload: res.data.subscriptions
    })
}

export const setSubscriptionsDetailedIndex = (data) => async dispatch => {
    dispatch( {
        type: types.SET_SUBSCRIPTIONS_DETAILED_INDEX,
        payload: data
    })
}
export const setSubscriptionsCancelled = (data) => async dispatch => {
    dispatch( {
        type: types.SET_SUBSCRIPTIONS_CANCELLED,
        payload: data
    })
}

export const startSetSubscriptionsData = (data) => async dispatch => {
    dispatch( {
        type: types.START_SET_SUBSCRIPTIONS_DATA,
        payload: data
    })
}
export const setBackupConfiguration = (data) => async dispatch => {
    dispatch( {
        type: types.SET_BACKUP_CONFIGURATION,
        payload: data
    })
}




