import React, { Component } from 'react';
import { Spinner, DropdownButton, DropdownItem, Row, Col, Card, Form } from 'react-bootstrap'
import _ from 'lodash'
import RegistrationContact from "../../components/domain/registrationContact"
import { connect } from 'react-redux';

class RegistrationContacts extends Component {
    state = { 
        adminContactSame: true,
        techContactSame: true,
        registrationContact: {},
        adminContact: {},
        techContact: {},
        registrationContactPrivacyEnabled: true,
        techContactPrivacyEnabled: true,
        adminContactPrivacyEnabled: true,
        techContactValid: false,
        adminContactValid: false,
        registrationContactValid: false,
        allValid: false
     } 
     handleAdminContactClicked = () => {
        this.setState({ adminContactSame: !this.state.adminContactSame, adminContact: this.state.registrationContact, adminContactValid: !this.state.adminContactSame ? this.state.registrationContactValid : false, adminContactPrivacyEnabled: this.state.registrationContactPrivacyEnabled }, () => {
        })
    }
    handleTechContactClicked = () => {
        this.setState({ techContactSame: !this.state.techContactSame, techContact: this.state.registrationContact, techContactValid: !this.state.techContactSame ? this.state.registrationContactValid : false, techContactPrivacyEnabled: this.state.registrationContactPrivacyEnabled }, () => {
        })
    }

    contactsAndPrivacy = () => {
        let contactsAndPrivacy = {
            RegistrationContact: this.state.registrationContact,
            AdminContact: this.state.adminContact,
            TechContact: this.state.techContact,
            PrivacyProtectAdminContact: this.state.adminContactPrivacyEnabled,
            PrivacyProtectTechContact: this.state.techContactPrivacyEnabled,
            PrivacyProtectRegistrationContact: this.state.registrationContactPrivacyEnabled,
            TechContactSame: this.state.techContactSame,
            AdminContactSame: this.state.adminContactSame,
            techContactValid: this.state.techContactValid,
            registrationContactValid: this.state.registrationContactValid,
            adminContactValid: this.state.adminContactValid,

        }
        return contactsAndPrivacy
    }
    componentDidMount(){
        this.props.basket.forEach(item=>{


            if(item.active){

                this.setState({
                    techContactSame: item.contactsAndPrivacy.TechContactSame,
                    adminContactSame: item.contactsAndPrivacy.AdminContactSame,
                    techContactValid: item.techContactValid,
                    adminContactValid: item.adminContactValid,
                    registrationContactValid: item.registrationContactValid,


                })
            }
        })
        

    }

    
     contactUpdated = (type, values) => {
        let json = {
            FirstName: values.firstName,
            LastName: values.lastName,
            ContactType: values.contactType,
            OrganizationName: values.organisation,
            AddressLine1: values.addressLine1,
            AddressLine2: values.addressLine2,
            City: values.city,
            State: values.stateProvince,
            CountryCode: values.country,
            ZipCode: values.zipPostal,
            PhoneNumber: "+" + values.phoneCode + "." + values.phoneNumber,
            Email: values.email,
            //Fax
            //ExtraParams
        }
        let techstuff = {}
        let adminstuff = {}
        if (type == "registrationContact") {
            if (this.state.techContactSame) {
                techstuff = { "techContact": json, techContactPrivacyEnabled: values.privacyEnabled, techContactValid: values.valid }

            }
            if (this.state.adminContactSame) {
                adminstuff = { "adminContact": json, adminContactPrivacyEnabled: values.privacyEnabled, adminContactValid: values.valid }
            }
        }

        let privacyType = type + "PrivacyEnabled"
        let validType = type + "Valid"
        let setstatestuff = {
            ...techstuff
            ,
            ...adminstuff,
            [type]: json, [privacyType]: values.privacyEnabled, [validType]: values.valid
        }
        this.setState(setstatestuff)
    }
    render() { 
        return (
            <div>
                <Row>
                <Col>
                    <h3>Registration Contact</h3>
                </Col>
            </Row>
            <RegistrationContact type="registrationContact" contactUpdated={this.contactUpdated} />
            <hr />



            <Row>
                <Col>
                    Admin Contact Same As Registration Contact:
                </Col>
                <Col>
                    <Form.Control checked={this.state.adminContactSame} onChange={() => this.handleAdminContactClicked()} type="checkbox" />
                    {!this.state.adminContactSame &&
                        <RegistrationContact type="adminContact" contactUpdated={this.contactUpdated} />
                    }
                </Col>
            </Row>
            <hr />

            <Row>
                <Col>
                    Tech Contact
                    {!this.state.techContactSame &&
                        <RegistrationContact type="techContact" contactUpdated={this.contactUpdated} />
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    Tech Contact Same As Registration Contact:
                </Col>
                <Col>
                    <Form.Control checked={this.state.techContactSame} onChange={() => this.handleTechContactClicked()} type="checkbox" />

                </Col>
            </Row>
            <button style={{ background: "red", width: "80%", margin: "20px" }} role='link' onClick={() => this.props.setContacts(this.contactsAndPrivacy(), this.state.allValid)} className="button">Save</button>


            </div>
        );
    }
}
 
function mapStateToProps(state) {
    return {
        basket: state.basket
    }
}
function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationContacts);
