import React, { Component } from 'react';
import moment from 'moment'
import { Card, Table, Button, Row, Col } from 'react-bootstrap'
import Modal from "../../components/modal"
import Axios from 'axios';
import AdminUserDetailsBasket from './adminUserDetailsBasket';

class AdminUserDetails extends Component {
    state = {
        showUserDetails: false,
        lastActive: null,
        pageActivity: [],
        viewBasket: false

    }
    componentDidUpdate(oldprops) {
        if (oldprops.showUserDetails == false && this.props.showUserDetails || oldprops.showUserDetails == null && this.props.showUserDetails) {
            this.getData()
        }
    }
    getData = () => {
        Axios.post("/api/stats/user/last/active", { id: this.props.userSelectedToCheck }).then(resp => {
            this.setState({ lastActive: resp.data.lastActive })
        })
        Axios.post("/api/stats/page/visits/all/by/user", { id: this.props.userSelectedToCheck }).then(resp => {
            this.setState({ pageActivity: resp.data.activity })
        })

    }
    openViewBasket = () => {
        this.setState({viewBasket: true})
    }
    closeViewBasket = () => {
        this.setState({viewBasket: false})
    }

    render() {
        let pageActivity = []
        this.state.pageActivity.forEach((view, index) => {
            pageActivity.push(<tr key={index}>
                <td>{moment.unix(view.date).format('DD-MM-YYYY@HH:mm')}</td>
                <td>{view.site}</td>
                <td>{view.referer}</td>
                <td >{view.ip}</td>
            </tr>)
        })
        return (
            <div>
                <Modal show={this.props.showUserDetails} title={this.props.userSelectedToCheck + " Details"} close={this.props.closeUserDetails} content={
                    <div>
                        <div>
                            Last Active: {moment.unix(this.state.lastActive).format('DD-MM-YYYY@HH:mm')}
                        </div>
                        <div>
                            <button onClick={this.openViewBasket}>View Basket</button>
                        </div>
                        <div>
                            Page activity
                            <Table style={{ 'margin': '0px' }} bordered>
                                <thead className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                                    <tr>
                                        <th>Date</th>
                                        <th>Page</th>
                                        <th>Referer</th>
                                        <th>IP</th>
                                    </tr>
                                </thead>
                                <tbody className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                                    {pageActivity}
                                </tbody>
                            </Table>

                        </div>
                    </div>
                }>


                </Modal>
                {this.state.viewBasket && <AdminUserDetailsBasket userSelectedToCheck={this.props.userSelectedToCheck} show={this.state.viewBasket} title={this.props.userSelectedToCheck + " Basket"} close={this.closeViewBasket} content={<div></div>} />}

            </div>
        );
    }
}

export default AdminUserDetails;