import React, { Component } from 'react';
import {  Row, Col } from 'react-bootstrap'
import AdminBalance from '../components/admin/adminBalance'
import { connect } from 'react-redux';
import AdminCounts from "../components/admin/adminCounts"
import AdminUserList from "../components/admin/adminUserList"
import AdminNewStatsGraph from '../components/admin/adminNewStatsGraph';
import AdminTotalStatsGraph from '../components/admin/adminTotalStatsGraph';
import AdminSubscriptions from '../components/admin/adminSubscriptions';
import AdminSites from '../components/admin/adminSites';
import AdminViews from '../components/admin/adminViews';
import AdminLatestActiveUsers from '../components/admin/adminLatestActiveUsers';

class AdminStats extends Component {
    state = {
        users: [],
        views: [],
        sites: [],
        viewCount: 0,
        subscriptions: [],
        lastUpdated: 0,
        interval1: null,
        interval2: null,
        nonAdminGross: 0,
        usersIncludeAdmin: true,
        botSelectedToCheck: null,
        updateCount: 0
    }

    componentDidMount() {
        this.getData()
        let interval1 = setInterval(() => this.getData(), 20000)
        let interval2 = setInterval(() => {
            this.setState({ lastUpdated: this.state.lastUpdated + 1 })
        }, 1000)
        this.setState({ "interval1": interval1, "interval2": interval2 })
    }
    componentWillUnmount = () => {
        if (this.state.interval1) {
            clearInterval(this.state.interval1)
        }
        if (this.state.interval2) {
            clearInterval(this.state.interval2)
        }
    }


    getData = async () => {
        let data = {}
        data.lastUpdated = 0
        data.updateCount = this.state.updateCount + 1
        this.setState(data)
    }

    updateIncludeAdmin = (type) => {
        this.setState({ [type]: !this.state[type] })
    }


  



    render() {
        let darkmode = this.props.user.darkmode




        return (
            <div style={{ marginTop: '60px', marginBottom: '150px' }}>
                <p  className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}style={{ textAlign: 'center' }}>Last Updated: {this.state.lastUpdated} seconds ago</p>
                <Row>
                    <Col sm={6}>
                        <AdminBalance updateCount={this.state.updateCount} />
                    </Col>
                    <Col sm={6}>
                        <AdminCounts updateCount={this.state.updateCount} gross={this.state.nonAdminGross} pageviews={this.state.viewCount} users={this.state.usersCount} subscriptions={this.state.subscriptions.length} sites={this.state.sites.length} />
                    </Col>
                </Row>
                <AdminTotalStatsGraph updateCount={this.state.updateCount} darkmode={darkmode} />
                <AdminNewStatsGraph updateCount={this.state.updateCount} darkmode={darkmode} />
                <AdminUserList updateCount={this.state.updateCount} users={this.state.users} darkmode={darkmode} />
                <AdminSubscriptions updateCount={this.state.updateCount} darkmode={darkmode} />
                <AdminSites updateCount={this.state.updateCount} darkmode={darkmode} />
                <AdminViews updateCount={this.state.updateCount} darkmode={darkmode} />
                <AdminLatestActiveUsers updateCount={this.state.updateCount} darkmode={darkmode}/>



            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminStats);
