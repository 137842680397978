import React, { Component } from 'react';
import {Card, Table, Form, Button,Row,Col} from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import chart from 'chart.js'
import { connect } from 'react-redux';

class EmailEveryone extends Component {
    state = { 
        emailHtml: '',
        emailSubject: '',
        emailEnabled: false,
        showPreview: false
     }

    updateValue = (e,field) => {
        this.setState({[field]:e.target.value,emailEnabled: true})
    }
    sendEmail = () => {
        this.setState({emailEnabled: false},()=>{
            axios.post('/api/email/everyone',{emailSubject: this.state.emailSubject,emailHtml: this.state.emailHtml})
        })
    }
    showPreviewChange=()=>{
        this.setState({showPreview: !this.state.showPreview})
    }
    componentDidMount(){
        this.getTemplates()
        
    }
    getTemplates = () => {
        axios.get('/api/email/templates').then((res,err)=>{
            this.setState({emailTemplate: res.data.templates})
        })    
    }
    render() {     
        let darkmode = this.props.user.darkmode
    
        return ( 
            <div >
                <Card style={{ margin: '30px'}}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}  style={{textAlign: 'center'}}>
                        Email Everyone
                    </Card.Header>
                    <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"}  style={{'margin': '0px', paddingTop: "10px"}} >
                        <Row>
                            <Col xs={4}>
                            Subject

                            </Col>
                            <Col>
                            <Form.Control as='textarea' placeholder='Subject' onChange={(e)=>this.updateValue(e,'emailSubject')} />

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                            Html
                            </Col>
                            <Col>
                            <Form.Control as='textarea' placeholder='HTML' onChange={(e)=>this.updateValue(e,'emailHtml')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col >Show Preview</Col>
                            <Col ><Form.Control style={{float: 'left'}} checked={this.state.showPreview}  onClick={this.showPreviewChange} type="checkbox"/></Col>

                        </Row>
                        <hr/>
                        <Row style={{width: '100%', margin: 'auto'}}>
                            <div style={{maxWidth: '100%', width: '100%', margin: 'auto', }}>
                                {this.state.showPreview && <div  dangerouslySetInnerHTML={{__html: this.state.emailTemplate.standardHeader +`<p style="color: white">Hi !Email Address Added On Send!,</p>`+ this.state.emailHtml + this.state.emailTemplate.standardFooter}}/>}
                            </div>
                        </Row>
                        <Button disabled={!this.state.emailEnabled} style={{backgroundColor: '#343a40', width: '100%'}} onClick={this.sendEmail}>Send</Button>

                    </div>
                    <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}  > 
                    </Card.Footer>
                </Card>       
            </div>
         );
    }
}
 
 
function mapStateToProps(state) {
    return {
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailEveryone);





