import React, { Component } from 'react';
import { Card, Button, Row, Col, Form } from 'react-bootstrap'
import Axios from 'axios';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Modal from '../../modal'

class WordpressInstaller extends Component {
    state = {
        installEnabled: true,
        errormsg: '',
        expanded: '',
        wordpressInstallOpen: false,
        user: "",
        password: "",
        title: "",
        email: "",
        discourageEnabled: false,
        wordpressInstallDisabled: true,
        emailInvalidText: "",
        passwordInvalidText: "",
        userInvalidText: "",
        titleInvalidText: ""

    }
    installWordPress = () => {
        if (!this.props.disabled) {
            this.setState({ installEnabled: false })
            let subid = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
            Axios.post('/api/sites/create', { type: 'wordpress', subId: subid, user: this.state.user, password: this.state.password, email: this.state.email, title: this.state.title, discourageEnabled: this.state.discourageEnabled }).then(res => {
                if (res.data.success) {
                    this.setState({ errormsg: res.data.message })
                    this.props.refresh()
                } else {
                    this.setState({ errormsg: res.data.message, installEnabled: true })
                }
            })
        }
    }
    handleDiscourageClicked = () => {
        this.setState({ discourageEnabled: !this.state.discourageEnabled })
    }
    emailValid = () => {
        const regexEmailLike = /^[^@]+@[^@]+$/;
        if (this.state.email.length == 0) {
            if (this.state.emailInvalidText != "Email Required") {
                this.setState({ emailInvalidText: "Email Required" })
            }
            return false
        }
        if (this.state.email.length > 255) {
            if (this.state.emailInvalidText != "Email too long...") {

                this.setState({ emailInvalidText: "Email too long..." })
            }
            return false
        }
        if (regexEmailLike.test(this.state.email)) {
            return true
        } else {
            if (this.state.emailInvalidText != "Email Not Valid. E.g. email@domain.com") {

                this.setState({ emailInvalidText: "Email Not Valid. E.g. email@domain.com" })
            }
            return false
        }
    }
    updateValue = (key, value) => {
        this.setState({ [key]: value }, () => {
            let valid = true
            if (this.state.user.length < 2) {
                this.setState({userInvalidText: "User must be at least 2 characters long"})

                valid = false
            }else{
                this.setState({userInvalidText: ""})
            }
            if (this.state.title.length < 2) {
                this.setState({titleInvalidText: "Title must be at least 2 characters long"})
                valid = false
            }else{
                this.setState({titleInvalidText: ""})
            }
            if (this.state.password.length < 5) {
                this.setState({passwordInvalidText: "Password must be at least 5 characters long"})
                valid = false
            }else{
                this.setState({passwordInvalidText: ""})
            }
            if (!this.emailValid()) {
                valid = false
            }else{
                this.setState({emailInvalidText: ""})
            }
            this.setState({ wordpressInstallDisabled: !valid })

        })
    }


    expandDescription = (type) => {
        if (this.state.expanded === type) {
            this.setState({ expanded: '' })
        } else {
            this.setState({ expanded: type })
        }
    }
    openInstallWordpress = () => {
        this.setState({ wordpressInstallOpen: true })
    }
    closeInstallWordpress = () => {
        this.setState({ wordpressInstallOpen: false })
    }
    render() {
        let darkmode = this.props.user.darkmode
        let wordpressSettings = <div>
            <Row>
                <Col>
                    Site Title
                </Col>
                <Col>
                    <input style={{ width: '100%' }} type='text' value={this.state.title} onChange={(e) => { this.updateValue("title", e.target.value) }} placeholder='Site Title' />

                </Col>
            </Row>
            {this.state.titleInvalidText && <Row><Col style={{ color: "red" }}>{this.state.titleInvalidText}</Col></Row>}

            <hr />

            <Row>
                <Col>
                    Admin User
                </Col>
                <Col>
                    <input style={{ width: '100%' }} type='text' value={this.state.user} onChange={(e) => { this.updateValue("user", e.target.value) }} placeholder='Admin User' />

                </Col>
            </Row>
            {this.state.userInvalidText && <Row><Col style={{ color: "red" }}>{this.state.userInvalidText}</Col></Row>}

            <hr />
            <Row>
                <Col>
                    Admin Password
                </Col>
                <Col>
                    <input style={{ width: '100%' }} type='text' value={this.state.password} onChange={(e) => { this.updateValue("password", e.target.value) }} placeholder='Admin Password' />

                </Col>
            </Row>
            {this.state.passwordInvalidText && <Row><Col style={{ color: "red" }}>{this.state.passwordInvalidText}</Col></Row>}

            <hr />
            <Row>
                <Col>
                    Admin Email
                </Col>
                <Col>
                    <input style={{ width: '100%' }} type='text' value={this.state.email} onChange={(e) => { this.updateValue("email", e.target.value) }} placeholder='Admin Email' />

                </Col>
            </Row>
            {this.state.emailInvalidText && <Row><Col style={{ color: "red" }}>{this.state.emailInvalidText}</Col></Row>}
            <hr />

            <Row>
                <Col>
                    Discourage Search Engines From Indexing.
                </Col>
                <Col>
                    <Form.Control checked={this.state.discourageEnabled} onChange={() => this.handleDiscourageClicked()} type="checkbox" />

                </Col>
            </Row>
            <Row><Col>*Seach engines may not honour this, this is just a request.</Col></Row>
            <Row>
                <Col>
                    <Button disabled={this.state.wordpressInstallDisabled} onClick={this.installWordPress} >Install</Button>
                </Col>
            </Row>
        </div>
        return (
            <Col>
                <Card style={{ margin: 'auto', minWidth: '250px', display: 'block', marginTop: '20px', marginBottom: '20px', maxWidth: '500px' }}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                        WordPress
                    </Card.Header>
                    <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ textAlign: 'center', paddingTop: "10px", marginBottom: "0px", overflow: 'hidden', height: this.state.expanded === 'wordpress' ? '' : '300px' }}>
                        <img alt='wordpress' style={{ width: '60px' }} src={require('../../../wordpress.jpg')}></img>


                        <p style={{ ...{ padding: '20px', height: this.state.expanded == 'wordpress' ? '' : '200px', overflow: 'hidden' } }}>
                            Wordpress is one of the worlds most popular open-source content  management systems. It is excellent for blogs, personal websites and business website of any size.
                            Due to its popularity there are plenty of setup guides around for any use case you could need, making it perfect for beginners. With it being open source you can customize it however you want making the possibilities endless.
                        </p>
                        <p style={{ height: '20px', marginTop: "-15px" }} onClick={() => this.expandDescription('wordpress')}><FontAwesomeIcon size='2x' icon={this.state.expanded === 'wordpress' ? faChevronUp : faChevronDown} /></p>

                    </div >
                    <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                        {this.props.disabled ? <p style={{ color: 'red' }}>A domain name is required to enable installation.</p> : <Button disabled={this.props.disabled} onClick={this.openInstallWordpress} disabled={!this.state.installEnabled} >Install</Button>}
                        {this.state.errormsg}
                    </Card.Footer>
                </Card>
                <Modal show={this.state.wordpressInstallOpen} title={<h1>WordPress Settings</h1>} content={wordpressSettings} close={this.closeInstallWordpress} />
            </Col>
        );
    }
}

function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WordpressInstaller);