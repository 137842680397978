import React, { Component } from 'react';
import StripeContainerRecurring from '../components/stripe/stripeContainerRecurring'
import { Card, Row, Col, Button, Form } from 'react-bootstrap'
import Axios from 'axios';
import PurchasePolicy from '../components/purchaseCancellationRefundPolicy';
import { Link } from 'react-router-dom';
import AddToBasket from "../components/basket/addToBasket"
import { connect } from 'react-redux';
import BuyPlanFooter from './buyPlanFooter';
class Plans extends Component {
    state = {
        SelectedId: '',
        selectedPlan: '',
        hasSubscription: false,
        subscriptionStatus: null,
        showCancelModal: false,
        currentPlanId: null,
        currentEndDate: null,
        basicID: null,
        invoice: null,
        valueSelected: 0,
        availWidth: 0,
        availHeight: 0
    }
    handlePlanClick = (plan, value) => {
        if (this.state.selectedPlan === plan) {
            this.setState({ selectedPlan: '', SelectedId: '', valueSelected: 0 })
        } else {
            Axios.post('api/payment/get/priceid', { planName: plan }).then(res => {
                if (res.data.id) {
                    this.setState({ selectedPlan: plan, SelectedId: res.data.id, valueSelected: value })
                }

            })
        }

    }
    componentDidMount() {
        this.handlePlanClick("Standard", "2")
        this.updateWindowDimensions();

        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions = () => {
        this.setState({ availWidth: window.screen.availWidth, availHeight: window.screen.availHeight });
    }
    closePurchasePolicy = () => {
        this.setState({ showPurchasePolicy: false })
    }
    openPurchasePolicy = () => {
        this.setState({ showPurchasePolicy: true })
    }
    render() {
        let baseStyle = { maxWidth: '300px', minWidth: '240px', margin: 'auto' }
        let selectedStyle = { boxShadow: '0 0 30px #77b5e6', ...baseStyle }
        let prices = { Basic: '£1/Month', Standard: '£2/Month', Advanced: '£4/Month' }
        let mobile = this.state.availWidth < 724 || this.state.availHeight < 1000
        let darkmode = this.props.user.darkmode

        return (
            <div style={{ marginTop: '60px', marginBottom: '150px' }}>
                <h1 style={{ marginBottom: '20px', textAlign: 'center', color: "white" }}><u>Hosting Plans</u></h1>
                <Row style={{ maxWidth: '1000px', margin: 'auto' }}>

                    <Col style={{ marginTop: '10px' }}>
                        <Row>
                            <Card onClick={() => { this.handlePlanClick('Basic', '1') }} style={this.state.selectedPlan === 'Basic' ? selectedStyle : baseStyle}>
                                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                                    <b>Basic Hosting</b>
                                </Card.Header>
                                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                                    <ul style={{ height: '230px' }}>
                                        <li>Free SSL Certificate</li>
                                        <li>Free BreezeLabs Domain Name</li>
                                        <li>5GB SSD Storage</li>
                                        <li>Standard Support</li>
                                        <li>Unlimited Bandwidth</li>
                                        <li>1 Click Website Installers</li>
                                        <li>BreezeLabs Control Panel</li>
                                        <li>Easy Cancellations</li>
                                        <li>PHPMyAdmin</li>
                                        <li>FTP</li>
                                    </ul>
                                </div>
                                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                                    <p style={{ textAlign: 'center' }}>
                                        <b>
                                            £1.00 / month
                                        </b>
                                    </p>
                                    <Form.Control style={{ margin: "10px", margin: "auto" }} checked={this.state.selectedPlan === 'Basic'} onClick={() => { this.handlePlanClick('Basic', "1") }} type="checkbox" />

                                </Card.Footer>
                            </Card>
                        </Row>
                     

                    </Col>
                    <Col style={{ marginTop: '10px' }}>
                        <Row>

                            <Card onClick={() => { this.handlePlanClick('Standard', "2") }} style={this.state.selectedPlan === 'Standard' ? selectedStyle : baseStyle}>
                                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                                    <b>Standard Hosting</b>
                                </Card.Header>
                                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"}>

                                    <ul style={{ height: '230px' }}>
                                        <li>All Basic Features</li>
                                        <li>10GB SSD Storage</li>
                                        <li>24/7 Support</li>
                                        <li>Manual Backups</li>
                                        <li>1 Email Account (Coming Soon)</li>
                                    </ul>
                                </div>
                                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                                    <p style={{ textAlign: 'center' }}>
                                        <b>
                                            £2.00 / Month
                                        </b>
                                    </p>
                                    <Form.Control style={{ margin: "10px", margin: "auto" }} checked={this.state.selectedPlan === 'Standard'} onClick={() => { this.handlePlanClick('Standard', "2") }} type="checkbox" />

                                </Card.Footer>
                            </Card>
                        </Row>
         
                    </Col>

                    <Col style={{ marginTop: '10px' }}>
                        <Row>
                            <Card onClick={() => { this.handlePlanClick('Advanced', "4") }} style={this.state.selectedPlan === 'Advanced' ? selectedStyle : baseStyle}>
                                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                                    <b>Advanced Hosting</b>
                                </Card.Header>
                                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"}>

                                    <ul style={{ height: '230px' }}>
                                        <li>All Basic Features</li>
                                        <li>20GB SSD Storage</li>
                                        <li>24/7 Priority Support</li>
                                        <li>Manual Backups</li>
                                        <li>Weekly Automated Backups</li>
                                        <li>10 Email Accounts (Coming Soon)</li>
                                    </ul>
                                </div>
                                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                                    <p style={{ textAlign: 'center' }}>
                                        <b>
                                            £4.00 / Month
                                        </b>
                                    </p>
                                    <Form.Control  checked={this.state.selectedPlan === 'Advanced'} onClick={() => { this.handlePlanClick('Advanced', "4") }} type="checkbox" />
                                </Card.Footer>
                            </Card>
                        </Row>
                     

                    </Col>
                </Row>

                <div style={{ width: '100%', margin: 'auto', textAlign: 'center', marginTop: '20px', display: 'block' }}>
                    <h5 style={{ color: "white" }}><u>Selected</u></h5>
                    <p style={{ color: "white" }}>Plan: {this.state.selectedPlan ? this.state.selectedPlan + ' - ' + prices[this.state.selectedPlan] : "None - Click on a plan to select."}</p>
                </div>
                <div style={{ width: '100%', margin: 'auto', textAlign: 'center', marginTop: '20px', display: 'block' }}>

                    <div style={{ marginTop: "20px" }}><Link onClick={this.openPurchasePolicy}  >Purchase, Return and Refund Policy</Link></div>
                    <p style={{ marginTop: '20px', fontSize: '15px', margin: 'center', color: "white" }}>
                        Coupon codes can be added on the payment page
                        (Click 'view details' if viewing on mobile).
                    </p>

                    <PurchasePolicy showPolicy={this.state.showPurchasePolicy} closePolicy={this.closePurchasePolicy} openPolicy={this.openPurchasePolicy} />
                </div>
                <BuyPlanFooter selectedPlan={this.state.selectedPlan} SelectedId={this.state.SelectedId} valueSelected={this.state.valueSelected} />
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans);





