import React, { Component } from 'react';
import {Button, Modal, Row, Col, Form} from 'react-bootstrap'
import Axios from 'axios';
import { connect } from 'react-redux';
import {setBackupConfiguration} from '../../actions/subscriptionsActions'
class SetDomainName extends Component {
    state = { 
        time: '',
        day: ''
     }
     timeChanged=(e)=>{
        this.setState({time: e.target.value})
     }
     UpdateBackupConfiguration=()=>{
        Axios.post('/api/backup/configuration/update',{time: this.state.time,day: this.state.day,subscriptionItem: this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem})
        this.props.setBackupConfiguration({backupDay: this.state.time,index: this.props.subscriptions.detailedIndex,backupTime: this.state.day})
        this.props.closeConfigureBackupsModal()
     }
     componentDidMount(){
        this.setState({time: this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].backupTime,day: this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].backupDay})
     }
    render() { 
        let darkmode = this.props.user.darkmode

        return ( 
            <Modal style={{width: '90%'}} onHide={this.props.closeConfigureBackupsModal} show={this.props.show}>
                    <Modal.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                        <Modal.Title>
                            Configure Backups
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                       <Row>
                        Weekly Backup Time
                       </Row>
                       <Row>
                            <input type="time" value={this.state.time} onChange={this.timeChanged}/>
                       </Row>
                       <Row>
                        Weekly Backup Day
                       </Row>
                       <Row>
                        <Col><Row>Mon</Row><Row><Form.Control checked={this.state.day === "monday"} onClick={()=>this.setState({day:"monday"})} type="checkbox"/></Row></Col>
                        <Col><Row>Tue</Row><Row><Form.Control checked={this.state.day === "tuesday"} onClick={()=>this.setState({day:"tuesday"})} type="checkbox"/></Row></Col>
                        <Col><Row>Wed</Row><Row><Form.Control checked={this.state.day === "wednesday"} onClick={()=>this.setState({day:"wednesday"})} type="checkbox"/></Row></Col>
                        <Col><Row>Thu</Row><Row><Form.Control checked={this.state.day === "thursday"} onClick={()=>this.setState({day:"thursday"})} type="checkbox"/></Row></Col>
                        <Col><Row>Fri</Row><Row><Form.Control checked={this.state.day === "friday"} onClick={()=>this.setState({day:"friday"})} type="checkbox"/></Row></Col>
                        <Col><Row>Sat</Row><Row><Form.Control checked={this.state.day === "saturday"} onClick={()=>this.setState({day:"saturday"})} type="checkbox"/></Row></Col>
                        <Col><Row>Sun</Row><Row><Form.Control checked={this.state.day === "sunday"} onClick={()=>this.setState({day:"sunday"})} type="checkbox"/></Row></Col>

                       </Row>
                    </Modal.Body>
                    <Modal.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                        <div style={{width: '100%'}}>
                            <Row>
                                <Col>
                                <Button style={{margin: "5px"}} onClick={this.UpdateBackupConfiguration}>Update</Button>    

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <Button style={{ margin: "5px"}} onClick={this.props.closeConfigureBackupsModal}>Cancel</Button>    

                                </Col>
                            </Row>
                        </div>
                        
                    </Modal.Footer>
                </Modal>
         );
    }
}
 
function mapStateToProps(state){
    return{
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch){
    return{
        setBackupConfiguration: (data)=>{dispatch(setBackupConfiguration(data))}
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SetDomainName);
