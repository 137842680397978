import React, { Component } from 'react';
import { Button } from 'react-bootstrap'
import Modal from './modal';
class PrivacyPolicy extends Component {
    state = {}
    render() {
        let title = <h1 style={{ margin: '5px' }}><u>BreezeLabs Purchase, Cancellation and Refund Policy</u></h1>
        let body = <div >
            <h5>Automatic renewals</h5>
            By purchasing a subscription, you agree to set up a monthly subscription where payments are collected automatically by Stripe each month, until the subscription is cancelled by yourself or us at BreezeLabs.
            As Stripe authenticates your payment method, you may briefly see a small charge.
            Your hosting plan is automatically set to renew at the end of your subscription. Any promotional price lasts only as long as stated. At any point, you can cancel or delete your plan.

            <h5>If your storage plan is canceled or expires</h5>
            If your auto-renewal fails, a 2 day grace period will be added to your subscription so that your able to update or fix your payment information before your
            data is permanently deleted. During this grace period you wont lose access to anything related to that subscription.

            <h5>Cancel your subscription</h5>
            You can cancel or delete your subscription at any time. If you cancel your subscription, all services and data releated to that subscription will be available until the subscriptions end date. If you delete your subscription, all services will be unavailable and
            your data will be permanently deleted immediately.
            <p>Cancel your Subscription: 1. Click on the relevent subscription you wish to cancel. 2. Click "Cancel Subscription" in the "Hosting plan details" section. 3. Confirm that you wish to cancel.</p>
            <p>Delete your Subscription: 1. Click on the relevent subscription you wish to delete. 2. Click "Delete plan" in the "Hosting plan details" section. 3. Confirm that you wish to delete.</p>

            <h5>Out of plan fees</h5>
            By purchasing a subscription, you agree to stick within your chosen plans storage quota and that you will pay the over storage fees if you exceed your quota. This will be 50p/gigabyte you have exceeded per month.
            These fees will be charged weekly in respect to how much you are over your plan quota at that time. If we do not receive payment within 2 days of the invoice being created your subscription will be cancelled. If this happens,
            we will contact you ASAP to discuss upgrading your plan or to create a custom plan to suit your needs.

            <h5>Refund policy</h5>
            If you cancel within the first 14 days of purchase, you can receive a full refund on the relevent hosting subscription for that specific month only. To apply for the refund you must email us at breezelabsuk@gmail.com within the first 14 days of that months purchase. BreezeLabs does not
            offer any refunds for any cancellations that occur after 14 days of purchase.

            <h5><u>Contact Us</u></h5>
            <p>If you have any questions about this Purchase, Cancellation and Refund Policy, please contact us.</p>
            <p>Email: breezelabsuk@gmail.com</p>
        </div>
        return (
            <Modal show={this.props.showPolicy} title={<h1>BreezeLabs Privacy Policy</h1>} content={body} close={this.props.closePolicy} />

        
      );
    }
}

export default PrivacyPolicy;

