import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setSubscriptionsCancelled} from '../../../actions/subscriptionsActions'
import {Redirect} from "react-router-dom"
import {Card} from 'react-bootstrap'
import SiteDetails from '../../../components/myHosting/siteDetails'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLock} from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import WebsiteInstallerComp from '../../../components/myHosting/websiteInstaller'

class WebsiteInstaller extends Component {
    state = { 
        type: null,
        domain: null,


     } 

    getSiteData=()=>{

        let subid = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
        Axios.post('/api/sites/get-site',{subId: subid}).then(req=>{
            if(req.data.site){
                this.setState({domain: req.data.domain, type: req.data.type})
            }else{
                this.setState({domain: null, type: null})
            }
        }) 
    
    }
    componentDidMount(){
        if(this.props.subscriptions.detailedIndex !== null){
            this.getSiteData()    

        }
        
        
    }
    render() { 
        if(this.props.subscriptions.detailedIndex === null){
            return <Redirect to='/my-hosting'/>
        }
        let subscription = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex]

        let manageSiteAllowed = subscription.status != 'waiting' && subscription.status != 'expired'
        return (<div>
            {manageSiteAllowed ? 
                    <div>
                        
                        <div>
                        {
                            !this.state.type || this.state.type === 'parking' ?
                                <WebsiteInstallerComp disabled={this.state.domain === null} refresh={this.getSiteData}/>
                            :
                                <SiteDetails domain={this.state.domain} refresh={this.getSiteData} type={this.state.type}/>
                            }
                        </div>
                    </div>
                    :
                    <div>
                        <Card style={{width: '80%',margin: 'auto', display: 'block', marginTop: '20px'}}>
                            <Card.Header style={{marginBottom: '10px',textAlign: 'center'}}>
                                Manage Site
                            </Card.Header>
                            <Card.Text style={{textAlign: 'center'}}>
                            <FontAwesomeIcon style={{paddingBottom: '20px'}}  size='4x' icon={faLock}/>
                                <p>Your subscription status must be active to manage your site.</p>
                            </Card.Text>
                        </Card>
                    </div>
                }
        </div>);
    }
}
 
function mapStateToProps(state){
    return{
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch){
    return{
        setSubscriptionsCancelled: (index)=>{dispatch(setSubscriptionsCancelled(index))}
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(WebsiteInstaller);