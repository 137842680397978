import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import AddToBasket from "../components/basket/addToBasket"
import { connect } from 'react-redux';

class Suggestions extends Component {
    state = {}
    render() {
        let plans = {
            basic: "price_1I4v0NA6TEV3bmMSFsqTibGS",
            standard: "price_1ImxlQA6TEV3bmMS8s20X8yz",
            advanced: "price_1ImxnAA6TEV3bmMSnatxpoyN"
        }

        return (
            <div style={{width: "80%", margin: "auto"}} >
                <hr className={"darkmode-hr"} style={{ width: "70%" }} />
                <Row>
                    <h4 style={{ margin: "auto" }}>Create Your Online Presence Today!</h4>
                </Row>
                <Row  style={{ margin: "auto", width: "100%" }}>
                    <Col sm={8}>
                        <div style={{  }}>
                            <Row>
                                <p style={{ margin: "auto" }}>Hosting Plan</p>

                            </Row>
                            <Row style={{marginBottom: "10px", textAlign: "center", margin: "auto"}} >
                                <Col style={{ margin: "auto", textAlign: "center", width: "100%" }}>
                                    <Row>
                                        <Col>
                                            Basic
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <AddToBasket name={"Basic"} priceId={plans.basic} type="hosting" icon={<FontAwesomeIcon size='2x' icon={faPlus} />} disabled={false} />

                                        </Col>
                                    </Row>

                                </Col>
                                <Col style={{ margin: "auto", textAlign: "center", width: "100%" }}>
                                    <Row>
                                        <Col>Standard</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <AddToBasket name={"Standard"} priceId={plans.standard} type="hosting" icon={<FontAwesomeIcon size='2x' icon={faPlus} />} disabled={false} />

                                        </Col>
                                    </Row>

                                </Col>
                                <Col style={{ margin: "auto", textAlign: "center", width: "100%" }}>
                                    <Row>
                                        <Col>Advanced</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <AddToBasket name={"Advanced"} priceId={plans.advanced} type="hosting" icon={<FontAwesomeIcon size='2x' icon={faPlus} />} disabled={false} />
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        </div>
                    </Col>
                    <Col sm={4}>
                        <div style={{ height: "100%", textAlign: "center" }}>
                            <p style={{ margin: "auto" }}>Domain</p><br/>
                            <Button as={Link} to="/domains">Find a domain</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);