import React, { Component } from 'react';
import { Row, Button, Card, Col} from 'react-bootstrap'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
class HostingAbout extends Component {
    state = {  } 
    render() { 
        return (
            <Card style={{width: '80%',margin: 'auto', maxWidth:'1000px', marginTop: '40px'}}>
                        <Card.Header style={{marginBottom: '10px',textAlign: 'center'}}>
                            <h4 style={{textAlign: 'center', margin: 'auto'}}>Want to find out more about our hosting?</h4>
                        </Card.Header>
                        <Row style={{margin: '10px'}}>
                            <Button style={{backgroundColor: '#343a40', height: '100%', width: '80%', margin: 'auto'}}  href="https://breezelabs.co.uk/hosting"> Find out more</Button>
                        </Row>
        </Card>
        );
    }
}
 
export default HostingAbout;