import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class CheckoutFooter extends Component {
    state = {
        show: false,
        items: null,
        itemAdded: "",
        initialised: false
        
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({initialised: true})
        }, 1000); 
    }
    shouldIShow = () => {
        if(this.state.items == null && !this.props.basket[0]){
            return
        }

        //if the basket and props are the same
        if(this.props.basket == this.state.items){
            return
        }
        //set the items
        let orig = this.state.items
        this.setState({items: this.props.basket, show: false},()=>{
            if(orig == null && !this.state.initialised){
                return
            }
            if(orig == null){
                let str = ""

                if(this.props.basket[0].type == "hosting"){
                    str = this.props.basket[0].name+ " " + this.props.basket[0].type
                }
                else{
                    str= this.props.basket[0].domain + " ("+this.props.basket[0].years+" Years)"
                }
                this.setState({show: true, itemAdded: str})
                return
            }
            this.props.basket.forEach((item, i) => {
                let str = ""
                if(!orig[i]){
                    if(item.type == "hosting"){
                        str = item.name+ " " + item.type
                    }
                    else{
                        str= item.domain + " ("+item.years+" Years)"
                    }
                    this.setState({show: true, itemAdded: str})
                    return
                }
                if(orig[i].amount != item.amount){
                    if(item.type == "hosting"){
                        str = item.name + " " + item.type
                    }
                    else{
                        str= item.domain + " ("+item.years+" Years)"
                    }
                    this.setState({show: true, itemAdded: str})
                    return
                }
            
            });
        })

       
        



    }
    render() {
        this.shouldIShow()
        if (this.state.show) {
            return (<div className='checkout-footer' style={{height: "150px"}}>
                <Button onClick={() => { this.setState({ show: false }) }} style={{ float: "right", width: "50px" }}>X</Button>
                <p>{this.state.itemAdded} Added To Basket</p>
                <Button as={Link} onClick={()=>{this.setState({show: false})}} to="/basket">View Basket</Button>


            </div>);
        }
        else {
            return null
        }
    }
}

function mapStateToProps(state) {
    return {
        basket: state.basket
    }
}
function mapDispatchToProps(dispatch) {
    return {


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutFooter);