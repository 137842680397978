import React, { Component } from 'react';
import {Button, Modal} from 'react-bootstrap'
import StripeContainerRecurring from '../stripe/stripeContainerRecurring'
import Axios from 'axios'
import { connect } from 'react-redux';
class DeleteDomainName extends Component {
    state = { 
        SelectedId: '',
        selectedPlan: '',
        valueSelected: 0
     }
   test=()=>{
    let plan = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].plan.split(" ")[0]
    Axios.post('/api/payment/get/priceid',{planName: plan}).then(res=>{
        if(res.data.id){
            this.setState({selectedPlan: this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].plan, SelectedId: res.data.id, valueSelected: res.data.value})
        }
    })
   }
    render() { 
        return ( 
            <Modal onShow={this.test} onHide={this.props.closeRestartPlanModal} show={this.props.show}>
                    <Modal.Header>
                        <Modal.Title>
                            Restart Plan
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <p>Click 'Checkout' below to restart your '{this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].plan}' plan. This will restart the plan once your payment is received.</p>
                        <StripeContainerRecurring disabled={false} priceId={this.state.SelectedId} value={this.state.valueSelected} restartId={this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{width: '100%'}}>
                            <Button style={{float: 'left',backgroundColor: '#343a40'}} onClick={this.props.closeRestartPlanModal}>Cancel</Button>    
                        </div>
                        
                    </Modal.Footer>
                </Modal>
         );
    }
}
 
function mapStateToProps(state){
    return{
        subscriptions: state.subscriptions,
    }
}
function mapDispatchToProps(dispatch){
    return{
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DeleteDomainName);
