import React, { Component } from 'react';
import Axios from "axios"
import { Col, Row, Spinner } from 'react-bootstrap';

class NameServers extends Component {
    state = {
        errorGetting: false,
        nameservers: [],
        loading: false
    }
    componentDidMount() {
        this.setState({ loading: true })
        Axios.post("/api/domains/get/nameservers", { domain: this.props.domain }).then(res => {
            if (!res.data.success) {
                this.setState({ errorGetting: true })
            } else {
                this.setState({
                    nameservers: res.data.nameservers
                })
            }
            this.setState({ loading: false })
        })

    }
    render() {
        let error = this.state.errorGetting
        let nameservers = []
        this.state.nameservers.forEach((namerserver, i) => {
            nameservers.push(
                <Row>
                    <Col>
                        Nameserver {i}: {namerserver.Name}
                    </Col>
                </Row>
            )
        })
        return (


            <div>
                {this.state.loading ?
                <div style={{margin: "auto", textAlign: "center"}}>
                    <Spinner animation="border" vaiant="secondary" style={{ width: '50px', height: '50px', fontSize: '30px' }} />

                </div>
                    : error ? <div>
                        <p>Error getting nameservers: please contact support.</p>
                    </div> :
                        nameservers

                }

            </div>
        );
    }
}

export default NameServers;