import React, { Component } from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import DeleteAccount from '../../components/account/deleteAccount'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import { connect } from 'react-redux';

class AccountData extends Component {
    state = {
        showDeleteConfirm: false,
    }

    openDeleteConfirm = () => {
        this.setState({ showDeleteConfirm: true })
    }
    closeDeleteModal = () => {
        this.setState({ showDeleteConfirm: false })
    }

    render() {
        let darkmode = this.props.user.darkmode

        return (
            <div>
                <Card style={{ width: '90%', margin: 'auto', display: 'block', marginTop: '60px', marginBottom: '60px' }}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{  textAlign: 'center' }}>
                        <Row>
                            <Button as={Link} to='/account' style={{ marginLeft: '20px', backgroundColor: 'rgb(0, 123, 255)' }}><FontAwesomeIcon size='2x' icon={faArrowLeft} /></Button>
                            <h3 style={{ textAlign: 'center', margin: 'auto' }}>Account Data</h3>

                        </Row>
                    </Card.Header>
                    <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} >

                        <Row style={{ padding: '20px', textAlign: 'center' }}>
                            <Col sm='3'>Delete Account: </Col>
                            <Col >
                                <Button onClick={this.openDeleteConfirm} style={{ width: '60%', backgroundColor: '#343a40' }} type='submit'>Delete</Button>
                            </Col>
                        </Row>
                    </div>

                    <DeleteAccount closeDeleteModal={this.closeDeleteModal} show={this.state.showDeleteConfirm} />
                </Card>



            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setUser: (data) => { dispatch(setUser(data)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountData);
