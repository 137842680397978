import React, { Component } from 'react';
import { Row, Button, Col } from 'react-bootstrap'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import HostingCard from './hostingCard'
import { connect } from 'react-redux';

class AddPlanCard extends Component {
    state = {
        hovered: false
    }
    openDetails = ()=>{
        this.setState({hovered: true})
    }
    closeDetails = ()=>{
        this.setState({hovered: false})
    }
    render() {
        let darkmode = this.props.user.darkmode
        return (
            <HostingCard>
                <Button to='/plans' as={Link} style={{ backgroundColor: this.state.hovered? darkmode ? "#2a2e32": "white" :darkmode?'#343a40': "white", color: darkmode? "white":'black', width: "100%", height: "100%" }} onMouseEnter={this.openDetails} onMouseLeave={this.closeDetails}>
                    <Row style={{ top: "35%", position: 'relative', marginLeft: "0px" }}>
                        <Col>
                            <Row>
                                <Col style={{ maxWidth: '50px' }}>
                                    <FontAwesomeIcon size='3x' icon={faPlus} />
                                </Col>
                                <Col style={{ marginLeft: '10px', maxWidth: '300px' }}>
                                    <p style={{ fontSize: '30px' }}>Add a hosting plan</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Button>
            </HostingCard>
        );
    }
}

function mapStateToProps(state) {
    return {
      user: state.user
    }
  }
  function mapDispatchToProps(dispatch) {
    return {
  
    }
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(AddPlanCard);
