import initialState from './initialState'
import { SET_SUBSCRIPTIONS_DATA, SET_SUBSCRIPTIONS_DETAILED_INDEX, SET_SUBSCRIPTIONS_CANCELLED, START_SET_SUBSCRIPTIONS_DATA, SET_BACKUP_CONFIGURATION} from '../actions/actionsTypes'
import _ from 'lodash'
export default function subscriptions(state=initialState.subscriptions, action){
    let newState = _.cloneDeep(state)
    switch(action.type){
        case SET_SUBSCRIPTIONS_DATA:
            newState.subscriptions = action.payload
            newState.retrieved = true
            if(!action.payload){
                newState.subscriptions = []
            }
            return newState
        case START_SET_SUBSCRIPTIONS_DATA:
            newState.retrieved = false
            return newState
        case SET_SUBSCRIPTIONS_DETAILED_INDEX:
            newState.detailedIndex = action.payload
            return newState
        case SET_SUBSCRIPTIONS_CANCELLED:
            newState.subscriptions[action.payload].status = 'cancelled'
            return newState
        case SET_BACKUP_CONFIGURATION:
            newState.subscriptions[action.payload.index].backupDay = action.payload.backupDay
            newState.subscriptions[action.payload.index].backupTime = action.payload.backupTime
            return newState
        default:
            return state
    }
}