import React, { Component } from 'react';
import { Spinner, DropdownButton, DropdownItem, Row, Col, Card, Form } from 'react-bootstrap'
import _ from 'lodash'
import Axios from "axios"
import Modal from "../../components/modal"
import StripeContainerDomain from '../../components/stripe/stripeContainerDomain'
import RegistrationContact from "../../components/domain/registrationContact"
import AddToBasket from "../basket/addToBasket"
import { connect } from 'react-redux';
import DomainReccomendations from "./domainReccomendations"
import DomainSearch from "./domainSearch"
class BuyDomain extends Component {
    state = {
        showBuy: false,
        domainToBuy: "",
        years: 1,
        domainToBuyOneYearPriceDollar: null,
        adminContactSame: true,
        techContactSame: true,
        registrationContact: {},
        techContact: {},
        adminContact: {},
        registrationContactPrivacyEnabled: true,
        techContactPrivacyEnabled: true,
        adminContactPrivacyEnabled: true,
        techContactValid: false,
        adminContactValid: false,
        registrationContactValid: false,
        sale: false,
        autoRenew: true,
        buyDisabled: true,
        domainToBuyOneYearPricePoundOffer: null,
        OfferMinus: 0,
        domainSearched: ""

    }

    componentDidMount() {
        this.isSaleActive()
    }

    isSaleActive = () => {
        Axios.get("/api/domains/sale/active").then(res => {
            this.setState({ sale: res.data.active, OfferMinus: res.data.minus })

        })
    }


    closeBuy = () => {
        this.setState({ showBuy: false })
    }
    setYears = (years) => {
        this.setState({ years: years })
    }
    handleAdminContactClicked = () => {
        this.setState({ adminContactSame: !this.state.adminContactSame, adminContact: this.state.registrationContact, adminContactValid: !this.state.adminContactSame ? this.state.registrationContactValid : false, adminContactPrivacyEnabled: this.state.registrationContactPrivacyEnabled }, () => {
            this.isValid()
        })
    }
    handleTechContactClicked = () => {
        this.setState({ techContactSame: !this.state.techContactSame, techContact: this.state.registrationContact, techContactValid: !this.state.techContactSame ? this.state.registrationContactValid : false, techContactPrivacyEnabled: this.state.registrationContactPrivacyEnabled }, () => {
            this.isValid()
        })
    }
    handleAutoRenewClicked = () => {
        this.setState({ autoRenew: !this.state.autoRenew })
    }
    contactUpdated = (type, values) => {
        let json = {
            FirstName: values.firstName,
            LastName: values.lastName,
            ContactType: values.contactType,
            OrganizationName: values.organisation,
            AddressLine1: values.addressLine1,
            AddressLine2: values.addressLine2,
            City: values.city,
            State: values.stateProvince,
            CountryCode: values.country,
            ZipCode: values.zipPostal,
            PhoneNumber: "+" + values.phoneCode + "." + values.phoneNumber,
            Email: values.email,
            //Fax
            //ExtraParams
        }
        let techstuff = {}
        let adminstuff = {}
        if (type == "registrationContact") {
            if (this.state.techContactSame) {
                techstuff = { "techContact": json, techContactPrivacyEnabled: values.privacyEnabled, techContactValid: values.valid }

            }
            if (this.state.adminContactSame) {
                adminstuff = { "adminContact": json, adminContactPrivacyEnabled: values.privacyEnabled, adminContactValid: values.valid }
            }
        }

        let privacyType = type + "PrivacyEnabled"
        let validType = type + "Valid"
        let setstatestuff = {
            ...techstuff
            ,
            ...adminstuff,
            [type]: json, [privacyType]: values.privacyEnabled, [validType]: values.valid
        }
        this.setState(setstatestuff, () => {
            this.isValid()
        })
    }

    isValid = () => {
        if (this.state.adminContactValid && this.state.techContactValid && this.state.registrationContactValid) {
            this.setState({ buyDisabled: false })
        } else {
            this.setState({ buyDisabled: true })
        }
    }

    processBuyDomain = (data) => {
        this.setState({ ...data })
    }

    contactsAndPrivacy = () => {
        let contactsAndPrivacy = {
            RegistrationContact: this.state.registrationContact,
            AdminContact: this.state.adminContact,
            TechContact: this.state.techContact,
            PrivacyProtectAdminContact: this.state.adminContactPrivacyEnabled,
            PrivacyProtectTechContact: this.state.techContactPrivacyEnabled,
            PrivacyProtectRegistrationContact: this.state.registrationContactPrivacyEnabled,
            TechContactSame: this.state.techContactSame,
            AdminContactSame: this.state.adminContactSame
        }
        return contactsAndPrivacy
    }
    domainSearched = (domain) => {
        this.setState({ domainSearched: domain })
    }
    domainCheckoutClicked = () => {
        this.setState({ buyDisabled: true })
    }
    close = () => {
        this.closeBuy()
    }
    render() {
        let darkmode = this.props.user.darkmode
        let buyContents =
            <div>
                <Row>
                    <Col>Domain: {this.state.domainToBuy}</Col>
                </Row>
                <hr />
                <Row>
                    <Col>Years:</Col>
                    <Col>
                        <DropdownButton align="end" title={this.state.years.toString()} variant="primary">
                            <DropdownItem onClick={() => this.setYears(1)}>1</DropdownItem>
                            <DropdownItem onClick={() => this.setYears(2)}>2</DropdownItem>
                            <DropdownItem onClick={() => this.setYears(3)}>3</DropdownItem>
                            <DropdownItem onClick={() => this.setYears(4)}>4</DropdownItem>
                            <DropdownItem onClick={() => this.setYears(5)}>5</DropdownItem>
                            <DropdownItem onClick={() => this.setYears(6)}>6</DropdownItem>
                            <DropdownItem onClick={() => this.setYears(7)}>7</DropdownItem>
                            <DropdownItem onClick={() => this.setYears(8)}>8</DropdownItem>
                            <DropdownItem onClick={() => this.setYears(9)}>9</DropdownItem>
                            <DropdownItem onClick={() => this.setYears(10)}>10</DropdownItem>
                        </DropdownButton>
                    </Col>
                </Row>
                <hr />

                <Row><Col>
                    {this.state.domainToBuyOneYearPricePoundOffer && this.state.years == 1 ?
                        <div>
                            <Row>
                                <Col>
                                    Price: £{this.state.domainToBuyOneYearPricePoundOffer}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p style={{ fontSize: "10px" }}>For the first year, then: £{(this.state.domainToBuyOneYearPriceDollar * this.props.exchangeRate * this.state.years).toFixed(2)} (${this.state.domainToBuyOneYearPriceDollar * this.state.years}) per year</p>
                                </Col>
                            </Row>
                        </div>
                        :
                        <div>Price: £{(this.state.domainToBuyOneYearPriceDollar * this.props.exchangeRate * this.state.years).toFixed(2)} (${this.state.domainToBuyOneYearPriceDollar * this.state.years})</div>
                    }
                </Col>
                </Row>
                <hr />

                <Row>
                    <Col>
                        <h3>Registration Contact</h3>
                    </Col>
                </Row>
                <RegistrationContact type="registrationContact" contactUpdated={this.contactUpdated} />
                <hr />



                <Row>
                    <Col>
                        Admin Contact Same As Registration Contact:
                    </Col>
                    <Col>
                        <Form.Control checked={this.state.adminContactSame} onChange={() => this.handleAdminContactClicked()} type="checkbox" />
                        {!this.state.adminContactSame &&
                            <RegistrationContact type="adminContact" contactUpdated={this.contactUpdated} />
                        }
                    </Col>
                </Row>
                <hr />

                <Row>
                    <Col>
                        Tech Contact
                        {!this.state.techContactSame &&
                            <RegistrationContact type="techContact" contactUpdated={this.contactUpdated} />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Tech Contact Same As Registration Contact:
                    </Col>
                    <Col>
                        <Form.Control checked={this.state.techContactSame} onChange={() => this.handleTechContactClicked()} type="checkbox" />

                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        AutoRenew:
                    </Col>
                    <Col>
                        <Form.Control checked={this.state.autoRenew} onChange={() => this.handleAutoRenewClicked()} type="checkbox" />

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>* Domain Prices are set in USD by the corresponding TLD authority. BreezeLabs converts this to GBP to charge the user, therefore the yearly price for each domain will differ depending on the USD - GBP exchange rate.</p>
                        <p>* By Clicking Buy Now, you are agreeing to have your default payment method automaticaly charged the renewal fee if applicable.</p>
                    </Col>
                </Row>
                <Row><Col style={{ margin: "auto", textAlign: "center" }}>
                    <AddToBasket close={this.close} adminContactValid={this.state.adminContactValid} offerPrice={this.state.domainToBuyOneYearPricePoundOffer} techContactValid={this.state.techContactValid} registrationContactValid={this.state.registrationContactValid} type="domain" domain={this.state.domainToBuy} priceDollarPerYear={this.state.domainToBuyOneYearPriceDollar} years={this.state.years} contactsAndPrivacy={this.contactsAndPrivacy()} autoRenew={this.state.autoRenew} disabled={false} /></Col></Row>

                <Row><Col style={{ margin: "auto", textAlign: "center" }}><StripeContainerDomain offerPrice={this.state.domainToBuyOneYearPricePoundOffer} disabled={this.state.buyDisabled} domainCheckoutClicked={this.domainCheckoutClicked} autoRenew={this.state.autoRenew} contactsAndPrivacy={this.contactsAndPrivacy} years={this.state.years} yearlyPricePound={(this.state.domainToBuyOneYearPriceDollar * this.props.exchangeRate).toFixed(2)} domain={this.state.domainToBuy} />
                </Col></Row>
            </div>

        return (<div>
            {
                this.state.sale &&
                <Card style={{ textAlign: "center", padding: "15px", border: "2px solid #ff5722", borderRadius: "10px", marginLeft: "30px", marginRight: "30px", marginBottom: "-20px", marginTop: "20px", animation: "pulse 1.5s infinite" }}>
                    <h4 style={{ color: "#ff5722", fontSize: "1.5rem" }}>🚀 Flash Sale - Domains from £0.99! 🚀</h4>
                </Card>
            }
            <DomainSearch domainSearched={(domain) => { this.domainSearched(domain) }} buyDomain={(data) => this.processBuyDomain(data)} exchangeRate={this.props.exchangeRate} sale={this.state.sale} OfferMinus={this.state.OfferMinus} sale={this.state.sale} />
            <DomainReccomendations domainSearched={this.state.domainSearched} buyDomain={(data) => this.processBuyDomain(data)} exchangeRate={this.props.exchangeRate} sale={this.state.sale} OfferMinus={this.state.OfferMinus} sale={this.state.sale} />


            <Modal width="1000px" show={this.state.showBuy} title={<h1>Buy A Domain</h1>} content={buyContents} close={this.closeBuy} />

        </div>);
    }
}

function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user,
        basket: state.basket
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyDomain);

