import React, { Component } from 'react';
import moment from 'moment'
import { Card, Table, Button, Row, Col } from 'react-bootstrap'
import Modal from "../../components/modal"
import Axios from 'axios';

class AdminIpHistory extends Component {
    state = {
        showIpHistory: false,
        views: []

    }
    componentDidUpdate(oldprops) {
        if (oldprops.showIpHistory == false && this.props.showIpHistory || oldprops.showIpHistory == null && this.props.showIpHistory) {
            this.getData()

        }
    }
    getData = () => {
        Axios.post("/api/stats/ip/history/by/ip", { ip: this.props.botSelectedToCheck }).then(resp => {
            this.setState({ views: resp.data.views })
        })
    }

    render() {
        let views = []
        this.state.views.forEach((view, index)=>{
            views.push(
                <tr key={index}>
                    <td>{moment.unix(view.date).format('DD-MM-YYYY@HH:mm')}</td>
                    <td>{view.site}</td>
                    <td>{view.referer}</td>
                    <td >{view.ip}</td>
                </tr>
            )
        })
        return (
            <div>
                <Modal show={this.props.showIpHistory} title={this.props.botSelectedToCheck + " History"} close={this.props.closeIpHistory} content={
                          <div style={{ 'margin': '0px' }}>
                          <Table style={{ 'margin': '0px' }} bordered>
                              <thead className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                                  <tr>
                                      <th>Date</th>
                                      <th>Page</th>
                                      <th>Referer</th>
                                      <th>IP</th>
                                  </tr>
                              </thead>
                              <tbody className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                                  {views}
                              </tbody>
                          </Table>
                      </div>
                }>


                </Modal>
          
            </div>
        );
    }
}

export default AdminIpHistory;