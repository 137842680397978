import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { connect } from 'react-redux';
import { Spinner, Row, Col, Card } from 'react-bootstrap'

class DomainNavbar extends Component {
    state = {  
        buyHovered: false,
        myHovered: false
    } 
    render() { 
        let darkmode = this.props.user.darkmode
        let buyHovered = this.state.buyHovered || window.location.pathname == "/domains/buy"
        let myHovered = this.state.myHovered || window.location.pathname == "/domains/my-domains"
        return (
            <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ width: "100%", height: "40px", marginTop: "-12px" }}>
            <Row>
                <Col   style={{textAlign: "right"}}>
                    <Nav.Link onMouseEnter={()=>{this.setState({buyHovered: true})}} onMouseLeave={()=>{this.setState({buyHovered: false})}}  style={{color: buyHovered ? "white" : "rgba(255,255,255,.5)"}} as={Link} onClick={this.toggle} to="/domains/buy">Buy a Domain</Nav.Link>

                </Col>
                <Col>
                    <Nav.Link onMouseEnter={()=>{this.setState({myHovered: true})}} onMouseLeave={()=>{this.setState({myHovered: false})}} style={{color: myHovered ? "white" : "rgba(255,255,255,.5)"}} as={Link} onClick={this.toggle} to="/domains/my-domains">My Domains</Nav.Link>

                </Col>
            </Row>

        </div>
        );
    }
}
 
function mapStateToProps(state) {
    return {
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainNavbar);





