import React, { Component } from 'react';

class HostingCard extends Component {
    state = {}
    render() {
        return (
            <div style={{ height: this.props.detailed ? "" : '200px', width: '400px', margin: 'auto', marginTop: '20px' }}>
                {this.props.children}
            </div>
        );
    }
}

export default HostingCard;