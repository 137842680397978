import React, { Component } from 'react';
import {Button, Modal} from 'react-bootstrap'
import Axios from 'axios'
import { connect } from 'react-redux';
import {logoutUser} from '../../actions/userActions'
class DeleteAccount extends Component {
    state = {  }
    deleteAccount = () => {
        Axios.get('/api/user/delete')
        this.props.logoutUser()
    }
    render() { 
        return ( 
            <Modal onHide={this.props.closeDeleteModal} show={this.props.show}>
                    <Modal.Header>
                        <Modal.Title>
                            Delete Account
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete your Account?</p>
                        <p>All data associated to your account and hosting plans will be irreversibly deleted. </p>
                    </Modal.Body>
                    <div>
                    <hr/>

                    </div>
                    <div style={{width: '100%', padding: '20px', paddingTop: '0px'}}>
                            <Button style={{float: 'right', background:'red', borderColor: 'red'}} onClick={this.deleteAccount}>Delete Account</Button>    
                            <Button style={{float: 'left'}} onClick={this.props.closeDeleteModal}>Cancel</Button>    
                        
                    </div>
                </Modal>
         );
    }
}

function mapDispatchToProps(dispatch){
    return{
        logoutUser: ()=>{dispatch(logoutUser())}
    }
  }
  
export default connect(null, mapDispatchToProps)(DeleteAccount);
