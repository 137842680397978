import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AddToBasket from "../components/basket/addToBasket"
import StripeContainerRecurring from '../components/stripe/stripeContainerRecurring'

class CheckoutFooter extends Component {
    state = {
    }

    render() {
        let prices = { Basic: '£1/Month', Standard: '£2/Month', Advanced: '£4/Month' }

            return (<div className='checkout-footer' style={{zIndex: 1}}>
               Plan: {this.props.selectedPlan ? this.props.selectedPlan + ' - ' + prices[this.props.selectedPlan] : "None - Click on a plan to select."}
               <StripeContainerRecurring disabled={!this.props.SelectedId} priceId={this.props.SelectedId} value={this.props.valueSelected} />
                <AddToBasket type="hosting" name={this.props.selectedPlan} priceId={this.props.SelectedId} disabled={!this.props.SelectedId}/>

            </div>);
        
       
    }
}

function mapStateToProps(state) {
    return {
        basket: state.basket
    }
}
function mapDispatchToProps(dispatch) {
    return {


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutFooter);