import React, { Component } from 'react';
import Axios from 'axios';
import {Card, Row, Col, Button, Form} from 'react-bootstrap'
class ForgotPassword extends Component {
    state = { 
        email: '',
        errorString: '',
        success: false
     }
    updateValue = (e, type) => {
        this.setState({[type]: e.target.value})
    }
    resetPassword=()=>{
        Axios.post('/api/user/forgot-password', {email: this.state.email}).then(res=>{
            this.setState({errorString: res.data.errorString, success: res.data.success})
        })
    }
    
    render() { 
        return ( 
                <div style={{width: '100%', paddingTop: '40px'}}>
                    <Card style={{width: '80%',margin: 'auto'}}>
                        <Card.Header className="darkmode-card-header" style={{textAlign: 'center'}}>
                            <h1>Forgot Password</h1>
                        </Card.Header>
                        <Card.Text className="darkmode-card-content" style={{textAlign: 'center', marginBottom: "-10px"}}>
                            <Row style={{padding: '20px'}}>
                                <Col xs={4}>
                                <label>Email</label>
                                </Col>
                                <Col xs={6}>
                                    <Form.Control type='text' placeholder='Enter Email' onChange={(e)=>this.updateValue(e,'email')} />
                                </Col>
                                
                            </Row>
                            <Row style={{padding: '20px'}}>
                                <Col>
                                <p>{this.state.errorString}</p>
                                {this.state.success && <p>You have been sent an email with instructions on how to reset your password.</p>}

                                </Col>

                            </Row>
                        </Card.Text>
                        <Card.Footer className="darkmode-card-header" >
                            <Row>
                                <Col >
                                <Button style={{float: 'right',backgroundColor: '#343a40'}}  type='submit'  onClick={this.resetPassword}> Reset Password</Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>                    
                </div>
         );
    }
}
 
export default ForgotPassword;