import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import axios from 'axios'
import { setMarketingPreferences } from '../../actions/userActions'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import { setUser } from '../../actions/userActions'

class AccountUserInterface extends Component {
    state = {
        darkmode: false
    }
    componentDidMount(){
        axios.get("/api/user/isset/darkmode").then(res=>{
            this.setState({darkmode: res.data.darkmode})
        })

    }
    handleDarkMode = (e) => {
        e.persist()
        this.setState({darkmode: !this.state.darkmode},()=>{
            axios.post("/api/user/set/darkmode", {enable: this.state.darkmode}).then((req)=>{
                axios.defaults.headers.common['x-auth-token'] = localStorage.jwtToken
                req.data.user.darkmode = this.state.darkmode
                this.props.setUser({ user: req.data.user, token: localStorage.jwtToken })

            })
        })
    }
    render() {
let darkmode = this.props.user.darkmode
        return (
            <div >
                <Card   style={{ width: '90%', margin: 'auto', display: 'block', marginTop: '60px', marginBottom: '60px' }}>
                    <Card.Header className={darkmode? "darkmode-card-header" :"lightmode-card-header"} style={{ textAlign: 'center' }}>
                        <Row >
                            <Button as={Link} to='/account' style={{ marginLeft: '20px', backgroundColor: 'rgb(0, 123, 255)' }}><FontAwesomeIcon size='2x' icon={faArrowLeft} /></Button>
                            <h3 style={{ textAlign: 'center', margin: 'auto' }}>User Interface</h3>

                        </Row>
                    </Card.Header>
                    <Card.Text className={darkmode? "darkmode-card-content" :"lightmode-card-content" } style={{ textAlign: 'center' }}>

                        <Row style={{ padding: '20px' }}>
                            <Col>
                            <p style={{ textAlign: 'left', paddingLeft: '40px' }}>Dark Mode</p>
                            </Col>
                            <Col style={{ textAlign: 'center' }} xs={4}>
                                <Form.Control checked={this.state.darkmode} onClick={(e) => this.handleDarkMode(e)} type="checkbox" />
                            </Col>
                        </Row>


                    </Card.Text>
                </Card>



            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setUser: (data) => { dispatch(setUser(data)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountUserInterface);
