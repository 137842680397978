import React, { Component } from 'react';
import { Spinner, Row, Col, Card } from 'react-bootstrap'
import _ from 'lodash'
import Axios from "axios"
import { connect } from 'react-redux';
class domainReccomendations extends Component {
    state = {
        availableDomains: [],
        prices: [],
        searchedDomain: {},
        domainSearched: "",
        showBuy: false,
        domainToBuy: "",
        searching: false,
        domainToBuyOneYearPriceDollar: null,
        domainToBuyOneYearPricePoundOffer: null,
        priceLoading: true,
        buyLoading: false

    }
    componentDidMount() {
        this.getSavedDomains()

    }
    buydomain = (data) => {
        this.props.buyDomain(data)
    }
    genDomainRow = (ava, disableOffer) => {
        //seperate tld
        let domaintldsplt = ava.DomainName.split(".")
        let domaintld = ""
        let i = 0
        for (const d of domaintldsplt) {

            if (i > 0) {
                if (i > 1) {
                    domaintld = domaintld + "."
                }
                domaintld = domaintld + d
            }
            i = i + 1
        }
        //get the price
        let actprice = 0
        let offerMinus = null
        for (const price of this.state.prices) {
            if (price.Name == domaintld) {
                actprice = price.RegistrationPrice
                if (!disableOffer && this.props.sale) {
                    offerMinus = this.props.OfferMinus
                }
            }
        }
        let priceRow = <div></div>
        let offerPrice = null
        if(this.state.priceLoading){
            priceRow = <div><Spinner animation="border" vaiant="secondary" style={{ width: '50px', height: '50px', fontSize: '30px' }} /></div>

        }else{
            if (offerMinus) {
                offerPrice = ((actprice * this.props.exchangeRate) - offerMinus).toFixed(2)
                if (offerPrice < 0.99) {
                    offerPrice = 0.99
                }
                priceRow = <div>£{offerPrice}     <strike> £{(actprice * this.props.exchangeRate).toFixed(2)}</strike> </div>
            } else {
                priceRow = <div> £{(actprice * this.props.exchangeRate).toFixed(2)} (${actprice})</div>
            }
        }

        //create html
        return (<Row style={{ margin: "20px" }}>
            <Col style={{ textAlign: "center", margin: "auto" }}>{ava.DomainName}</Col>
            <Col style={{ textAlign: "center", margin: "auto" }}>
                <Row>{priceRow}</Row>
                {!this.state.priceLoading && offerMinus && <Row><p style={{ fontSize: "10px" }}>For the first year, then £{(actprice * this.props.exchangeRate).toFixed(2)} (${actprice}) per year</p></Row>}
            </Col>
            <Col style={{ textAlign: "center", margin: "auto" }}>
                {
                    ava.Availability == "AVAILABLE" ?

                        <button disabled={this.state.buyLoading} onClick={() => this.buydomain({ showBuy: true, domainToBuy: ava.DomainName, domainToBuyOneYearPriceDollar: actprice, domainToBuyOneYearPricePoundOffer: offerPrice })} style={{ background: "green", width: "140px" }} className="button">
                            {this.state.buyLoading ? 
                            <Spinner animation="border" vaiant="secondary" style={{ width: '50px', height: '50px', fontSize: '30px' }} />
                            : <p>Buy</p>}
                            </button>
                        :
                        <button disabled={true} style={{ background: "red", width: "140px" }} className="button">Not Available</button>
                }
            </Col>
        </Row>
        )
    }
    checkAvailable=()=>{
        Axios.post("/api/domains/check/saved/available").then(res => {

            this.setState({buyLoading: false, availableDomains: res.data.domains,})
        })
        
    }
    getSavedDomains = () => {
        this.setState({ searching: true, buyLoading: true }, () => {
            Axios.post("/api/domains/get/saved").then(res => {
                if (res.data.success) {
                    if (res.data.domains[0]) {
                        this.setState({ availableDomains: res.data.domains, searching: false }, () => {
                            this.getPrices()
                            this.checkAvailable()

                        })
                    } else {
                        let subUsername = this.props.user.email.split("@")[0];
                        subUsername = subUsername.replace(/\./g, "-");
                        subUsername = subUsername.replace(/_/g, "-");
                        subUsername = subUsername + ".com"
                        Axios.post("/api/domains/get/suggestions", { domain: subUsername }).then(res => {
                            this.setState({ availableDomains: res.data.rec_domains, domainSearched: res.data.domainSearched, searching: false }, () => {
                                this.getPrices()
                            })

                        })
                    }

                }
            })
        })
    }

    getPrices = () => {
        this.setState({priceLoading: true},()=>{
            let tlds = []
            for (const domain of this.state.availableDomains) {
                tlds.push(domain.DomainName)
    
            }
            if (this.state.domainSearched) {
                tlds.push(this.state.domainSearched)
            }
            Axios.post("/api/domains/get/tld/prices", { domains: tlds }).then(res => {
                this.setState({ prices: res.data.price, priceLoading: false })
    
            })
        })
     
    }
    searchDomains = (domain, first) => {
        this.setState({ availableDomains: [], searchedDomain: [], searching: true, buyLoading: false })
        let spl = domain.split(".")

        var subarray = spl.slice(1);
        let joined = subarray.join(".")

        Axios.post("/api/domains/get/suggestions", { domain: domain }).then(res => {
            if (res.data.domainSearched == this.state.domainSearched) {
                this.setState({ availableDomains: res.data.rec_domains, domainSearched: res.data.domainSearched, searching: false }, () => {
                    this.getPrices()
                })
            }

        })

    }
    componentDidUpdate = () => {
        if (this.state.domainSearched != this.props.domainSearched) {
            this.setState({ domainSearched: this.props.domainSearched }, () => {
                if (!this.props.domainSearched) {
                    this.getSavedDomains()
                } else {
                    this.searchDomains(this.props.domainSearched)

                }
            })
        }
    }
    render() {
        let recommended_domains = []
        let offerInBasket = false
        let darkmode = this.props.user.darkmode

        this.props.basket.forEach(it => {
            if (it.offer) {
                offerInBasket = true
            }
        })
        if (this.state.availableDomains && this.state.availableDomains[0]) {
            this.state.availableDomains.forEach((ava, i) => {

                let html = this.genDomainRow(ava, offerInBasket)
                recommended_domains.push(html)
                if (i < this.state.availableDomains.length) {
                    recommended_domains.push(<hr />)
                }
            })
        }
        return (<div>

            <Card style={{ margin: "30px" }}>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ maxWidth: '100%' }} >
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                        <h4 >Recommended Domains</h4>
                    </Card.Header>
                    {recommended_domains}
                    {
                        this.state.searching && <div>
                            <Row>
                                <Col style={{ margin: "auto", textAlign: "center", margin: "20px" }}>
                                    <Spinner animation="border" vaiant="secondary" style={{ width: '50px', height: '50px', fontSize: '30px' }} />
                                </Col>

                            </Row>
                            <Row>
                                <Col style={{ margin: "auto", textAlign: "center", margin: "20px" }}>
                                    <p>Searching...</p>

                                </Col>

                            </Row></div>

                    }



                </div>
                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                </Card.Footer>
            </Card>
        </div>);
    }
}

function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user,
        basket: state.basket
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(domainReccomendations);

