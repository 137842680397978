import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addBasketItem } from '../../actions/basketActions'
import {Button} from "react-bootstrap"
class AddToBasket extends Component {
    state = {}
    handleClick = () => {
        let basketItem = {}
        if (this.props.type == "hosting") {
            basketItem = {
                type: this.props.type,
                // productId: this.props.productId,
                priceId: this.props.priceId,
                name: this.props.name,
                amount: 1,
            }
        } else if (this.props.type == "domain") {
            basketItem = {
                domain: this.props.domain,
                type: this.props.type,
                priceDollarPerYear: this.props.priceDollarPerYear,
                amount: 1,
                years: this.props.years,
                contactsAndPrivacy: this.props.contactsAndPrivacy,
                autoRenew: this.props.autoRenew,
                adminContactValid: this.props.adminContactValid,
                techContactValid: this.props.techContactValid,
                registrationContactValid: this.props.registrationContactValid,
                offer: this.props.offerPrice ? this.props.offerPrice : null

            }
        } else {
            return
        }

        this.props.addBasketItem(basketItem)
        if(this.props.close){
            this.props.close()
        }
    }
    render() {
        let style = {}
        if(this.props.icon){
            style={}
        }else{
            style = {
                 background: "green", width: "200px", margin: "20px" 
            }
        }
        return (
            <div>
                <Button disabled={this.props.disabled} style={style} role='link' onClick={this.handleClick} >
                     {this.props.icon ? this.props.icon : "Add To Basket"}
                     </Button>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        addBasketItem: (data) => { dispatch(addBasketItem(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToBasket);
