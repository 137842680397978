import React, { Component } from 'react';
import {Button, Form, Row, Col, Card} from 'react-bootstrap'
import axios from 'axios'
import _ from 'lodash'
import { connect } from 'react-redux';
import { renderToString } from 'react-dom/server'

class EmailNewsletter extends Component {
    state = { 
        newsletterDate: null,
        topicTitles: [],
        topicContents: [],
        showPreview: false,
        emailTemplate: '',
        newsletterTitle: '',
        issueCount: 0,
        saveEnabled: false,
        approved: 'false',
        sent: false,
        file: null,
        banner: '',
        bannerDisabled: true,
        sentPrompt: '',
        sentPromptSingle: '',

     }
     componentDidMount = () => {
         this.getTemplates()
         this.getNewsletter()
     }
     
     showPreviewChange=()=>{
        this.setState({showPreview: !this.state.showPreview})
    }
    getNewsletter=()=>{
        axios.get('/api/email/newsletter/get').then((res,err)=>{
            axios.get('/api/email/newsletter/count').then((ress,err)=>{
                this.setState({newsletterDate:res.data.newsletter.date , topicTitles:res.data.newsletter.titles, topicContents: res.data.newsletter.content,newsletterTitle: res.data.newsletter.newsletterTitle, approved: res.data.newsletter.approved, issueCount: ress.data.count, banner: res.data.newsletter.banner})
            })
        })
    }
    getTemplates = () => {
        axios.get('/api/email/templates').then((res,err)=>{
            const test = _.cloneDeep(res.data.templates.newsletter)
            this.setState({emailTemplate: test})
        })    
    }
    updateValue = (e,field,i) => {
        if(field === 'newsletterDate' || field === 'newsletterTitle'){
        this.setState({[field]: e.target.value})
        }else{
            let newContent = this.state[field]
            newContent[i] = e.target.value
            this.setState({[field]: newContent})
        }
        this.setState({saveEnabled: true})
    }
    save=()=>{
        axios.post('/api/email/newsletter/save',{date: this.state.newsletterDate, titles: this.state.topicTitles, content: this.state.topicContents, newsletterTitle: this.state.newsletterTitle, approved: false}) 
        this.setState({saveEnabled: false, approved: 'false'})
    }  
    approve=()=>{
        axios.get('/api/email/newsletter/approve') 
        this.setState({approved: 'true'})
    }  
    send=()=>{
        this.setState({sent: true})
        axios.get('/api/email/newsletter/send').then(()=>this.setState({sentPrompt: 'sent'}))
    }
    openFileSelector = async(e) => {
        this.setState({file: e.target.files[0], bannerDisabled: false})
    }
    uploadFile =async ()=>{
        const formData = new FormData()
        formData.append('file', this.state.file)
        this.setState({bannerDisabled: true})
        axios.post('/api/email/newsletter/banner/upload',formData).then((res)=>this.setState({approved:'false', banner: res.data.banner})).catch(err=>{})
    }
    sendTest=()=>{
        axios.get('/api/email/newsletter/test').then(()=>this.setState({sentPromptSingle: 'sent'}))
        setInterval(()=>{this.setState({sentPromptSingle: ''})}, 3000);
    }
    render() { 
        let darkmode = this.props.user.darkmode

        let approveDisabled = this.props.user.email !== 'thomas.breeze@icloud.com'
        let sendDisabled = true
        if(this.props.user.email === 'thomas.breeze@icloud.com'){
            sendDisabled = false
        }if(this.state.approved === 'false'){
            sendDisabled = true
        }if(this.state.sent){
            sendDisabled = true
        }

        let contentRows = []
        for(let i= 0; i < this.state.topicTitles.length +1; i++){
            contentRows.push(
                <div>
                    <Row>
                                <Col xs={4}>
                                Topic Name
                                </Col>
                                <Col>
                                <Form.Control  as='textarea' value={this.state.topicTitles[i]} placeholder='Title' onChange={(e)=>this.updateValue(e,'topicTitles',i)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                Topic Content
                                </Col>
                                <Col>
                                <Form.Control as='textarea' value={this.state.topicContents[i]} placeholder='Content' onChange={(e)=>this.updateValue(e,'topicContents',i)} />
                                </Col>
                            </Row>
                </div>
            )
        };
        let newsletterContent = this.state.emailTemplate.replace(/\$date/g, this.state.newsletterDate)
        let titlesHtml = ''
        for (let title in this.state.topicTitles){titlesHtml = titlesHtml + `<h5 class='topictitle'>${this.state.topicTitles[title]}</h5>`}
        let titlePlusContent = ''
        for (let title in this.state.topicTitles){titlePlusContent = titlePlusContent + `<h5 class='contenttitle'>${this.state.topicTitles[title]}</h5><p class='topiccontent'>${this.state.topicContents[title]}</p>`}
        newsletterContent = newsletterContent.replace(/\$titles/g, titlesHtml)
        newsletterContent = newsletterContent.replace(/\$content/g, titlePlusContent)
        newsletterContent = newsletterContent.replace(/\$newslettertitle/g, this.state.newsletterTitle)
        newsletterContent = newsletterContent.replace(/\$issue/g, this.state.issueCount)
        newsletterContent = newsletterContent.replace(/\$src/g, `src="https://hosting.breezelabs.co.uk/images/logo.png"`)

        return ( <div >
            <Card style={{ margin: '30px'}}>
                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{textAlign: 'center'}}>
                    Email Newsletter
                </Card.Header>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{maxWidth: '100%'}} >
                            <Row>
                            <Col xs={4}>
                                Date

                                </Col>
                                <Col>
                                <Form.Control as='textarea'  value={this.state.newsletterDate} placeholder='e.g. JAN. 2021' onChange={(e)=>this.updateValue(e,'newsletterDate')} />

                                </Col>

                            </Row>
                            <Row>
                            <Col xs={4}>
                                Newsletter Title

                                </Col>
                                {this.state.banner && <Col>
                                {this.state.banner}
</Col>}
                                <Col>
                                <input  onChange={(e)=>this.openFileSelector(e)} type='file'></input>
                                <Button disabled={this.state.bannerDisabled} onClick={this.uploadFile}>upload</Button>

                                </Col>

                            </Row>
                            <Row>
                            <Col xs={4}>
                                Newsletter Banner

                                </Col>
                                <Col>
                                <Form.Control as='textarea' value={this.state.newsletterTitle} placeholder='e.g. Must read' onChange={(e)=>this.updateValue(e,'newsletterTitle')} />

                                </Col>

                            </Row>
                            {contentRows}
                            
                            
                            <Row>
                                        <Col >Show Preview</Col>
                                        <Col ><Form.Control style={{float: 'left'}} checked={this.state.showPreview}  onClick={this.showPreviewChange} type="checkbox"/></Col>

                                    </Row>
                                    <hr/>
                            <Row style={{width: '100%', margin: 'auto'}}>

                                <div style={{maxWidth: '100%', width: '100%', margin: 'auto', }}>
                                    


                            {this.state.showPreview && <div  dangerouslySetInnerHTML={{__html: newsletterContent}}/>}
                            </div>
                            </Row>
                            <Button style={{backgroundColor: '#343a40', width: '100%'}} disabled={!this.state.saveEnabled} onClick={this.save}>Save</Button>

                        <Button style={{backgroundColor: '#343a40', width: '100%'}} onClick={this.sendTest}>Send test to me</Button>{this.state.sentPromptSingle && this.state.sentPromptSingle}
                        <Button style={this.state.approved === 'true' ? {backgroundColor: 'green', width: '100%'}:{backgroundColor: 'red', width: '100%'}} disabled={approveDisabled} onClick={this.approve}>Approve</Button>
                        <Button style={{backgroundColor: '#343a40', width: '100%'}} disabled={sendDisabled} onClick={this.send}>Send</Button>{this.state.sentPrompt && this.state.sentPrompt}

                </div>
                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} > 
                </Card.Footer>
            </Card>       
        </div>);
    }
}
function mapStateToProps(state){
    return{
        user: state.user
    }
}
export default connect(mapStateToProps)(EmailNewsletter);