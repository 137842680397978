import React, { Component } from 'react';
import {Navbar, Col, Row} from 'react-bootstrap'
import PrivacyPolicy from './privacyPolicy';

class BottomNav extends Component {
    state = { 
        showPolicy: false
     }
     closePolicy=()=>{
        this.setState({showPolicy: false})
    }
    openPolicy=()=>{
       this.setState({showPolicy: true})
   }
    render() { 
        return (
            <div style={{textAlign: 'center'}}>
                <Navbar bg="dark" variant="dark" fixed='bottom'>
                    <Row style={{width: "100%"}}>
                        <Col xs={4} style={{margin:'auto'}}>
                        <p style={{color: 'white', margin: 'auto'}}>&copy; 2021 BreezeLabs. All Rights Reserved.</p>

                        </Col>
                        <Col xs={4} style={{margin:'auto'}}>
                            <a target='_blank' style={{margin: 'auto', padding:'5px'}} href='https://twitter.com/breezelabsuk'><img alt='twitter'  style={{width: '20px'}} src={require('../twitter.png')}></img></a>
                            <a  target='_blank' style={{margin: 'auto', padding:'5px'}} href='https://facebook.com/BreezeLabs-103161455084732'><img alt='facebook' style={{width: '20px'}} src={require('../facebook.png')}></img></a>
                        </Col>
                        <Col xs={4} style={{margin:'auto'}}>
                        <a style={{cursor: 'pointer', margin:'auto'}} onClick={this.openPolicy}><p style={{color: 'white', margin:'auto'}}>Privacy Policy</p></a>

                        </Col>
                    </Row>

                    <PrivacyPolicy showPolicy={this.state.showPolicy} closePolicy={this.closePolicy} openPolicy={this.openPolicy}/>      

                </Navbar>
            </div>
        );
    }
}

  
export default BottomNav;
