import React, { Component } from 'react';
import { Form, Button, Card, Col, Row } from 'react-bootstrap';
import axios from 'axios'
import { connect } from 'react-redux';
import { setUser } from '../actions/userActions'
import PrivacyPolicy from '../components/privacyPolicy'
import { Link } from 'react-router-dom';
import HostingAbout from '../components/hostingAbout'
class CreateAccount extends Component {
    state = {
        email: '',
        password: '',
        errorString: '',
        confirmEmail: '',
        emailValid: false,
        emailSame: true,
        passwordValid: false,
        passwordSame: true,
        confirmPassword: '',
        showPolicy: false,
        policy: false,
        policyInvalid: false,
        marketingPreferences: { email: false, phone: false, text: false },
        showConfirmEmail: false,
        showConfirmPassword: false,
    }

    handleForm = () => {

        var user = {
            email: this.state.email,
            password: this.state.password,
            marketingPreferences: this.state.marketingPreferences
        }
        let { valid, invalid } = this.validateAll()
        if (valid) {
            axios.post('/api/user/create', user).then(res => {
                if (res.data.success) {

                    this.props.setUser({ user: res.data.user, token: res.data.token })
                    window.gtag_report_conversion_sign_up()
                } else {
                    this.setState({ errorString: res.data.error })
                }
            })
        } else {
            this.setState({ policyInvalid: invalid.policyInvalid, passwordSame: invalid.passwordSame, passwordValid: invalid.passwordValid, emailValid: invalid.emailValid, emailSame: invalid.emailSame })
        }
    }
    validateAll = () => {
        let valid = true
        let invalid = {}
        let emailValidity = this.validateEmailOnly()
        let passwordValidity = this.validatePasswordOnly()
        valid = emailValidity.valid && passwordValidity.valid && this.state.policy
        invalid = { policyInvalid: !this.state.policy, ...passwordValidity.invalid, ...emailValidity.invalid }
        return { valid: valid, invalid: invalid }
    }
    validateEmailOnly = () => {
        let valid = true
        let invalid = {}
        if (!this.validateEmail(this.state.email)) {
            valid = false
            invalid.emailValid = true
        } else {
            invalid.emailValid = false
        }
        if (this.state.email !== this.state.confirmEmail) {
            valid = false
            invalid.emailSame = true
        } else {
            invalid.emailSame = false
        }
        return { valid: valid, invalid: invalid }
    }
    validatePasswordOnly = () => {
        let valid = true
        let invalid = {}
        if (!this.validatePassword(this.state.password)) {
            valid = false
            invalid.passwordValid = true
        } else {
            invalid.passwordValid = false
        }
        if (this.state.password !== this.state.confirmPassword) {
            valid = false
            invalid.passwordSame = true
        } else {
            invalid.passwordSame = false
        }
        return { valid: valid, invalid: invalid }
    }
    handleChange = (e, type) => {
        let showConfirm = type === 'password' ? { showConfirmPassword: true } : type === 'email' ? { showConfirmEmail: true } : null
        this.setState({ [type]: e.target.value, ...showConfirm }, async () => {
            let validity
            if (type === 'email' || type === 'confirmEmail') {
                validity = await this.validateEmailOnly()
            }
            if (type === 'password' || type === 'confirmPassword') {
                validity = await this.validatePasswordOnly()
            }
            this.setState({ ...validity.invalid })

        })
    }
    validatePassword = (password) => {
        const re = /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
        return re.test(String(password));
    }
    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    closePolicy = () => {
        this.setState({ showPolicy: false })
    }
    openPolicy = () => {
        this.setState({ showPolicy: true })
    }
    handlePolicy = () => {
        this.setState({ policy: !this.state.policy })
    }
    handleMarketing = (type) => {
        let marketingPreferences = this.state.marketingPreferences
        marketingPreferences[type] = !marketingPreferences[type]
        this.setState({ marketingPreferences: marketingPreferences })
    }
    render() {

        return (

            <div style={{ width: '100%', marginTop: '60px', marginBottom: '150px',  }}>
                <div>
                    <Card style={{ width: '80%', margin: 'auto', maxWidth: '1000px' }}>
                        <Card.Header className="darkmode-card-header" style={{ textAlign: 'center' }}>
                            <h1>Create Account</h1>
                        </Card.Header>
                        <Card.Text className="darkmode-card-content" style={{marginBottom: "0px"}}>

                            <Row style={{ padding: '20px' }}>
                                <Col xs={4}>
                                    <label style={{ paddingLeft: '40px' }}>Email:</label>
                                </Col>
                                <Col style={{ minWidth: '200px', margin: 'auto' }} xs={6}>
                                    <Form.Control onChange={(e) => this.handleChange(e, 'email')} type="text" placeholder="Enter Email" />
                                </Col>

                            </Row>
                            {this.state.emailValid &&
                                <Row >
                                    <Col>
                                        <p style={{ paddingLeft: '60px', color: 'red' }}>Email not valid</p>
                                    </Col>
                                </Row>
                            }

                            {this.state.showConfirmEmail &&
                                <div>
                                    <Row style={{ padding: '20px' }}>
                                        <Col xs={4} style={{ minWidth: '120px' }}>
                                            <label style={{ paddingLeft: '40px', minWidth: '150px' }}>Confirm Email:</label>
                                        </Col>
                                        <Col style={{ minWidth: '200px', margin: 'auto' }} xs={6}>
                                            <Form.Control onChange={(e) => this.handleChange(e, 'confirmEmail')} type="text" placeholder="Confirm Email" />
                                        </Col>

                                    </Row>
                                    {this.state.emailSame && !this.state.emailValid &&
                                        <Row >
                                            <Col>
                                                <p style={{ paddingLeft: '60px', color: 'red' }}>Emails are not the same</p>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }

                            <hr />

                            <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '5px' }}>
                                <Col style={{ minWidth: '120px' }} xs={4}>
                                    <label style={{ paddingLeft: '40px' }}>Password:</label>

                                </Col>
                                <Col style={{ minWidth: '200px', margin: 'auto' }} xs={6}>
                                    <Form.Control onChange={(e) => this.handleChange(e, 'password')} type="password" placeholder="Enter Password" />


                                </Col>

                            </Row>
                            {this.state.passwordValid &&
                                <Row >
                                    <Col>
                                        <p style={{ paddingLeft: '60px', paddingRight: '60px', color: 'red' }}>Password must contain at least one uppercase, lowercase, number and be 8 characters min</p>
                                    </Col>
                                </Row>
                            }

                            {this.state.showConfirmPassword &&
                                <div>
                                    <Row style={{ padding: '20px' }}>
                                        <Col style={{ minWidth: '120px' }} xs={4}>
                                            <label style={{ paddingLeft: '40px', minWidth: '180px' }}>Confirm Password:</label>

                                        </Col>
                                        <Col style={{ minWidth: '200px', margin: 'auto' }} xs={6}>
                                            <Form.Control onChange={(e) => this.handleChange(e, 'confirmPassword')} type="password" placeholder="Confirm Password" />

                                        </Col>

                                    </Row>
                                    {this.state.passwordSame && !this.state.passwordValid &&
                                        <Row >
                                            <Col>
                                                <p style={{ paddingLeft: '60px', color: 'red' }}>Passwords not the same</p>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }

                            {!this.state.passwordSame && !this.state.passwordValid && !this.state.emailValid && !this.state.emailSame &&
                                <div>
                                    <hr />
                                    <Row style={{ paddingTop: '20px', paddingLeft: '40px', paddingRight: '40px', textAlign: 'center' }}>
                                        <p>From time to time we would like to contact you with details of other products, offers, services and competitions we provide. If you consent to us contacting you for this purpose please tick to say how you would like us to contact you. You can change this at any time in your account settings.</p>
                                    </Row>
                                    <Row style={{ padding: '20px' }}>
                                        <Col style={{ textAlign: 'center' }} xs={4}>
                                            <p>Email</p>
                                            <Form.Control style={{ minWidth: '20px' }} checked={this.state.marketingPreferences.email} onClick={() => this.handleMarketing('email')} type="checkbox" />
                                        </Col>
                                        <Col style={{ textAlign: 'center' }} xs={4}>
                                            <p>Phone</p>
                                            <Form.Control style={{ minWidth: '20px' }} checked={this.state.marketingPreferences.phone} onClick={() => this.handleMarketing('phone')} type="checkbox" />
                                        </Col>
                                        <Col style={{ textAlign: 'center' }} xs={4}>
                                            <p>Text</p>
                                            <Form.Control style={{ minWidth: '20px' }} checked={this.state.marketingPreferences.text} onClick={() => this.handleMarketing('text')} type="checkbox" />
                                        </Col>

                                    </Row>
                                    <hr />

                                    <Row style={{ padding: '20px' }}>
                                        <Col xs={8} style={{ textAlign: 'center' }}>
                                            <label>I accept <a onClick={this.openPolicy}><p style={{ color: 'blue' }} >BreezeLab's Privacy Policy</p></a></label>
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Control style={{ minWidth: '50px' }} onClick={this.handlePolicy} checked={this.state.policy} type="checkbox" />

                                        </Col>

                                    </Row>
                                    {this.state.policyInvalid &&
                                        <Row >
                                            <Col>
                                                <p style={{ textAlign: 'center', color: 'red' }}>You must accept our privacy policy</p>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }

                        </Card.Text>
                        <Card.Footer className="darkmode-card-header">
                            <div style={{ margin: 'auto', textAlign: 'center' }}>
                                {this.state.errorString}
                                <Button style={{ backgroundColor: 'rgb(0, 123, 255)', height: '100%', width: '80%', margin: 'auto' }} onClick={this.handleForm} >Create account</Button>
                            </div>
                            <Row>
                                <Col style={{margin: '10px', textAlign: "center"}}>
                                    <Link style={{width: '200px'}}  type='submit'  to='/login'> <u>Already have an account? Log in</u></Link>
                                </Col>
                                <Col style={{margin: '10px', textAlign: "center"}}>
                                    <a style={{width: '200px'}}   href='https://breezelabs.co.uk/hosting'> <u>Learn more about our hosting</u></a>
                                </Col>
                                </Row>
                        </Card.Footer>
                    </Card>
                    <PrivacyPolicy showPolicy={this.state.showPolicy} closePolicy={this.closePolicy} openPolicy={this.openPolicy} />
                </div>



            </div>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setUser: (data) => { dispatch(setUser(data)) }
    }
}
export default connect(null, mapDispatchToProps)(CreateAccount);
