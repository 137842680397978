import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Row, Col, Button, Form, Card } from 'react-bootstrap';
import {Link} from "react-router-dom"

import axios from 'axios'
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
class AccountDetails extends Component {
    state = { 
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        updateDisabled: true,
        passwordUpdateMessage: null,
        errorMsgs: []
     }
    textChange=(e, type)=>{
        this.setState({[type]: e.target.value},async ()=>{
            let passwordValid = await this.validatePassword(this.state.newPassword)
            let newEqual = this.state.newPassword === this.state.confirmPassword
            let updateEnabled = this.state.currentPassword  && newEqual && passwordValid ? false : true
            this.setState({updateDisabled: updateEnabled, errorMsgs: [!this.state.currentPassword?'Enter current password' : null, !newEqual ? 'New passwords arent the same': null, !passwordValid? 'New Password not valid' : null]})
        })
    }
    validatePassword = (password) => {
        const re = /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
        return re.test(String(password));
    }
    handlePasswordUpdate=()=>{
        axios.post('/api/user/password/update', {currentPassword: this.state.currentPassword, newPassword: this.state.newPassword}).then(res=>{
            this.setState({passwordUpdateMessage: res.data.message})
        })
        setTimeout(()=>{
            this.setState({passwordUpdateMessage: null})
        }, 5000)
    }
    

    render() {
        let passwordErrors = []
        this.state.errorMsgs.forEach((message)=>{
            message &&
            passwordErrors.push( 
                <Col sm='10' style={{textAlign: 'left',paddingLeft:'40px'}}><li>{message}</li></Col>
            )
        })
        let darkmode = this.props.user.darkmode

        return ( 
            <div>
                <Card style={{width: '90%',margin: 'auto', display: 'block', marginTop: '60px', marginBottom: '60px'}}>
                <Card.Header className={darkmode? "darkmode-card-header" :"lightmode-card-header"} style={{textAlign: 'center'}}>
                    <Row >
                            <Button as={Link} to='/account' style={{marginLeft: '20px',backgroundColor: 'rgb(0, 123, 255)'}}><FontAwesomeIcon  size='2x' icon={faArrowLeft}/></Button>
                            <h3 style={{textAlign: 'center', margin: 'auto'}}>Account Details</h3>
                    </Row>
                </Card.Header>
                <Card.Text className={darkmode? "darkmode-card-content" :"lightmode-card-content"} style={{textAlign: 'center', paddingBottom: "20px"}}>
                <Row style={{padding: '20px'}}>
                    <Col sm='2'>Email: </Col>
                    <Col sm='8'>{this.props.user.email}</Col>
                </Row>
                <hr/>
                <Row style={{padding: '20px'}}>
                    <Col sm='10' style={{textAlign: 'left',paddingLeft:'40px'}}>Password (must contain at least one uppercase, lowercase and number and be 8 characters min):</Col>
                </Row>
                <Row style={{padding: '20px',paddingTop:'0px'}}>
                    {passwordErrors}
                </Row>
                <Row style={{margin: '20px',marginTop:'0px'}}>
                    <Col sm='3' style={{marginTop: '5px'}} >
                        <Form.Control type='password' onChange={(e)=>{this.textChange(e,'currentPassword')}} style={{width: '95%', margin: 'auto'}} placeholder='Current password' />
                    </Col>
                    <Col style={{marginTop: '5px'}} sm='3'>
                        <Form.Control type='password' onChange={(e)=>{this.textChange(e,'newPassword')}} style={{width: '95%', margin: 'auto'}} placeholder='New password' />
                    </Col>
                    <Col style={{marginTop: '5px'}} sm='3'>
                        <Form.Control type='password' onChange={(e)=>{this.textChange(e,'confirmPassword')}} style={{width: '95%', margin: 'auto'}} placeholder='Confirm password' />
                    </Col>
                    <Col style={{marginTop: '5px'}} sm='3'>
                            <Button  onClick={this.handlePasswordUpdate} disabled={this.state.updateDisabled} style={{width: '95%', margin: 'auto',backgroundColor: '#343a40'}} type='submit'>Update</Button>
                    </Col>

                </Row>
                
                                   
                </Card.Text>
            </Card>
                

            
            </div>
         );
    }
}
function mapStateToProps(state){
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(AccountDetails);
