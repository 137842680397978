import React, { Component } from 'react';
import {Navbar, Nav} from 'react-bootstrap'
import {Link} from "react-router-dom"
import { connect } from 'react-redux';
import {setUser, logoutUser} from '../actions/userActions'
class TopNav extends Component {
    state = { 
        expanded: false,
     }
    logout=()=>{
        this.props.logoutUser()
        window.location.href = '/'
    }
    toggle=()=>{
        this.setState({expanded: !this.state.expanded})
    }
    render() { 
        let basket_text = ""
        let total_items = 0
        this.props.basket.forEach(item => {
            total_items += parseInt(item.amount)
        });
        if(total_items > 0){
            basket_text = <p style={{color: "green"}}> ({total_items})</p>
        }
        return (
            <div>
                <Navbar onMouseLeave={()=>{this.setState({expanded: false})}} expanded={this.state.expanded} onToggle={this.toggle} expand='md' bg="dark" variant="dark" fixed='top'>
                    <Navbar.Brand href="https://breezelabs.co.uk" ><img alt='logo' style={{width: '150px', paddingTop: '15px'}} src={require('../logo.png')}></img></Navbar.Brand>
                    <div style={{marginRight: 'auto'}}><Navbar.Brand   as={Link} to="/login">Hosting</Navbar.Brand></div>
                    <Navbar.Toggle />
                    <Navbar.Collapse >
                    {this.props.user? 
                    <Nav style={{width: '100%'}} className="justify-content-end">
                        <Nav.Link as={Link} onClick={this.toggle} to="/my-hosting">My Hosting</Nav.Link>
                        <Nav.Link as={Link} onClick={this.toggle}  to="/plans">Plans</Nav.Link>
                        <Nav.Link as={Link} onClick={this.toggle} to="/domains">Domains</Nav.Link>
                        <Nav.Link as={Link} onClick={this.toggle}  to="/account">Account</Nav.Link>
                         <Nav.Link as={Link} to="/basket"><div style={{ display: 'flex', alignItems: 'center' }}> <p style={{marginRight: '5px' }}>Basket</p>{basket_text}</div></Nav.Link>

                        {this.props.user.admin === 'true' && 
                            <Nav.Link onClick={this.toggle}  as={Link} to="/admin">Admin</Nav.Link>

                        }
                        <Nav.Link as={Link} to="/help">Help</Nav.Link>
                        <Nav.Link onClick={()=>{this.logout(); this.toggle()}}>Logout</Nav.Link>
                    </Nav>
                    :
                    <Nav style={{width: '100%'}} className="justify-content-end">
                        <Nav.Link onClick={this.toggle} href="https://breezelabs.co.uk/hosting">About</Nav.Link>
                        <Nav.Link as={Link} onClick={this.toggle} to="/login">Login</Nav.Link>
                        <Nav.Link as={Link} onClick={this.toggle} to="/create-account">Create Account</Nav.Link>
                        <Nav.Link as={Link} onClick={this.toggle} to="/help">Help</Nav.Link>

                    </Nav>
                    }
</Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}
  
function mapStateToProps(state){
    return{
        user: state.user,
        basket: state.basket
    }
}
function mapDispatchToProps(dispatch){
    return{
        setUser: (data)=>{dispatch(setUser(data))},
        logoutUser: ()=>{dispatch(logoutUser())}
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
