import React, { Component } from 'react';
import {Card, Row, Col, Button, Spinner} from 'react-bootstrap'
import Axios from 'axios';
import { connect } from 'react-redux';
import b64ToBlob from "b64-to-blob";
import fileSaver from "file-saver";
import ConfigureBackups from './configureBackups'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLock} from '@fortawesome/free-solid-svg-icons'
class SiteBackup extends Component {
    state = { 
        backups: [],
        manualBackup: null,
        scheduleEnabled: true,
        downloadInProgress: false,
        backupInterval: null,
        showConfigureBackupsModal: false,
        date: ""
     }
     
     componentDidMount(){
        this.getBackups()
        let backupInterval = setInterval(() => {
            this.getBackups()
        }, 1000);
        this.setState({backupInterval: backupInterval})
     }
     componentWillUnmount(){
        clearInterval(this.state.backupInterval)
     }
     getBackups(){
        Axios.post('/api/backup/subscription/backups',{subscriptionItem: this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem}).then(res=>{
            this.setState({backups: res.data.backups, manualBackup: res.data.manualBackup})
        })
     }
     openConfigureBackups = () => {
        this.setState({showConfigureBackupsModal: true})
}
    closeConfigureBackupsModal = () => {
        this.setState({showConfigureBackupsModal: false})
    }
     scheduleDownload=(date)=>{
         if(this.state.scheduleEnabled){
         this.setState({scheduleEnabled: false})
        let data = {subscriptionItem: this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem, date: date}
        Axios.post('/api/backup/download/schedule',data).then(res=>{
            setTimeout(() => {
                this.setState({scheduleEnabled: true})
            }, 5000);   
            this.getBackups()     
        })
    }
    }
    
    download=(date, id)=>{
        this.setState({downloadInProgress:true, date: id})
        let data = {subscriptionItem: this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem, date: date}
        fetch('/api/backup/download/date',{
            method: 'POST',
            mode: 'cors', 
            cache: 'no-cache', 
            credentials: 'same-origin', 
            headers: {
            'Content-Type': 'application/json',
            'x-auth-token':localStorage.jwtToken
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer', 
            body: JSON.stringify(data) 
        }).then((response) => {
            return response.text();
        })
        .then((zipAsBase64) => {
            const blob = b64ToBlob(zipAsBase64, "application/zip");
            let domainUnderScored = this.props.domain.replace(/\./g, '_')
            domainUnderScored = domainUnderScored.replace(/-/g, '_')
            fileSaver.saveAs(blob, `${domainUnderScored}_${date}.zip`);
            this.setState({downloadInProgress:false})
        });
    }
    downloadAutomated=(date, id)=>{
        this.setState({downloadInProgress:true, date: id})
        let data = {subscriptionItem: this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem, date: date}
        fetch('/api/backup/download/automated/date',{
            method: 'POST',
            mode: 'cors', 
            cache: 'no-cache', 
            credentials: 'same-origin', 
            headers: {
            'Content-Type': 'application/json',
            'x-auth-token':localStorage.jwtToken
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer', 
            body: JSON.stringify(data) 
        }).then((response) => {
            return response.text();
        })
        .then((zipAsBase64) => {
            const blob = b64ToBlob(zipAsBase64, "application/zip");
            let domainUnderScored = this.props.domain.replace(/\./g, '_')
            domainUnderScored = domainUnderScored.replace(/-/g, '_')
            fileSaver.saveAs(blob, `${domainUnderScored}_${date}.zip`);
            this.setState({downloadInProgress:false})

        });
    }
    render() {
        let darkmode = this.props.user.darkmode

        let automatedProducts = ['prod_JPnNSYA4kMMdQo','prod_JPnhsmUG9IcnWH']
        let backups = [] 
        this.state.backups.forEach((backup,i)=>{
            backups.push(  
                <div>
                    <Row style={{paddingTop: '10px',paddingBottom: '10px'}}>
                        <Col>Date: {backup.dateFormatted}</Col>
                        <Col>Type: {backup.sitetype}</Col>
                        <Col>Expires: {backup.expiryDate}</Col>
                        <Col><Button disabled={this.state.downloadInProgress && this.state.date == backup.date} onClick={()=>this.downloadAutomated(backup.date, backup.date)} >
                        {
                                                        this.state.downloadInProgress && this.state.date == backup.date?
                        <div><Spinner animation="border" vaiant="secondary" style={{width: '18px', height:'18px', fontSize: '10px'}} /> Downloading </div>:
                            "Download"}
                            
                            </Button></Col>
                    </Row>
                    {i < (this.state.backups.length -1) && <hr style={{width:'90%'}}></hr>}
                </div>
            )
        })
        return ( 
            <Card style={{width: '80%',margin: 'auto', display: 'block', marginTop: '20px'}}>
                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{textAlign: 'center'}}>
                    Backups
                </Card.Header>
                <Card.Text className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{textAlign: 'center', maxWidth: "100%"}}>
                    {
                        !this.state.manualBackup ?
                            <Row style={{paddingTop: '10px',paddingBottom: '10px', maxWidth: "100%",margin: "auto", textAlign: "center"}}>
                                <Col>
                                    Download current site files and data: 
                                </Col>
                                <Col style={{margin: "auto", textAlign: "center"}}>
                                    <Button disabled={!this.state.scheduleEnabled} onClick={()=>this.scheduleDownload('manual')} >
                                        Create site backup
                                    </Button>
                                </Col>
                            </Row>
                        :
                            <div style={{width:'90%', margin: 'auto'}}>
                                <Row>
                                    <Col>
                                        Download current site files and data. This will be kept for two hours. You can click refresh to make an up to date backup. 
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: '10px',paddingBottom: '10px'}}>
                                    <Col>
                                        Date: {this.state.manualBackup.dateFormatted}
                                    </Col>
                                    <Col>
                                        Type: {this.state.manualBackup.sitetype}
                                    </Col>
                                    <Col>Expires: {this.state.manualBackup.expiryDate}</Col>

                                    <Col>
                                        {
                                            this.state.manualBackup.status === 'processing' ? 
                                                <Button  >
                                                    <Spinner animation="border" vaiant="secondary" style={{width: '18px', height:'18px', fontSize: '10px'}} /> Processing
                                                </Button>
                                            : 
                                                <Button disabled={this.state.downloadInProgress && this.state.date == "manual"} onClick={()=>this.download(this.state.manualBackup.date, "manual")}>
                                                    {
                                                        this.state.downloadInProgress && this.state.date == "manual" ?
                                                            <div>
                                                                <Spinner animation="border" vaiant="secondary" style={{width: '18px', height:'18px', fontSize: '10px'}} /> Downloading
                                                            </div>
                                                        : 
                                                            'Download'
                                                    }
                                                </Button>
                                        }
                                    </Col>
                                    <Col>
                                        <Button disabled={!this.state.scheduleEnabled} onClick={()=>this.scheduleDownload('manual')} >
                                            Create New
                                        </Button>
                                    </Col> 
                                </Row>
                            </div>
                    }
                    <hr style={{width:'90%'}}></hr>
                    {automatedProducts.includes(this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].productId) ? <div>
                        {backups}

                    <Button onClick={()=>this.openConfigureBackups()} style={{ marginBottom: '20px', width: "80%"}}>Configure automated backups</Button>
                    <ConfigureBackups closeConfigureBackupsModal={this.closeConfigureBackupsModal} show={this.state.showConfigureBackupsModal} /> </div>:
                    <div>
                        <p>Your hosting plan does not support automated backups. Upgrade your plan to access automated Backups.</p>
                            <FontAwesomeIcon style={{paddingBottom: '20px'}}  size='4x' icon={faLock}/>
                    </div>
                    }
                </Card.Text>
            </Card>
         );
    }
}
 
function mapStateToProps(state){
    return{
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch){
    return{
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SiteBackup);