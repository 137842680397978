import React, { Component } from 'react';
import {loadStripe} from '@stripe/stripe-js'
import axios from 'axios'
import {Button} from 'react-bootstrap'
import { Link } from 'react-router-dom';

class StripeContainerRecurring extends Component {
    state = { 
        session: null,
        key: '',
        showPurchasePolicy: false
     }
     componentDidMount = () => {
         axios.get('/api/payment/get/key').then(res=>{
            this.setState({key: res.data.key})
         })
     }
    
    handleClick =  async () => {
        this.props.autoEnableClicked()
        axios.post('/api/payment/create-payment-intent/setup/auto', {yearlyPricePound: this.props.yearlyPricePound, yearlyPriceDollar: this.props.yearlyPriceDollar, id: this.props.id}).then(async res=>{
            this.props.close()
        })
    }

    render() { 
        return ( 
            <div>
               <button disabled={this.props.disabled} style={{ background: "green", width: "80%", margin: "20px" }} role='link' onClick={this.handleClick} className="button">Confirm Setup Of Auto-Renew</button>
 
            </div>
         );
    }
}
 
export default StripeContainerRecurring;
