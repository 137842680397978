import React, { Component } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import {Nav} from 'react-bootstrap'

class Sidebar extends Component {
    state = { 
        prompt: 'block',
        open: true,
        hoverC: false,
     } 
    //  componentDidMount(){
        
    //     // this.updateWindowDimensions();

    //     // window.addEventListener('resize', this.updateWindowDimensions);
    // }
    // componentWillUnmount() {
    //     window.removeEventListener('resize', this.updateWindowDimensions);
    //   }
    //   updateWindowDimensions=() =>{
    //     let state = window.screen.availWidth > 1320 ? true : false
    //     this.props.sidebarChanged(state)

    //     this.setState({ open: state});
    //   }
    render() { 
        return (
            <div>
                            {this.state.open ? 
                            <div style={{position: "fixed",  margin: "0 0 0 0",  zIndex: '1',display: this.state.prompt, top: '92px', background: 'rgb(52, 58, 64)', left: '142px', width: '50px', height: '40px', borderRadius:'9px'}} 
                            onMouseLeave={()=>{this.setState({hoverC: false})}} onMouseEnter={()=>{this.setState({hoverC: true})}} onClick={()=>{this.setState({open: false});this.props.sidebarChanged(false)}}>
                                <FontAwesomeIcon  id="123123" icon={faChevronLeft} style={{top: this.state.hoverC ? '3px':'5px',left: '15px',color:'white', fontSize:this.state.hoverC ?'35px':"30px", position: "absolute",display: this.state.prompt   }}/></div>:
                                <div style={{position: "fixed",  margin: "0 0 0 0", zIndex: '1',display: this.state.prompt, top: '92px', background: 'rgb(52, 58, 64)', left: '42px', width: '50px', height: '40px', borderRadius:'9px'}} 
                                onMouseLeave={()=>{this.setState({hoverC: false})}} onMouseEnter={()=>{this.setState({hoverC: true})}} onClick={()=>{this.setState({open: true});this.props.sidebarChanged(true)}}>
                                    <FontAwesomeIcon  id="123123" icon={faChevronRight} style={{top: this.state.hoverC ? '3px':'5px',left: '15px',color:'white', fontSize:this.state.hoverC ?'35px':"30px", position: "absolute",display: this.state.prompt   }}/></div>}

        {this.state.open ? <div id="testtt" style={{width: "150px", height: "100%", background: "rgb(52, 58, 64)", position: "fixed", zIndex: "2", color: "white", overflow: 'hidden',top: '88px'}}>
            <Nav.Link style={{margin: "20px 0 0 0", marginLeft: "10px", width: '160px', borderRadius: '25px', color: 'white'}} as={Link} to='/my-hosting/manage-plan' onMouseLeave={()=>{this.setState({hover1: false})}} onMouseEnter={()=>{this.setState({hover1: true})}}><p style={{display:"inline", fontWeight: this.state.hover1?'bold' : 'normal'}}>Plan Details</p></Nav.Link>
            <hr style={{width: "80%", background: "white"}}></hr>
            <Nav.Link style={{margin: "20px 0 0 0", marginLeft: "10px", width: '160px', borderRadius: '25px', color: 'white'}} as={Link} to='/my-hosting/manage-plan/dns' onMouseLeave={()=>{this.setState({hover2: false})}} onMouseEnter={()=>{this.setState({hover2: true})}}><p style={{display:"inline", fontWeight: this.state.hover2?'bold' : 'normal'}}>DNS</p></Nav.Link>
            <hr style={{width: "80%", background: "white"}}></hr>
            <Nav.Link style={{margin: "20px 0 0 0", marginLeft: "10px", width: '160px', borderRadius: '25px', color: 'white'}} as={Link} to='/my-hosting/manage-plan/website' onMouseLeave={()=>{this.setState({hover3: false})}} onMouseEnter={()=>{this.setState({hover3: true})}}><p style={{display:"inline", fontWeight: this.state.hover3?'bold' : 'normal'}}>Website</p></Nav.Link>
            <hr style={{width: "80%", background: "white"}}></hr>
            <Nav.Link style={{margin: "20px 0 0 0", marginLeft: "10px", width: '160px', borderRadius: '25px', color: 'white'}} as={Link} to='/my-hosting/manage-plan/backups' onMouseLeave={()=>{this.setState({hover4: false})}} onMouseEnter={()=>{this.setState({hover4: true})}}><p style={{display:"inline", fontWeight: this.state.hover4?'bold' : 'normal'}}>Backups</p></Nav.Link>
</div> : <div id="testtt" onMouseEnter={()=>{this.setState({hoverC: true})}} onMouseLeave={()=>{this.setState({hoverC: false})}} onClick={()=>{this.setState({open: true});this.props.sidebarChanged(true)}} style={{width: "50px", height: "100%", background: "rgb(52, 58, 64)", position: "fixed", zIndex: "2", color: "white", overflow: 'hidden',top: '88px'}}><p style={{transform: 'rotate(-90deg)', marginLeft: '10px', marginTop: '200px', transformOrigin: '0 0', width: '300px'}}>EXPAND TO SEE MENU</p></div>}
        </div>);
    }
}
 
export default Sidebar;