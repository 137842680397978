export const SET_USER = 'SET_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SET_MARKETING_PREFERENCES = 'SET_MARKETING_PREFERENCES'

export const START_SET_SUBSCRIPTIONS_DATA = 'START_SET_SUBSCRIPTIONS_DATA'
export const SET_SUBSCRIPTIONS_DATA = 'SET_SUBSCRIPTIONS_DATA'
export const SET_SUBSCRIPTIONS_DETAILED_INDEX = 'SET_SUBSCRIPTIONS_DETAILED_INDEX'
export const SET_SUBSCRIPTIONS_CANCELLED = 'SET_SUBSCRIPTIONS_CANCELLED'
export const SET_BACKUP_CONFIGURATION = 'SET_BACKUP_CONFIGURATION'

export const ADD_BASKET_ITEM = 'ADD_BASKET_ITEM'
export const SET_BASKET = 'SET_BASKET'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const SET_CONTACT_INDEX = 'SET_CONTACT_INDEX'
