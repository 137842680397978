import initialState from './initialState'
import axios from 'axios'
import { SET_USER, LOGOUT_USER, SET_MARKETING_PREFERENCES } from '../actions/actionsTypes'

export default function user(state=initialState.users, action){
    let newState
    switch(action.type){
        case SET_USER:
            newState = action.payload.user
            localStorage.setItem('jwtToken', action.payload.token)
            axios.defaults.headers.common['x-auth-token'] = action.payload.token
            return newState
        case LOGOUT_USER:
            localStorage.removeItem('jwtToken')
            delete axios.defaults.headers.common['x-auth-token']
            return null
        case SET_MARKETING_PREFERENCES:
            newState = state
            newState.marketingPreferences = action.payload
            return state
        default:
            return state
    }
}