import React, { Component } from 'react';
import { Card, Row, Button, Col, Form, Pagination } from 'react-bootstrap'
import Axios from 'axios'
import { connect } from 'react-redux';
import Faq from "./faq"
import _ from 'lodash'
class Help extends Component {
    state = {
        email: '',
        message: '',
        submitEnabled: false,
        enquirySent: false,
        number: '',
        contactEmail: false,
        contactText: false,
        contactTelephone: false,
        faqs: [],
        search: "",
        currentPage: 1
    }
    searchDebounced = _.debounce(() => this.handleSearch(1), 500)

    componentWillReceiveProps(next) {
        if (next.user && next.user.email !== this.state.email) {
            this.setState({ email: next.user ? next.user.email : '' })
        }
    }
    componentWillMount() {
        if (this.props.user) {
            this.setState({ email: this.props.user.email })
        }
        this.getFaqs(1)
    }
    textChange = (e, type) => {
        e.persist()
        let email = type === 'email' ? e.target.value : this.state.email
        let messageExists = type === 'message' ? e.target.value !== '' : this.state.message !== ''
        let emailValid = this.validateEmail(email)
        let submitEnabled = emailValid && messageExists
        this.setState({ [type]: e.target.value, submitEnabled: submitEnabled, enquirySent: false })
    }
    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    handleSubmit = () => {
        Axios.post('/api/help/contact', this.state).then((res) => {
            this.setState({
                enquirySent: res.data.success,
                email: this.props.user ? this.state.email : '',
                message: '',
                number: '',
                submitEnabled: false,
                contactEmail: false,
                contactText: false,
                contactTelephone: false,
                totalFaqs: 0
            })
            setTimeout(() => {
                this.setState({ enquirySent: false })
            }, 10000)
        })
    }
    getFaqs = (page) => {
        Axios.post("/api/faq/get/published", { page: page }).then(res => {
            this.setState({ faqs: res.data.faqs, totalFaqs: res.data.total })
        })

    }
    handleSearch = (page) => {
        if (this.state.search) {
            Axios.post("/api/faq/get/by/search", { term: this.state.search, page: page }).then(res => {
                this.setState({ faqs: res.data.faqs, totalFaqs: res.data.total, currentPage: page})
            })
        } else {
            this.getFaqs(1)
        }

    }
    searchChange = (e) => {
        this.setState({ search: e.target.value})
        this.searchDebounced()
    }
    contactAuthChange = (e, type) => {
        this.setState({ [type]: !this.state[type] })
    }
    changePage = (newPage) => {
        if(this.state.search){
            this.setState({ currentPage: newPage })
            this.handleSearch(newPage)
        }else{
            this.setState({ currentPage: newPage })
            this.getFaqs(newPage)
        }
    }
    render() {
        let darkmode = this.props.user ? this.props.user.darkmode : true

        let faqs = []
        this.state.faqs.forEach(faq => {
            faqs.push(<Faq data={faq} />)
        })
        if (!this.state.faqs[0]) {
            faqs.push(<p>No FAQ's or Guides match your search query.</p>)
        }
        let pages = Math.ceil(this.state.totalFaqs / 10)
        let pagination = <Pagination  >
            <Pagination.First onClick={() => { this.changePage(1) }} />
            <Pagination.Prev onClick={() => { this.changePage(this.state.currentPage - 1 > 0 ? this.state.currentPage - 1 : 1) }} />
            {this.state.currentPage > 1 && <Pagination.Item onClick={() => { this.changePage(1) }}>{1}</Pagination.Item>}

            {this.state.currentPage > 3 && <Pagination.Ellipsis />}

            {this.state.currentPage - 2 > 2 && <Pagination.Item onClick={() => { this.changePage(this.state.currentPage - 2) }}>{this.state.currentPage - 2}</Pagination.Item>}
            {this.state.currentPage - 1 > 1 && <Pagination.Item onClick={() => { this.changePage(this.state.currentPage - 1) }}>{this.state.currentPage - 1}</Pagination.Item>}
            <Pagination.Item active>{this.state.currentPage}</Pagination.Item>
            {this.state.currentPage + 1 < pages && <Pagination.Item onClick={() => { this.changePage(this.state.currentPage + 1) }}>{this.state.currentPage + 1}</Pagination.Item>}
            {this.state.currentPage + 2 < pages && <Pagination.Item onClick={() => { this.changePage(this.state.currentPage + 2) }}>{this.state.currentPage + 2}</Pagination.Item>}

            {this.state.currentPage + 3 < pages && <Pagination.Ellipsis />}
            {this.state.currentPage < pages && <Pagination.Item onClick={() => { this.changePage(pages) }}>{pages}</Pagination.Item>}
            <Pagination.Next onClick={() => { this.changePage(this.state.currentPage + 1 <= pages ? this.state.currentPage + 1 : pages > 0 ? pages : 1) }} />
            <Pagination.Last onClick={() => { this.changePage(pages > 0 ? pages : 1) }} />
        </Pagination>
        return (
            <div style={{ marginTop: '60px', marginBottom: '150px', width: "100%" }}>
                <Card style={{ width: '90%', margin: 'auto', display: 'block', marginTop: '40px', maxWidth: '1000px'  }}>
                    <Card.Header className={darkmode? "darkmode-card-header" :"lightmode-card-header"}  style={{ textAlign: 'center' }}>
                        <h1>FAQ's and Guides</h1>
                    </Card.Header>
                    <div className={darkmode? "darkmode-card-content" :"lightmode-card-content"}  style={{ textAlign: 'center', width: "100%" }}>
                        <Row style={{ maxWidth: '1000px', margin: 'auto', paddingTop: '20px' }}>
                            <Col xs={2} style={{ minWidth: '180px' }} >
                                <p>Search:</p>
                            </Col>
                            <Col style={{ minWidth: '250px' }}>
                                <input style={{ width: '100%' }} type='text' value={this.state.search} onChange={(e) => this.searchChange(e)} placeholder='Title' />
                            </Col>
                        </Row>
                        {faqs}
                        <Row  style={{justifyContent: "center"}}>
                            {pagination}
                        </Row>
                    </div>
                </Card>
                <Card style={{ width: '90%', margin: 'auto', display: 'block', marginTop: '40px', maxWidth: '1000px' }}>
                    <Card.Header className={darkmode? "darkmode-card-header" :"lightmode-card-header"}  style={{  textAlign: 'center' }}>                    <h1>Contact Us</h1>
                    </Card.Header>
                    <div className={darkmode? "darkmode-card-content" :"lightmode-card-content"} style={{paddingTop: "20px"}}  >
                        <Row style={{ maxWidth: '1000px', margin: 'auto' }}>
                            <Col xs={2} style={{ minWidth: '180px' }} >
                                <p >Email (required):</p>
                            </Col>
                            <Col style={{ minWidth: '250px' }}>
                                <input style={{ width: '100%' }} type='text' value={this.state.email} onChange={(e) => this.textChange(e, 'email')} placeholder='email@email.com' />
                            </Col>
                        </Row>
                        <Row style={{ maxWidth: '1000px', margin: 'auto', marginTop: '20px' }}>
                            <Col xs={2} style={{ minWidth: '180px' }} >
                                <p>Phone Number:</p>
                            </Col>
                            <Col style={{ minWidth: '250px' }}>
                                <input style={{ width: '100%' }} type='text' value={this.state.number} onChange={(e) => this.textChange(e, 'number')} placeholder='+44 1234 123456' />
                            </Col>
                        </Row>
                        <Row style={{ maxWidth: '1000px', margin: 'auto', marginTop: '20px' }}>
                            <Col xs={2} style={{ minWidth: '180px' }} >
                                <p>Message (required):</p>
                            </Col>
                            <Col style={{ minWidth: '250px' }}>
                                <textarea style={{ width: '100%', height: '200px' }} type='text' value={this.state.message} onChange={(e) => this.textChange(e, 'message')} placeholder='How do i find out more?' />
                            </Col>
                        </Row>

                        {!this.props.user && <div>
                            <hr />

                            <Row style={{ maxWidth: '1000px', margin: 'auto', marginTop: '20px', textAlign: 'center' }}>
                                <Col>
                                    <p>Here at BreezeLabs we take your privacy seriously and
                                        will only use your personal information to administer
                                        your account and to provide the products and services
                                        you have requested from us.
                                    </p>
                                    <p>However, from time to time we would like to contact
                                        you with details of other products, offers, services
                                        and competitions we provide. If you consent to us contacting
                                        you for this purpose, please select the ways you would like us
                                        to contact you:
                                    </p>
                                    <Row style={{ padding: '10px' }}>
                                        <Col style={{ textAlign: 'center' }} xs={4}>
                                            <p>Email</p>
                                            <Form.Control checked={this.state.contactEmail} onChange={(e) => { this.contactAuthChange(e, 'contactEmail') }} type="checkbox" />
                                        </Col>
                                        <Col style={{ textAlign: 'center' }} xs={4}>
                                            <p>Phone</p>
                                            <Form.Control checked={this.state.contactTelephone} onChange={(e) => { this.contactAuthChange(e, 'contactTelephone') }} type="checkbox" />
                                        </Col>
                                        <Col style={{ textAlign: 'center' }} xs={4}>
                                            <p>Text</p>
                                            <Form.Control checked={this.state.contactText} onChange={(e) => { this.contactAuthChange(e, 'contactText') }} type="checkbox" />
                                        </Col>

                                    </Row>
                                    <p>If you would prefer not to hear from us, you can stop receiving our updates at any time by emailing us at <a href='mailto:breezelabsuk@gmail.com'>breezelabsuk@gmail.com</a>.</p>

                                </Col>
                            </Row></div>}
                    </div>
                    <Card.Footer className={darkmode? "darkmode-card-header" :"lightmode-card-header"}  >
                        <Row>
                            <Col>
                                {this.state.enquirySent && <p>Enquiry Sent!</p>}
                                {!this.state.submitEnabled && !this.state.enquirySent && <p style={{ color: 'red' }}>Please fill out the required fields</p>}
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button onClick={this.handleSubmit} disabled={!this.state.submitEnabled} style={{ width: '200px', backgroundColor: '#007bff' }}>Submit</Button>
                            </Col>

                        </Row>
                    </Card.Footer>
                </Card>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps)(Help);
