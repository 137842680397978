import React, { Component } from 'react';
import {Card, Table, Form, Button,Row,Col} from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import chart from 'chart.js'
import EmailUser from '../components/admin/emailUser'
import EmailEveryone from '../components/admin/emailEveryone'
import EmailNewsletter from '../components/admin/emailNewsletter'
import { connect } from 'react-redux';

class AdminEmail extends Component {
  state={
      updateStripeIdsEnabled: false,
      basicid:'',
      standardid:'',
      advancedid:'',
      devbasicid:'',
      devstandardid:'',
      devadvancedid:''

  }
  componentDidMount(){
    axios.get('/api/subscriptions/get/stripeids').then(async (res)=>{
        let basicid = ''
        let devbasicid = ''
        let standardid = ''
        let devstandardid = ''
        let advancedid = ''
        let devadvancedid = ''

        res.data.stripeids.products.forEach(product => {
            switch (product.name) {
                case "Basic Hosting":
                    basicid = product.priceid
                    devbasicid = product.devpriceid
                    break;
                case "Standard Hosting":
                    standardid = product.priceid
                    devstandardid = product.devpriceid
                    break;
                case "Advanced Hosting":
                    advancedid = product.priceid
                    devadvancedid = product.devpriceid
                    break;
                default:
                    break;
            }
        });
        this.setState({basicid: basicid, devbasicid: devbasicid,standardid: standardid, devstandardid: devstandardid,advancedid: advancedid, devadvancedid: devadvancedid})
    })
  }
  updateValue=(e,type)=>{
    this.setState({[type]:e.target.value},()=>{
        if(this.state.basicid && this.state.standardid && this.state.advancedid){
            this.setState({updateStripeIdsEnabled: true})
        }else{
            this.setState({updateStripeIdsEnabled: false})
        }
    })
  }
  updateStripeIds=()=>{
    let productdata = [
        {name:'Basic Hosting',priceid:this.state.basicid,devpriceid:this.state.devbasicid},
        {name:'Standard Hosting',priceid:this.state.standardid,devpriceid:this.state.devstandardid},
        {name:'Advanced Hosting',priceid:this.state.advancedid,devpriceid:this.state.devadvancedid},
    ]
    axios.post('/api/subscriptions/set/stripeids',productdata).then(()=>{
        this.setState({updateStripeIdsEnabled: false})
    })
  }

    render() {   
        let darkmode = this.props.user.darkmode
      
        return ( 
            <div style={{marginTop: '60px', marginBottom: '150px'}}>
                <Card style={{ margin: '30px'}}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{textAlign: 'center'}}>
                        Basic
                    </Card.Header>
                    <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{maxWidth: '100%'}} >
                        <Row>
                            <Col>priceid</Col>
                            <Col>                                    
                                <Form.Control as='textarea' placeholder='priceid' value={this.state.basicid} onChange={(e)=>this.updateValue(e,'basicid')} />
                            </Col>
                        </Row>   
                        <Row>
                            <Col>devpriceid</Col>
                            <Col>                                    
                                <Form.Control as='textarea' placeholder='devpriceid' value={this.state.devbasicid} onChange={(e)=>this.updateValue(e,'devbasicid')} />
                            </Col>
                        </Row>                 
                    </div>
                </Card>
                <Card style={{ margin: '30px'}}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{textAlign: 'center'}}>
                        Standard
                    </Card.Header>
                    <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{maxWidth: '100%'}} >
                        <Row>
                            <Col>priceid</Col>
                            <Col>                                    
                                <Form.Control as='textarea' placeholder='priceid' value={this.state.standardid} onChange={(e)=>this.updateValue(e,'standardid')} />
                            </Col>
                        </Row>                    
                        <Row>
                            <Col>devpriceid</Col>
                            <Col>                                    
                                <Form.Control as='textarea' placeholder='devpriceid' value={this.state.devstandardid} onChange={(e)=>this.updateValue(e,'devstandardid')} />
                            </Col>
                        </Row> 
                    </div>
                </Card>
                <Card style={{ margin: '30px'}}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{textAlign: 'center'}}>
                        Advanced
                    </Card.Header>
                    <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{maxWidth: '100%'}} >
                        <Row>
                            <Col>priceid</Col>
                            <Col>                                    
                                <Form.Control as='textarea' placeholder='priceid' value={this.state.advancedid} onChange={(e)=>this.updateValue(e,'advancedid')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>devpriceid</Col>
                            <Col>                                    
                                <Form.Control as='textarea' placeholder='devpriceid' value={this.state.devadvancedid} onChange={(e)=>this.updateValue(e,'devadvancedid')} />
                            </Col>
                        </Row>
                    </div>
                </Card>
                <Button disabled={!this.state.updateStripeIdsEnabled} style={{backgroundColor: '#343a40', width: '100%'}} onClick={this.updateStripeIds}>Update</Button>

            </div>
         );
    }
}
 
function mapStateToProps(state) {
    return {
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminEmail);





