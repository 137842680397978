import React, { Component } from 'react';
import {Card, Table, Form, Button,Row,Col} from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import chart from 'chart.js'
import EmailUser from '../components/admin/emailUser'
import EmailEveryone from '../components/admin/emailEveryone'
import EmailNewsletter from '../components/admin/emailNewsletter'

class AdminEmail extends Component {
  state={

  }

    render() {         
        return ( 
            <div style={{marginTop: '60px', marginBottom: '150px'}}>
                <EmailUser/>
                <EmailEveryone/>
                <EmailNewsletter/>
            </div>
         );
    }
}
 
export default AdminEmail;